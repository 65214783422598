import {
  UPDATE_STATUS_SUCCESS,
  GET_STATUS_SUCCESS,
  CLEAR_DOSSIER_STATUS,
  SAVE_COMMENTS_TO_LOCAL,
  SET_DOSSIER_FILTERS,
} from "./dossierStatusActionType";
import { STATUS } from "../../config/dossier.status";
import {
  getFromLocalStorage,
  saveToLocalStorage,
  clearLocalStorage,
} from "../../utils/localStorageHelper";

const initialState = {};
const dossierStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_SUCCESS: {
      const dossierData = { ...action.payload };
      if (dossierData.status === STATUS.REVIEW) {
        dossierData.rejectionLocalComments = getFromLocalStorage(dossierData.dossierKey);
      } else if (dossierData.status === STATUS.MPE_REVIEW) {
        dossierData.mpeLocalComments = getFromLocalStorage(`${dossierData.dossierKey}_mpe`);
      } else {
        clearLocalStorage(dossierData.dossierKey);
      }
      return { ...state, ...dossierData };
    }
    case UPDATE_STATUS_SUCCESS: {
      const dossierData = { ...action.payload };
      if (dossierData.status !== STATUS.REVIEW) {
        clearLocalStorage(dossierData.dossierKey);
      } 
      
      if (dossierData.status !== STATUS.MPE_REVIEW) {
        clearLocalStorage(`${dossierData.dossierKey}_mpe`);
      }
      return { ...state, ...action.payload };
    }
    case CLEAR_DOSSIER_STATUS: {
      return {};
    }
    case SAVE_COMMENTS_TO_LOCAL: {
      const { dossierKey, comments } = action.payload;
      saveToLocalStorage(dossierKey, comments);
      return { ...state };
    }
    case SET_DOSSIER_FILTERS: {
      return { ...state, filters: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default dossierStatusReducer;
