import React, { useEffect, useReducer, useRef } from "react";
import { useParams } from "react-router-dom";
import idx from "idx";
import "chartjs-plugin-datalabels";
import "chartjs-plugin-piechart-outlabels";
import { Container, Tooltip } from "@airbus/components-react";
import { Fullscreen } from "@airbus/icons/react";
import NoData from "../NoData/NoData";
import { getData } from "../../utils/data.request";
import ChartData from "./ChartData";
import { getChartComponent } from "../../config/chart.component";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

import "./Charts.scss";

const Charts = (props) => {
  const { reportName } = useParams();
  const { details, sources } = idx(props, (_) => _.content);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { data: {}, type: "", error: null }
  );
  const chartRef = useRef(null);
  const setFullScreen = () => {
    chartRef.current && chartRef.current.requestFullscreen && chartRef.current.requestFullscreen();
  }
  useEffect(() => {
    getData(sources, reportName)
      .then((response) => {
        if (response.data && response.data.length) {
          setState(ChartData(response, details));
        }
      })
      .catch((err) => {
        setState({ error: err });
      });
  }, [details, sources, reportName]);
  const RenderChart = getChartComponent(state.type);
  const chartTitle = idx(props, (_) => _.content.details.title);
  const chartSubTitle = idx(props, (_) => _.content.details.subtitle);
  const datasetKeyProvider = () => {
    return Math.random();
  };
  const { chartOptions, error, data } = state;
  const renderFullscreenIcon = () => (
    <Tooltip placement="bottom" label="Fullscreen">
      <Fullscreen className="exclude-print full-screen-icon" onClick={setFullScreen}  />
    </Tooltip>
  )
  return (
    <Container className={"chart-cls"}>
      {chartTitle && (
        <div className="chart-title">
          <span>
            {chartTitle}
            {chartSubTitle && Object.keys(data).length ? (
              <span style={{ fontSize: "8px" }}>{`  ${chartSubTitle}`}</span>
            ) : null}
          </span>
          {renderFullscreenIcon()}
        </div>
      )}
      <div className="chart-style" ref={chartRef} style={{ height: details.height }}>
        {error ? (
          <ErrorHandler error={error} />
        ) : RenderChart && data && data.datasets ? (
          <RenderChart
            data={data}
            options={chartOptions}
            datasetKeyProvider={datasetKeyProvider}
          />
        ) : (
          <NoData sources={sources} />
        )}
      </div>
    </Container>
  );
};

export default Charts;
