import React from "react";
import { useSelector } from "react-redux";
import { Container, List, ListItem, ListItemIcon, ListItemText } from "@airbus/components-react";
import { FiberManualRecord } from "@airbus/icons/react";
import './TableOfContents.scss';

const handleClick = (event) => {
  const elId = event.currentTarget.attributes['data-link'].value;
  elId && document.getElementById(elId).scrollIntoView();
}
/**
 *
 * @param {*} contents to fetch contents
 */
const renderContents = contents => {
  return contents.map((content) => (
    <ListItem key={content.name}>{content.label}</ListItem>
  ));
};
const renderSections = tocList => {
  return tocList.map(section => (
    <ListItem key={section.name} data-link={section.name} onClick={handleClick}>
      <ListItemIcon>
        <FiberManualRecord style={{ "height": "0.5em", "width": "0.5em" }} />
      </ListItemIcon>
      <ListItemText>
        {section.label}
      </ListItemText>
      <List>{renderContents(section.contents)}</List>
    </ListItem>
  ));
};
/**
 * Fetching & displaying contents of Table of Contents using template data
 */

const TableOfContents = () => {
  const templateList = useSelector(state => state);
  const tocList = templateList.reports.toc;
  return tocList && (
    <Container className='table-content-cls'>
      <div className='render-section-div'>
        <List>
          {renderSections(tocList)}
        </List>
      </div>
    </Container>
  );
};

export default TableOfContents;
