import React, { useEffect, useState } from "react";
import { Spinner, SearchInput, Col, Flex } from "@airbus/components-react";
import { useDispatch, useSelector } from "react-redux";

import reportTexts from "../../locales/reportTexts.json";
import DossierCards from "../../components/DossierCards/DossierCards";
import { apiListDossierStatus } from "../../models/dossierModel/dossierStatusApi";
import "./ReportKanban.scss";

const ReportKanban = () => {
  const dispatch = useDispatch();
  const { dossierStatusList } = useSelector((store) => store);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    dispatch(apiListDossierStatus());
  }, [dispatch]);

  const filterDossiers = (dossiers) => {
    return dossiers.filter(
      (dossier) =>
        !searchString ||
        dossier.searchTags.some((tag) => tag.includes(searchString))
    );
  };

  const renderCards = (dossierStatusList) => {
    return Object.keys(dossierStatusList).map((key) => (
      <Col xs={2} key={key}>
        <div className="kanban-lane">
          <span>{`${key.replace("_", " ")} (${
            dossierStatusList[key].length
          })`}</span>
          <DossierCards dossiers={filterDossiers(dossierStatusList[key])} />
        </div>
      </Col>
    ));
  };

  return Object.keys(dossierStatusList).length ? (
    <div className="report-kanban-cls">
      <SearchInput
        className="report-search"
        placeholder="Search"
        onChange={(event, value) => setSearchString(value.toLowerCase())}
      />
      <div className="report-kanban-main">{renderCards(dossierStatusList)}</div>
    </div>
  ) : (
    <Flex justify="center" style={{ paddingTop: 100 }}>
      <Spinner size="medium" label={reportTexts.LoadingTemplate} />
    </Flex>
  );
};

export default ReportKanban;
