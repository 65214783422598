import getConfig from "../../config"
import { API_CALL } from "../../utils/api.middleware";
import { ROOT_REPORTS, API_METHOD_GET } from "../../utils/api.actions";
import { ATTACHMENT_DOWNLOAD_SUCCESS, ATTACHMENT_DOWNLOAD_FAILURE } from "./attachmentActionType";

const config = getConfig();
const apiName = config.API.endpoints[0].name; // api endpoint resolved from aws for the project

//download a file
export const apiDownloadAttachment = (reportName, fileName) => {
  const downloadSuccess = response => {
    const link = document.createElement("a");
    link.href = response.body;
    link.download = decodedFileName;
    link.click();
    return { type: ATTACHMENT_DOWNLOAD_SUCCESS }
  };
  const downloadFailure = error => ({ type: ATTACHMENT_DOWNLOAD_FAILURE, payload: error });
  const decodedFileName = decodeURI(fileName)

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: `${ROOT_REPORTS}/${reportName}/directories/attachments/files/${decodedFileName}?url=true`,
      method: API_METHOD_GET,
      params: {},
    },
    handlers: [downloadSuccess, downloadFailure],
  };
};
