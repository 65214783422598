import React, {useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogHeader} from "@airbus/components-react";
import {defaultDialogAction, defaultDialogBody} from "./DialogUtils";
import idx from "idx";
import * as ReactIs from 'react-is';
import "./EditRowDialog.scss"


/**
 *
 * @param props List of props below :
 * - title [string]: The title of the Dialog, display at the top
 * - openDialog [boolean]: A flag to determine the opened/closed state of the dialog
 * - data [object]: The data to display in each fields
 * - openDialogHandler [func]: The handler to set the "openDialog" flag
 * - dialogContentRenderer [func]: An optional custom func to render the contents of the dialog.
 *                                 If not specified, a default function is used
 * - columns [array<string>]: A list of fields to display in the dialog, passed to the default content renderer
 * - rowIndex [number]: The index of the row that's currently being edited. Set to -1 when used to add a row
 * - persistData [func]: A function to persist data in the local storage
 * - defaultInterval [string]: The default interval to be populated in the "interval" field, when adding a row.
 * @return {JSX.Element}
 * @constructor
 */
const EditRowDialog = (props) => {
    /**
     * List of states:
     * - edited: The local copy of the current row being edited.
     *
     * We use a local copy so that it doesn't modify directly the table.
     * That way, we don't have to revert any changes when the user closes the modal
     */
    const {
        title,
        openDialog,
        data,
        openDialogHandler,
        DialogHeaderRenderer,
        DialogContentRenderer,
        DialogActionRenderer,
        columns,
        rowIndex,
        persistData,
        defaultInterval
    } = idx(props, (_) => _);

    const [edited, setEdited] = useState(rowIndex === -1 ? {['INTERVAL']: defaultInterval} : {...data[rowIndex]});
    const [defaultDataCustom, setDefaultDataCustom] = useState({});

    /**
     * A function passed to underlying <Input /> onChange function to modify values
     * @param key The key where resides the value to be modified
     * @param value The next value
     */
    const editData = (key, value) => {
        const dataCopy = {...edited};
        dataCopy[key] = value;
        setEdited(dataCopy);
    }

    const confirmHandler = () => {
        const reg = /[\[\],]/gm; //eslint-disable-line
        const dataCopy = [...data]
        Object.entries(edited).forEach(([k, v]) => {
            if (v) {
                const value = Array.isArray(v) ? v.join(', ') : v
                edited[k] = value?.replaceAll(reg, '');
            }
        })
        if (rowIndex === -1) {
            persistData([...dataCopy, {...defaultDataCustom, ...edited}]);
        } else {
            dataCopy[rowIndex] = {...edited};
            persistData(dataCopy);
        }
    }

    const cancelHandler = () => {
        openDialogHandler(false);
    }

    useEffect(() => {
        if (rowIndex === -1) {
            setEdited(title === "Consolidated Task" ? {['INTERVAL']: defaultInterval} : {...data[rowIndex]});
        } else {
            setEdited(data[rowIndex])
        }
    }, [openDialog, rowIndex, data, defaultInterval, title]);

    const dialogContentRendererProps = {
        fields: columns,
        currentRow: data[rowIndex],
        defaultData: {},
        disabledEdits: [],
        edited,
        setEditedData: editData,
        setDefaultDataCustom,
        data,
        rowIndex
    }


    const dialogActionRendererProps = {
        confirmHandler,
        cancelHandler,
        edited,
        data
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={cancelHandler}
                disableBackdropClick
                disableEscapeKeyDown
                className="responsive-dialog"
            >
                <DialogHeader className="dialog-header">
                    {DialogHeaderRenderer && !ReactIs.isFragment(DialogHeaderRenderer) ?
                        <DialogHeaderRenderer/> : title}
                </DialogHeader>
                <DialogContent>
                    {DialogContentRenderer && !ReactIs.isFragment(DialogContentRenderer) ?
                        <DialogContentRenderer {...dialogContentRendererProps}/> : defaultDialogBody({
                            fields: columns,
                            currentRow: edited,
                            defaultData: rowIndex === -1 ? {'INTERVAL': defaultInterval} : {},
                            disabledEdits: [],
                            setEditedData: editData
                        })}
                </DialogContent>
                <DialogActions className="dialog-action">
                    {DialogActionRenderer && !ReactIs.isFragment(DialogActionRenderer) ?
                        <DialogActionRenderer {...dialogActionRendererProps}/> : defaultDialogAction(confirmHandler)}
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default EditRowDialog;
