import _ from 'lodash';
export const prepare = (data, details) => {
  const {
    xAxis
  } = details.options;
  return data && data instanceof Array && data.map((item) => {
    let result = _.omit(item, [xAxis]);
    result = _.pick(item, Object.keys(result).filter((key) => result[key] > 0));
    result = Object.keys(result).map((key) => ({
      name: key,
      count: result[key]
    }));
    const titleText = item.titleData ? item.titleData.replace(/\|/g, '\n ') : '';
    const chart = {
      details: {
        ...details,
        title: titleText,
        options: {
          ...details.options,
          xAxis: 'name',
          devicePixelRatio: 1.5,
        }
      },
      sources: [{
        type: "staticData",
        key: "details.value",
        value: {
          columns: [
            'name',
            'count'
          ],
          data: result
        }
      }]
    }
    return chart;
  });
}