import { GET_USERDATA_SUCCESS, UPDATE_USERDATA_SUCCESS, UPDATE_USERDATA_FAILURE, GET_USERINFO_SUCCESS, GET_USERINFO_FAILURE, GET_IMAGEB64_SUCCESS, GET_USER_PERMISSION_SUCCESS, GET_USER_PERMISSION_FAILURE } from "./userDataActionType";

const IAM_ROLES = {
  '2S71-IDAAS-*-MPO-USERS': 'specialist',
  '2S71-IDAAS-*-MPO-PROJECT-LEAD' : 'chairman',
  '2S71-IDAAS-*-MPO-CONTRIBUTORS' : 'mpe',
  '2S71-IDAAS-*-MPO-VALIDATORS' : 'validator'
}
const XTRA_ROLE = 'superuser';

const initialState = {
  attachments: [],
  user:{},
  images:[],
  imageB64:{},
  sectionHidden :{},
  posthog:{}
};
const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USERDATA_SUCCESS:
    case GET_USERDATA_SUCCESS:{
      const userData = action.payload.body.userdata;
      return { ...state, ...userData };
    }
    case UPDATE_USERDATA_FAILURE:{
      return state
    }
    case GET_USERINFO_SUCCESS:{
      const { identities, sub, ...user } = action.payload; // eslint-disable-line no-unused-vars
      const groupStr = user['custom:Groups'] && user['custom:Groups'].replace(/[^0-9a-zA-Z-,]+/g, "").replace(/-(VAL|STG|PRD)-/g, "-*-").split(",");
      user.role = (groupStr && groupStr.length > 1) ? XTRA_ROLE : (IAM_ROLES[groupStr] || 'default');
      return { ...state, user };
    }
    case GET_USERINFO_FAILURE:{
      return { ...state, user: { } }
    }
    case GET_IMAGEB64_SUCCESS:{
      const extension = action.payload.key.split('.')[1];
      const base64String = `data:image/${extension};base64,${action.payload.value}`
      const key = action.payload.key
      return { ...state, imageB64: { ...state.imageB64, [key]: base64String }}
    }
    case GET_USER_PERMISSION_SUCCESS:{
      const permission = action.payload.body;
      return { ...state, posthog: {permission} };
    }
    case GET_USER_PERMISSION_FAILURE:{
      return { ...state, posthog: {} };
    }
    default:
      return state;
  }
};

export default userDataReducer;
