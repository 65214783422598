export const TABLE = 'table';
export const GENERIC_TABLE = 'generictable';
export const CHART = 'chart';
export const IMAGE = 'image';
export const COVER = 'cover';
export const TEXT  = 'text';
export const TBLCONTENTS  = 'tableofcontents';
export const ATTACHMENTS  = 'attachments';
export const HASHTABLE = 'hash';
export const INFOCARD = 'infocard';
export const ESSAY = 'essay';
export const CHARTSLIST = 'chartslist';
