import {
  CLEAR_DOSSIER_STATUS,
  SAVE_COMMENTS_TO_LOCAL,
  UPDATE_DOSSIER_STATS,
  SET_DOSSIER_FILTERS,
} from "./dossierStatusActionType";

export const clearDossierStatus = () => ({ type: CLEAR_DOSSIER_STATUS });
export const saveComments = (dossierKey, comments) => ({
  type: SAVE_COMMENTS_TO_LOCAL,
  payload: { dossierKey, comments },
});

export const updateDossierStats = (dossierKey, data) => ({
  type: UPDATE_DOSSIER_STATS,
  payload: { dossierKey, ...data },
});

export const setFilterInfo = (payload) => ({
  type: SET_DOSSIER_FILTERS,
  payload,
});
