import {
  TOGGLE_TE_STATUS,
  SET_TE_STORAGE_KEY,
  SET_ED_DISABLED_COLUMNS,
  SET_IS_TE_EDITABLE,
} from "./teEditableActionType";

export const setSaveStatus = (payload) => {
  return {
    type: TOGGLE_TE_STATUS,
    payload: payload,
  };
};

export const setTEStorageKey = (payload) => {
  return {
    type: SET_TE_STORAGE_KEY,
    payload: payload,
  };
};

export const setTEDisabledColumns = (payload) => {
  return {
    type: SET_ED_DISABLED_COLUMNS,
    payload: payload,
  };
};

export const setIsTEEditable = (payload) => {
  return {
    type: SET_IS_TE_EDITABLE,
    payload: payload,
  };
};