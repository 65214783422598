import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import idx from 'idx';
import {
  Button,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@airbus/components-react';
import { Delete, Edit, PrintDisabled, CloudUpload } from '@airbus/icons/react';
import {
  apiUploadFile,
  apiDeleteFile,
  updateUserData,
  apiUpdateFile,
} from '../../models/userDataModel/userDataAPI';
import ConfirmModal from './components/ConfirmModal/ConfirmModal';

import './AttachmentCard.scss';
import UploadModal from './components/UploadModal/UploadModal';
import { STATUS, DERIVED_SYNC_STATUS } from '../../config/dossier.status';

const AttachmentCard = (props) => {
  const { content, sectionName } = idx(props, (_) => _);
  const { reportName } = useParams();
  const contentStoredAt = content.sources && content.sources[0].key;

  // showDescription => true, if attachement card is present in appendix
  const showDescription = contentStoredAt === 'attachments' ? true : false;
  const { userData, loading, dossierStatus, syncNotification } = useSelector(
    (store) => store
  );
  const { userDataDeleteFile, userDataUploadFile } = loading;

  const dispatch = useDispatch();
  const clsName = 'attachment-card-cls exclude-print';
  const syncInProgress =
    syncNotification.sync_info &&
    syncNotification.sync_info.status === DERIVED_SYNC_STATUS.IN_PROGRESS;

  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const uploadModalOpen = (attachment) => {
    setUploadModalOpen(true);
    setSelectedAttachment(attachment);
  };
  const uploadModalClose = () => {
    setUploadModalOpen(false);
  };
  const confirmModalOpen = (attachment) => {
    setConfirmModalOpen(true);
    setSelectedAttachment(attachment);
  };
  const confirmModalClose = () => {
    setConfirmModalOpen(false);
    setSelectedAttachment(null);
  };

  useEffect(() => {
    confirmModalClose();
  }, [userDataDeleteFile]);
  useEffect(() => {
    uploadModalClose();
  }, [userDataUploadFile]);

  const removeAttachment = (fileName) => {
    // eslint-disable-next-line no-unused-vars
    const { imageB64, user, ...newUserData } = userData;
    newUserData[contentStoredAt] = userData[contentStoredAt].filter(
      (item) => item.name !== fileName
    );
    dispatch(apiDeleteFile(reportName, fileName, newUserData, contentStoredAt));
  };

  const uploadFile = ({ attachmentObj, fileInformation }) => {
    if (
      userData[contentStoredAt].find((item) => item.name === fileInformation.id)
    ) {
      // eslint-disable-next-line no-unused-vars
      const { imageB64, user, ...newUserData } = userData;
      newUserData[contentStoredAt] = userData[contentStoredAt].map((item) =>
        item.name === attachmentObj.name ? attachmentObj : item
      );
      dispatch(
        apiUpdateFile(reportName, fileInformation, newUserData, contentStoredAt)
      );
    } else {
      // eslint-disable-next-line no-unused-vars
      const { imageB64, user, ...newUserData } = userData;
      newUserData[contentStoredAt] = [
        ...userData[contentStoredAt],
        attachmentObj,
      ];
      dispatch(
        apiUploadFile(reportName, fileInformation, newUserData, contentStoredAt)
      );
    }
  };

  const updateDescription = (attachmentObj) => {
    // eslint-disable-next-line no-unused-vars
    const { imageB64, user, ...newUserData } = userData;
    newUserData[contentStoredAt] = userData[contentStoredAt].map((item) =>
      item.name === attachmentObj.name ? attachmentObj : item
    );

    dispatch(
      updateUserData({
        reportName,
        userData: newUserData,
        componentName: 'userDataUploadFile',
      })
    );
  };

  const renderUploadModal = () => {
    return isUploadModalOpen ? (
      <UploadModal
        className='upload-modal'
        showDescription={showDescription}
        isOpen={isUploadModalOpen}
        isPending={userDataUploadFile}
        sectionName={sectionName}
        attachment={selectedAttachment}
        onUpload={uploadFile}
        onEdit={updateDescription}
        onClose={uploadModalClose}
      />
    ) : (
      false
    );
  };

  const renderConfirmModal = () => {
    return isConfirmModalOpen ? (
      <ConfirmModal
        className='confirm-modal'
        isOpen={isConfirmModalOpen}
        isPending={userDataDeleteFile}
        attachment={selectedAttachment}
        onConfirm={removeAttachment}
        onClose={confirmModalClose}
      />
    ) : (
      false
    );
  };

  return dossierStatus &&
    STATUS.TECHNICAL_EVALUATION === dossierStatus.status ? (
    <div className={clsName}>
      <div className='card-header'>
        <span className='heading'>
          <PrintDisabled />
          <span>{content.label}</span>
        </span>
        <Button
          icon={<CloudUpload />}
          variant='primary'
          size='xsmall'
          className='exclude-print upload-btn'
          onClick={() => uploadModalOpen(null)}
          disabled={syncInProgress}
        >
          Upload
        </Button>
      </div>
      {renderUploadModal()}
      {renderConfirmModal()}
      <Table className='attachments-table'>
        <TableBody className='table-body'>
          {userData[contentStoredAt].map((item, index) => (
            <TableRow key={index}>
              <TableCell>Appendix {index + 1}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.description}</TableCell>
              <TableCell className='exclude-print action-cell'>
                {showDescription && (
                  <IconButton
                    className='edit-btn'
                    variant='ghost'
                    size='small'
                    onClick={() => uploadModalOpen(item)}
                  >
                    <Edit />
                  </IconButton>
                )}
                <IconButton
                  className='delete-btn'
                  variant='ghost'
                  size='small'
                  onClick={() => confirmModalOpen(item)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  ) : (
    false
  );
};
export default AttachmentCard;
