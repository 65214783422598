import _ from "lodash";
import DataProps from "./DataProps";
import { setColorCode } from "./ColorCode";
import { circularCharts } from "../../config/circular.chart";
import {
  setLegend,
  setYAxisScaleAndTitles,
  setXAxisScaleAndTitles,
  setDataLabel,
  prepareMultiLineLabelData,
  getStackedDataSum,
  stackedDataSumObj,
  setLayoutforOutlabels,
  checkUniDataLineChart,
  prepareUniDataLineChart,
} from "./chart.options";
import { LINE } from "../../config/chart.type";

export const prepareCircularChartData = (chartDataJson, chartType, styles, labels) => {
  const { backgroundColors } = setBackGroundColorAndType(
    styles,
    labels,
    chartType
  );
  return [
    {
      data: Object.values(chartDataJson)[0],
      backgroundColor: backgroundColors,
      hoverBackgroundColor: backgroundColors,
    },
  ];
};
/**
 *
 * @param {*} styles
 * @param {*} labels
 * @param {*} isCircle
 */
const setBackGroundColorAndType = (styles, labels, chartType) => {
  const isCircle = checkIfCircularChart(chartType);
  const backgroundColors = new Array(labels.length);
  const chartTypes = new Array(labels.length);
  const yAxisTitles = new Array(labels.length);
  const yAxisIDs = new Array(labels.length);
  const pointStyles = new Array(labels.length);
  const pointRadi = new Array(labels.length);
  const borderWidths = new Array(labels.length);
  styles.forEach((item) => {
    const { value, key, type, title, yAxisID, color, pointStyle, pointRadius, borderWidth} = item;
    const lbl = isCircle ? value : key;
    if (labels.includes(lbl)) {
      backgroundColors[labels.indexOf(lbl)] = color;
      chartTypes[labels.indexOf(lbl)] = type ? type : chartType;
      yAxisTitles[labels.indexOf(lbl)] = title ? title : "";
      yAxisIDs[labels.indexOf(lbl)] = yAxisID ? yAxisID : "";
      pointStyles[labels.indexOf(lbl)] = pointStyle ? pointStyle : null;
      pointRadi[labels.indexOf(lbl)] = pointRadius ? pointRadius : null;
      borderWidths[labels.indexOf(lbl)] = borderWidth ? borderWidth : null;
    }
  });
  backgroundColors.includes(undefined) && setColorCode(backgroundColors);
  return {
    backgroundColors,
    chartTypes,
    yAxisTitles,
    yAxisIDs,
    pointStyles,
    pointRadi,
    borderWidths
  };
};
/**
 *
 * @param {*} chartDataJson
 * @param {*} chartType
 * @param {*} isCircle
 * @param {*} styles
 */


const prepareNonCircularChartData = (chartDataJson, chartType, styles, errorGraph = false) => {
  const titles = Object.keys(chartDataJson);
  const { backgroundColors, chartTypes, yAxisTitles, yAxisIDs, pointStyles, pointRadi, borderWidths } =
    setBackGroundColorAndType(styles, titles, chartType);
  return {
    datasets: titles.map((title, index) => {
      const dataProps =
        DataProps[chartType === "mix" ? chartTypes[index] : chartType];
      if (chartTypes[index] === LINE) {
        const style = _.find(styles, (item) => item.key === titles[index]);
        dataProps.pointRadius =
          style.pointRadius !== undefined
            ? style.pointRadius
            : dataProps.pointRadius;
      }
      let styleAtributes = {
        ...dataProps,
        label: yAxisTitles[index],
        backgroundColor: backgroundColors[index],
        borderColor: backgroundColors[index],
        pointBackgroundColor: backgroundColors[index],
        pointBorderColor: backgroundColors[index],
        pointHoverBackgroundColor: backgroundColors[index],
        pointHoverBorderColor: backgroundColors[index],
        hoverBorderColor: backgroundColors[index],
        maxBarThickness: errorGraph ? 3 : 80,
        data: chartDataJson[title],
        type: chartTypes[index],
        yAxisID: yAxisIDs[index],
      }
      if (errorGraph) {
        styleAtributes = {
          ...styleAtributes,
          pointStyle: pointStyles[index],
          pointRadius:pointRadi[index],
          borderWidth:borderWidths[index],
        };
      }
      return {...styleAtributes};
    }),
    yAxisTitles,
  };
};

export const checkIfCircularChart = (chartName) => {
  return circularCharts.includes(chartName);
};

export const prepareDynamicChartStyles = (columns, type, hideXaxisTitle) => {
  //  In non circular charts, when 2 types of xaxis values are present,
  //  1, one for for overall xaxis (ie) x axis title
  //  2, one for individual items
  //  hideXaxisTitle = optional, boolean = hides x axis title

  const chartLegends = columns.reduce((acc, column) => {
    if (type !== "mix") {
      acc.push({
        key: column,
        title: column,
      });
    }
    return acc;
  }, []);
  // first item in array is used as xaxis title.
  // if hideXaxisTitle=true, first item is removed from array
  if (hideXaxisTitle) {
    chartLegends.shift();
  }
  return chartLegends;

};

export const prepareChartData = (
  isCircularChart,
  chartDataJson,
  type,
  details,
  labels,
  label,
  chartData,
  columns
) => {
  const { styles, options } = details;
  const chartStyles =
    styles && styles.length
      ? styles
      : prepareDynamicChartStyles(columns, type, options.hideXaxisTitle);
  const isUniDataLineChart =
    type === "line" ? checkUniDataLineChart(chartDataJson) : false;

  labels = isUniDataLineChart ? ["", ...labels, ""] : labels;

  if (!isCircularChart) {
    const xAxisStyle = chartStyles.find((item) => item.key === label);
    const xAxisTitle = xAxisStyle ? xAxisStyle.title || xAxisStyle.key : "";
    const rawNonCircularChartData = isUniDataLineChart
      ? prepareUniDataLineChart(chartDataJson)
      : chartDataJson;
    const { datasets, yAxisTitles } = prepareNonCircularChartData(
      rawNonCircularChartData,
      type,
      chartStyles,
      details.errorGraph
    );
    const stackedDataSum = getStackedDataSum(datasets);
    options.showDatalabelsSum &&
      datasets.push(stackedDataSumObj(stackedDataSum));
    chartData.data = {
      datasets,
    };
    const chartOptions = setLegend(options.legend);
    chartOptions.plugins = {
      datalabels: setDataLabel(options.showDataLabel, stackedDataSum)
        .datalabels,
    };

    const labelsData = options.multiLineLabel
      ? prepareMultiLineLabelData(labels, chartData)
      : labels;
    const scales = {};
    if (!_.isEmpty(yAxisTitles)) {
      const yMaxObj = getYMax(rawNonCircularChartData, details.errorGraph);
      scales.yAxes = setYAxisScaleAndTitles(yAxisTitles, options.yAxes, yMaxObj);
    }
    scales.xAxes = setXAxisScaleAndTitles(
      xAxisTitle,
      labelsData,
      options.stacked,
      options.showGridLines
    );
    if (!_.isEmpty(scales)) {
      chartOptions.scales = scales;
    }
    chartData.chartOptions = chartOptions;
  } else {
    const datasets = prepareCircularChartData(
      chartDataJson,
      type,
      styles,
      labels
    );
    chartData.data = {
      labels: labels,
      datasets,
    };
    const numericalDataArray = datasets[0].data.map((data) => {
      return parseFloat(data) || 0;
    });
    const chartOptions = setLegend(options.legend);
    const dataLabelData = setDataLabel(
      options.showDataLabel,
      numericalDataArray
    );
    chartOptions.layout = setLayoutforOutlabels();
    chartOptions.plugins = {
      datalabels: dataLabelData.datalabelsCircular,
      outlabels: dataLabelData.outlabelsCircular,
    };
    chartData.chartOptions = chartOptions;
  }
  return chartData;
};

export const getYMax = (rawNonCircularChartData, isErrorgraph) =>{
  let divider = 5;
  let yMax = 0;
  const yMaxObj = {}
  if (isErrorgraph){
    const ffr_plus_me_all = rawNonCircularChartData.ffr_plus_me.concat(rawNonCircularChartData.ffr_plus_me_100_pc);
    const filtered = ffr_plus_me_all.filter((el) => (String(el)!="null"));
    const maxY = Math.ceil(Math.max(...filtered));
    divider = maxY < divider ? 1 : divider; 
    yMaxObj.isMultiple = maxY % divider === 0;
    yMax =  Math.ceil(maxY/ divider) * divider;
  }
  yMaxObj.yMax = yMax ;
  yMaxObj.divider = divider;
  return yMaxObj;
}