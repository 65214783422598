import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@airbus/components-react";

import './AvatarInitials.scss';

const AvatarInitials = ({email}) => {
  const [ initial1, initial2 ] = email.split('.').map((str) => str[0].toUpperCase());
  const [ name1, name2 ] = email.split('@')[0].split('.');
  return (
    <Tooltip className="avatar-initials-tooltip-cls" title={`${name1} ${name2}`}>
      <div className="avatar-initials-cls" >
        {`${initial1}${initial2}`}
      </div>
    </Tooltip>
  );
};

AvatarInitials.defaultProps = {
  email: ''
};
AvatarInitials.propTypes = {
  email: PropTypes.string
};

export default AvatarInitials;
