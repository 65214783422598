import React from 'react';
import parser from 'html-react-parser';
import PropTypes from 'prop-types'
import idx from 'idx';
import  './ReportText.scss';

const ReportText = (props) => {
  const { sources } = idx(props, _ => _.content)
  const renderSources = () => {
    return sources.map((source) => (
      <div key={source.location + '-' + source.name} className="report-txt-cls render-source">
        <div key={source.key}>
          {parser(source.value.text || "")}
        </div>
      </div>
    ));
  }
  return (
    <div className="report-text-component-cls">
      {renderSources()}
    </div>
  );
};

export default ReportText;

ReportText.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.shape({
        text: PropTypes.string
      })
    })
  }))
}