import React, { useEffect } from 'react'
import idx from 'idx'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { apiGetImage } from '../../models/userDataModel/userDataAPI';
import { useParams } from 'react-router-dom';

const ImagePreview = (props) => {
  const { imageName, source, height } = idx(props, _ => _)
  const imageContent = useSelector(state => state.userData.imageB64[imageName]);
  const dispatch = useDispatch();
  const { reportName } = useParams();
  useEffect(() => {
    !imageContent && dispatch(apiGetImage(reportName, imageName, source.key))
  }, [imageContent, dispatch, reportName, imageName, source.key])
  
  return (
    <div className="image-preview" style={{ border: '10px solid #ffffff' }}>
      {imageContent ? <img src={imageContent} alt={imageName} style={{maxHeight: height, width: 'auto'}} /> : ''}
    </div>
  );
}

export default ImagePreview;

ImagePreview.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.string, PropTypes.number]).isRequired,
  imageName: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired
}