import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { ComboBox, Button, Textarea, Typography, Container, Collapsible } from "@airbus/components-react";
import { useDispatch, useSelector } from "react-redux";
import { updateChairmanStatus } from "../../models/dossierModel/dossierStatusApi";
import { MSG_SHOULD_SHOWON_BANNER, CONFIRMATION_MODEL_ACTION, STATUS, SECTION_DROPDOWN } from "../../config/dossier.status";
import { saveComments } from "../../models/dossierModel/dossierStatusActions";
import "./AddComment.scss";
import { Done, Info, Visibility } from "@airbus/icons/react";
import ContributorComment from "./ContributorComment";
import DailogBox from "../DialogBox/DialogBox";

const AddComment = (props) => {

  const { dossierStatus, userData, accessRestriction } = useSelector(
    (store) => store
  );

  const { toc, dossierKey, email, toggleBoxStatus, mpeLocalComments, setRejectionComments, rejectionLocalComments } = props;
  const [commentData, setCommentData] = useState({});
  const [mpeCommentData, setMpeCommentData] = useState({});
  const [rejectionCommentData, setRejectionCommentData] = useState({});
  const [section, setSection] = useState('');
  const [saveLocal, setSaveLocal] = useState(false);
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [modelProps, setModelProps] = useState({});
  const { canChairmanReview, canReviewOrDownload, canValidatorReview } = accessRestriction.smiedEditable;
  const { mpeComments, comments} = dossierStatus;
  const [mpeLoppData, setmpeLoppData] = useState([]);

  useEffect(() => {
    const latestCommentData = toc.reduce((acc, item) => {
      const targetComment = canReviewOrDownload ?
      rejectionLocalComments && rejectionLocalComments.find((comment) => comment.sectionName === item.label)
      : mpeLocalComments && mpeLocalComments.find((comment) => comment.sectionName === item.label);
      acc[item.label] = targetComment ? targetComment.reason : '';
      return acc;
    }, {});
    setCommentData(latestCommentData);

    const latestChairmanCommentData = toc.reduce((acc, item) => {
      const targetComment = mpeComments.filter((comment) => comment.sectionName === item.label);
      if (targetComment.length > 0) {
        acc[item.label] = { title: "Feedback/Comments", comment: targetComment };
      }
      return acc;
    }, []);
    setMpeCommentData(latestChairmanCommentData);
    const rejectionCommentHistory = toc.reduce((acc, item) => {
      const rejectionComment = comments && comments.filter((comment) => comment.sectionName === item.label);
      if (rejectionComment && rejectionComment.length > 0) {
        acc[item.label] = { title: "Rejection Comments", comment: rejectionComment };
      }
      return acc;
    }, []);
    setRejectionCommentData(rejectionCommentHistory);

    const mpeloophistory = []
    const targetComment = mpeComments.filter((comment) => comment.sectionName === SECTION_DROPDOWN.MPE_COMMENT_KEY);
    const updatedmpeloophistory = targetComment.filter(c => c.comment.length > 0);
    updatedmpeloophistory.length > 0 && mpeloophistory.push({ title: '', comment: targetComment})
    updatedmpeloophistory.length > 0 && setmpeLoppData(mpeloophistory)
  }, [rejectionLocalComments, mpeLocalComments, toc, canReviewOrDownload, mpeComments, comments]);

  useEffect(() => {
    setSaveLocal(false);
  }, [section]);


  const save = () => {
    const commentsArray = Object.entries(commentData).map(([key, value]) => ({ sectionName: key, reason: value || '' }))
    const updatedComments = commentsArray.filter(c => c.reason)
    setSaveLocal(true);
    const key = canReviewOrDownload ? dossierKey : `${dossierKey}_mpe`;
    dispatch(saveComments(key, updatedComments))
  }
  const cancel = () => {
    toggleBoxStatus();
  }

  const handleChange = (e) => {
    setCommentData({ ...commentData, [section]: e.target.value })
    setRejectionComments({ ...commentData, [section]: e.target.value });
  }

  const hasComments = () => {
    return Object.keys(commentData).some((key) => !!commentData[key]);
  }

  const updateDossierToTEWithoutComments = () => {
    const status = STATUS.TECHNICAL_EVALUATION;
    const mpeCommentsStatus = MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITHOUT_COMMENT;
    //adding this extra object to show the mpe loop
    const newComments = [{ sectionName: SECTION_DROPDOWN.MPE_COMMENT_KEY, comment: SECTION_DROPDOWN.MPE_NO_COMMENT_MSG, createdBy: userData.user.email }]
    const oldComments = dossierStatus.mpeComments || [];
    const updatedMPEComments = newComments.concat(oldComments);
    dispatch(updateChairmanStatus({ status, email, dossierKey, mpeComments: updatedMPEComments, mpeCommentsStatus}));
    window.scrollTo({ top: 0 });
    toggleBoxStatus();
    confirmModalClose();
  }

  const updateDossierToTEWithComments = () => {
    const status = STATUS.TECHNICAL_EVALUATION
    const commentsArray = Object.entries(commentData).map(([key, value]) => ({ sectionName: key, comment: value || '', createdBy: userData.user.email }));
    const newComments = commentsArray.filter(c => c.comment);
    const oldComments = dossierStatus.mpeComments || [];
    //adding this extra object to show the mpe loop
    const mpeReviewComment = [{ sectionName: SECTION_DROPDOWN.MPE_COMMENT_KEY, comment: SECTION_DROPDOWN.MPE_COMMENT_MSG, createdBy: userData.user.email }]
    const updatedMPEComments = mpeReviewComment.concat(newComments.concat(oldComments));
    const mpeCommentsStatus = MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITH_COMMENT;
    dispatch(updateChairmanStatus({ status, email, dossierKey, mpeComments: updatedMPEComments, mpeCommentsStatus}));
    window.scrollTo({ top: 0 });
    toggleBoxStatus();
    confirmModalClose();
  }

  const confirmModalOpen = (key) => {
    const actionMap = {
      [CONFIRMATION_MODEL_ACTION.SUBMIT_WITHOUT_COMMENT]: {
        onConfirm: updateDossierToTEWithoutComments,
        DialogBody: 'Do you want to submit review without comments ?'
      },
      [CONFIRMATION_MODEL_ACTION.COMMENTS_DONE]: {
        onConfirm: updateDossierToTEWithComments,
        DialogBody: 'Do you want to submit ?'
      }
    };
    setModelProps(actionMap[key]);
    setConfirmModalOpen(true);
  };

  const confirmModalClose = () => {
    setConfirmModalOpen(false);
    setModelProps({})
  };

  const renderConfirmModalBox = () => {
    return isConfirmModalOpen ? (
      <DailogBox
        className="confirm-modal"
        isOpen={isConfirmModalOpen}
        onConfirm={modelProps.onConfirm}
        onClose={confirmModalClose}
        buttonName={'Confirm'}
        body={modelProps.DialogBody}
        title={'Are you sure ?'}
      />
    ) : (
      false
    );
  };

  const getCommentHisotryHead = <div className="comment-history-header" ><Info /><span className="comment-history-title">Comments history status</span></div>

  return (
    <Container className="addcomment-box-cls">
      {renderConfirmModalBox()}
      <div className="comment-info">
        <div className="toc-list">
          {canChairmanReview && <div className="heading">Go to</div>}
          {(canReviewOrDownload || canValidatorReview) &&
            <div>
              {mpeLoppData.length > 0 &&
                <div className="w-480 mpe-comment-history">
                  <Collapsible title={getCommentHisotryHead}>
                    {mpeLoppData.map((comments, index) => (
                      <ContributorComment comment={comments} key={index} index={index} isAvatarInitials={true} isNoComment={'-nocomment'} />
                    ))}
                  </Collapsible>
                </div>
              }
              <div className="heading">
                <h4>You are about to reject dossier</h4>
                <h5>Explain the reason for rejection</h5>
              </div>
           </div>
          }
          <div style={{ flex: 1 }}>
            <ComboBox
              className="toc-selector"
              placeholder={"Select a section from the list"}
              defaultValue={section}
              onChange={(val) => setSection(val.label)}
              options={toc.map(t => ({ label: t.label, value: t.label }))}
            />
          </div>
        </div>
        {section && mpeCommentData[section] &&
          <ContributorComment comment={mpeCommentData[section]} isAvatarInitials={true}/>
        }

        {section && canReviewOrDownload && rejectionCommentData[section]  &&
          <ContributorComment comment={rejectionCommentData[section]} isAvatarInitials={false}/>
        }

        <div className="toc-list">
          <div style={{ display: `${section ? 'block' : 'none'}` }}>
            {canChairmanReview && <div className="heading">Comment</div>}
            {canReviewOrDownload && <div className="heading">Rejection reasons</div>}
            <Textarea className="reason-field"
              rows={5}
              value={commentData[section]}
              onChange={handleChange}
              placeholder="Enter a text here (max 10000 character)"
              maxLength={10000}
            />
          </div>
        </div>
      </div>
      <div className={`${section ? 'actions overflow' : 'actions'}`}>
        <Button variant="primary" className="save" onClick={save} size="small">Save </Button>
        <Button className="cancel" onClick={cancel} size="small">Cancel </Button>
        {saveLocal &&
          <Typography component="span" variant="body1" className="save-msg">
            {canValidatorReview  && 'Comments saved successfully.'}
            {canReviewOrDownload && 'Your reason for rejection has been saved successfully.'}
          </Typography>}
        {!hasComments() && canChairmanReview && <Button icon={<Done />} variant="primary" className="submit-without-comment" onClick={() => confirmModalOpen(CONFIRMATION_MODEL_ACTION.SUBMIT_WITHOUT_COMMENT)} size="small">Submit without comment </Button>}
        {hasComments() && canChairmanReview && <Button icon={<Visibility />} variant="primary" className="submit-review" onClick={()=>confirmModalOpen(CONFIRMATION_MODEL_ACTION.COMMENTS_DONE)} size="small">Comments Done</Button>}
      </div>
    </Container>
  )
};

AddComment.defaultProps = {
  isEditable: true,
  comments: [],
  toc: [],
  mpeComments: [],
  mpeLocalComments: [],
  rejectionLocalComments: []
};

AddComment.propTypes = {
  setLoading: PropTypes.func,
  comments: PropTypes.array.isRequired,
  toc: PropTypes.array.isRequired,
  dossierKey: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  toggleBoxStatus: PropTypes.func.isRequired,
  mpeComments: PropTypes.array.isRequired,
  mpeLocalComments: PropTypes.array.isRequired,
  setRejectionComments: PropTypes.func,
  rejectionLocalComments: PropTypes.array.isRequired,
};

export default AddComment;
