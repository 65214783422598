export const SELECT_REPORT = "SELECT_REPORT";
export const SELECT_TEMPLATE = "SELECT_TEMPLATE";
export const REQUEST_TEMPLATE = "REQUEST_TEMPLATE";
export const CLEAR_REPORT = "CLEAR_REPORT";

export const REQUEST_REPORT_TYPES = "REQUEST_REPORT_TYPES";
export const RECEIVE_DYNAMIC_DATA= "RECEIVE_DYNAMIC_DATA";

export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const GET_TEMPLATES_FAILURE = "GET_TEMPLATES_FAILURE";

export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";

export const GET_TEMPLATE_SUCCESS = "GET_TEMPLATE_SUCCESS";
export const GET_TEMPLATE_FAILURE = "GET_TEMPLATE_FAILURE";

export const UPDATE_ATTACHMENT_INFO_FAILURE = 'UPDATE_ATTACHMENT_INFO_FAILURE';
export const UPDATE_ATTACHMENT_INFO_SUCCESS = 'UPDATE_ATTACHMENT_INFO_SUCCESS';

export const DOWNLOAD_PDF_FILE_SUCCESS = 'DOWNLOAD_PDF_FILE_SUCCESS';
export const DOWNLOAD_PDF_FILE_FAILURE = 'DOWNLOAD_PDF_FILE_FAILURE';