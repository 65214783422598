import {
  API_METHOD_GET,
  TEMPLATE_LIST,
  GET_REPORT_LIST,
  GET_TEMPLATE,
  ROOT_REPORTS
} from "../../utils/api.actions";
import getConfig from "../../config";
import { processTemplate } from './template.processor';
import { API_CALL } from "../../utils/api.middleware";
import {
  GET_REPORTS_SUCCESS, GET_REPORTS_FAILURE, GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILURE, GET_TEMPLATE_FAILURE, GET_TEMPLATE_SUCCESS,
  DOWNLOAD_PDF_FILE_SUCCESS, DOWNLOAD_PDF_FILE_FAILURE
} from "./reportActionType";
import { updateStatus } from "../dossierModel/dossierStatusApi";
import { STATUS } from "../../config/dossier.status";
const config = getConfig();
const apiName = config.API.endpoints[0].name; // api endpoint resolved from aws for the project

// GET list of Templates
export const apiTemplateList = () => {
  const getTemplatesSuccess = (data, passData) => ({ type: GET_TEMPLATES_SUCCESS, payload: { data: data.body, passData } })
  const getTemplatesFailure = (error) => ({ type: GET_TEMPLATES_FAILURE, payload: { error } })

  return {
    type: API_CALL,
    apiOptions: {
      apiName,
      path: TEMPLATE_LIST,
      method: API_METHOD_GET,
      params: {},
    },
    handlers: [getTemplatesSuccess, getTemplatesFailure],
  }
};

//Get list of Reports
export const apiReportList = () => {

  const getReportsSuccess = (data, passData) => ({ type: GET_REPORTS_SUCCESS, payload: { data: data.body, passData } })
  const getReportsFailure = (error) => ({ type: GET_REPORTS_FAILURE, error: error })

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: GET_REPORT_LIST,
      method: API_METHOD_GET,
      params: {},
    },
    handlers: [getReportsSuccess, getReportsFailure],
  }
};

//GET template from selected template type
export const apiGetTemplate = (report) => {
  const getTemplateSuccess = (data, passData) => async dispatch => {
    const template = await processTemplate(data.body);
    dispatch({ type: GET_TEMPLATE_SUCCESS, payload: { data: template, passData } })
  }
  const getTemplateFailure = (error) => ({ type: GET_TEMPLATE_FAILURE, error: error })

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: `${GET_TEMPLATE}/${report}`,
      method: API_METHOD_GET,
      params: {},
    },
    handlers: [getTemplateSuccess, getTemplateFailure],
  }
};

export const getAllFileNamesSuccess = (data, passData, store) => {

  const downloadZipFileSuccess = (response) => {
    const link = document.createElement("a");
    link.href = response.body;
    link.download = fileName;
    link.click();
    const { statusInfo } = passData;
    const dispatch = statusInfo.dispatch;
    delete statusInfo.dispatch;
    if (Object.keys(statusInfo).length > 0) {
      if (statusInfo.status === STATUS.DELIVERED) {
        statusInfo.stats = store.dossierStats;
      }
      dispatch(updateStatus(statusInfo))
    }
    return { type: '', payload: {} }
  }
  const downloadZipFileFailure = (error) => ({ type: '', error: error })

  const fileName = data.body.archives.length && data.body.archives[0];
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      // inline=false  -> returns file to download
      // url=true -> for signed URL
      path: `${ROOT_REPORTS}/${passData.reportName}/directories/archives/files/${fileName}?url=true`,
      method: API_METHOD_GET,
    },
    handlers: [downloadZipFileSuccess, downloadZipFileFailure],
    passData: { fileName, reportName: passData.reportName, statusInfo: passData.statusInfo },
    componentName: 'zipDownload'
  }
}
const getAllFileNamesFailure = (error) => ({ type: '', error: error })

/*
// DOWNLOAD ZIP FILE successflow
// apidownloadzip -> getAllFileNamesSucesss -> downloadZipFileSucesss -> [voila- report downloaded]

    this is a 2 step process.
    1st, we get the file's name present by passing `latest=true` as path parameter.
    once it is success, we invoke 2nd step, whcih downloads the file.
    when downloading the file, in following path parameter is passed
        inline=true  -> returns pdf/application that can be rendred in UI
        url=true -> for signed URL
*/
export const apiDownloadZIP = (reportName, statusInfo) => {
  // latest=true  -> returns latest name of all the files present(list api)
  const allFilesURL = `${ROOT_REPORTS}/${reportName}/directories/archives/files?latest=true`;

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: allFilesURL,
      method: API_METHOD_GET,
    },
    handlers: [getAllFileNamesSuccess, getAllFileNamesFailure],
    passData: { reportName, statusInfo },
    componentName: 'zipFilenames'
  }
};

/*
  apiDownloadPDF
    this is a 2 step process.
    1st, we get the list of all file's name present by passing `latest=true` as path parameter.
    once it is success, we invoke 2nd step, whcih downloads the file.
    when downloading the file, in following path parameter is passed
        inline=true  -> returns pdf/application that can be rendred in UI
        url=true -> for signed URL
*/
export const apiDownloadPDF = (reportName) => {
  const allFilesURL = `${ROOT_REPORTS}/${reportName}/directories/draft/files`;
  const errorCb = (error) => {
    return {
      type: DOWNLOAD_PDF_FILE_FAILURE,
      error: error
    };
  }
  const successCb = (response) => {
    return response.body ? {
      type: DOWNLOAD_PDF_FILE_SUCCESS,
      payload: response.body
    } : errorCb(new Error(response.errorType));
  };
  const fileNameSuccessCb = (response) => {
    const fileName = response.body.draft[0];
    return fileName ? {
      type: API_CALL, // API_CALL to make api call
      apiOptions: {
        apiName,
        path: `${allFilesURL}/${fileName}?url=true&inline=true`,
        method: API_METHOD_GET,
      },
      handlers: [successCb, errorCb],
      passData: { reportName },
      componentName: 'pdfDownload'
    } : errorCb(new Error('PDF Not Available'));
  }
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      // latest=true -> returns latest name of all the files present(list api)
      path: `${allFilesURL}?latest=true`,
      method: API_METHOD_GET,
    },
    handlers: [fileNameSuccessCb, errorCb],
    passData: { reportName },
    componentName: 'pdfFileNames'
  }
};