import { API_CALL_BEGIN, API_CALL_SUCCESS, API_CALL_FAILURE } from "../utils/api.middleware";


const loadingReducer = (state = {}, action) => {
    switch (action.type) {
      case API_CALL_BEGIN:
        return { ...state, [action.payload]: true }; // update state with selected report type
      case API_CALL_SUCCESS:
      case API_CALL_FAILURE:
        return { ...state, [action.payload]: undefined }; //gets the types of reports template
      default:
        return state;
    }
  };

  export default loadingReducer;
