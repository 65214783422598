import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

const SignIn = () => {
  useEffect(() => {
    Auth.federatedSignIn().catch(e=>{console.log(e)});
  });
  return null;
};

export default SignIn;