import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { rootReducer } from "../reducers/appReducer";
import { apiHandler} from "../utils/api.middleware";

const logMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV !== 'production'
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; //required to use Redux Devtools extension in Chrome

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(apiHandler, thunk, logMiddleware)));
