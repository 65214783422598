import React from 'react';

import App from '../App/App';
import configureAmplify from '../../utils/configureAmplify';

configureAmplify();

const AuthenticatedApp = () => {
    return (
      <div>
          <App />
      </div>
    );
  }

export default AuthenticatedApp;
