import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";

import HomePage from "../../components/HomePage/HomePage";
import NotFound from "../../components/NotFound/NotFound";
import AttachmentsHandler from "../AttachmentsHandler/AttachmentsHandler";
import ReportHandler from "../ReportHandler/ReportHandler";
import ReportKanban from "../ReportKanban/ReportKanban";
import Notification from "../../components/Notification/Notification";
import { usePostHog } from 'posthog-js/react'
import { getAuthUserInfo } from '../../utils/data.request';
import "./ReportWizard.scss";
import ToastComponent from "../../components/ToastComponent/ToastComponent";
import { apiGetUserPosthogRole } from "../../models/userDataModel/userDataAPI";

const ReportWizard = () => {
  const dispatch = useDispatch(); // this hook gives us dispatch method
  const location = useLocation();
  const user = useSelector(state => state.userData.user);
  const posthog = usePostHog()
  const permission = useSelector(state => state.userData.posthog);

  useEffect(() => {
    dispatch(getAuthUserInfo());

  }, [dispatch]);

  useEffect(() => {
    if (user && user.email) {
      if (localStorage.getItem("url") !== null && localStorage.getItem("url") !== location.pathname) {
        window.location.href = localStorage.getItem("url");
      } else {
        localStorage.removeItem("url");
      }
       //call permision api to get the posthog role for logging
       dispatch(apiGetUserPosthogRole(user.email));
    }
  }, [user, location, dispatch]);

  // call the posthog capture method to log the weather user is developer/bussines
  useEffect(() => {
      console.log('permission',permission)
      permission && posthog.register(permission.permission)
  }, [permission,posthog]);

  return (
    <div className="App">
      <Notification />
      <ToastComponent />

      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/kanban" component={ReportKanban} />
        <Route exact path="/AttachmentsHandler/:reportName/:fileName" component={AttachmentsHandler} />
        <Route path="/ReportHandler/:templateName/:reportName" component={ReportHandler} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  )

};
export default ReportWizard;
