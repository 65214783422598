import React, {useEffect, useState} from 'react';
import {ComboBox} from "@airbus/components-react";
import { getPNDropdownComponentReliability } from '../../utils/data.request';
import PropTypes from "prop-types";
import {useDebounce} from "../../hooks/useDebounce/useDebounce";


const MultiSelection = ({pns,setPns}) => {
    const [pnlist, setPnlist] = useState([])
    const [value, setValue] = useState('')
    const debouncedValue = useDebounce(value, 500)

    /**
     * useEffect to call PN List for dropdown with debounce hook
     */
    useEffect( () => {
        /* istanbul ignore else */
        if(debouncedValue){
            getPNDropdownComponentReliability(debouncedValue)
                .then(response => {
                    /* istanbul ignore else */
                    if(response){
                        setPnlist(response)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
   }, [debouncedValue])

    const handleChange = (event) => {
        const targetValue = event.target.value
        setValue(targetValue)
    }
    return (
        <>
          <div className='message-multi-select-pn'> Choose Part Number of interest</div>
            {
                <ComboBox
                    className="multi-select-pn"
                    multiple
                    limitChips={2}
                    placeholder="Choose Part Number"
                    options={[...pnlist,...pns].filter((v,i,s) => s.indexOf(v) === i) /* filter on unique PNs */}
                    onInput={(event) => {
                        handleChange(event)
                    }}
                    onChange={(pnsArray) => {
                        setPns(pnsArray)
                    }}/>
            }
        </>
    );

  }

export default MultiSelection

MultiSelection.propTypes = {
    pns: PropTypes.array,
    setPns: PropTypes.func
}
