import React from "react";
import PropTypes from "prop-types";
import ContentRenderer from "../ContentRenderer/ContentRenderer";
import { layoutsHandler } from "./LayoutHelper";
import "./ReportSections.scss";

// Arbitrary value chosen on how many page break indicators we should show
// Given the amount of data for the section at the time of writing, 5 seems to be best
export const NB_OF_PAGE_BREAK_INDICATORS = 5;

const ReportSections = (props) => {
  const { sectionData, sectionCls } = props;

  const getStyle = (location) => {
    const LOCATIONS = ["left", "middle", "right"];
    const updatedSectionData = layoutsHandler(sectionData);
    const layouts = updatedSectionData.layout
      .split("-")
      .map((item) => parseInt(item, 0));
    const total = layouts.reduce((acc, val) => acc + val, 0);
    const index = LOCATIONS.findIndex((item) => item === location);
    return {
      width: index > -1 ? `${(layouts[index] / total) * 100}%` : "100%",
      display: index > -1 ? (layouts[index] ? "block" : "none") : "block",
    };
  };

  const renderTitle = () => {

    return sectionData.label && !sectionData.hiddenLabel ? (
      <h1 className="text-style">
        {sectionData.label}
      </h1>
    ) : false;
  };
  const filterContents = (contents, location) => {
    return contents.filter((content) => content.location === location);
  };
  const renderContents = (location) => {
    const contents = filterContents(sectionData.contents, location);
    return contents.map(
      (item) =>
        (!item.hidden || item.hidden !== "true") && (
          <div key={item.name} id={`${sectionData.name}_${item.name}`}
            className={`report-content-cls ${sectionCls}`}>
            <ContentRenderer
              parentName={sectionData.label}
              content={item}
              sectionName={sectionData.name} />
          </div>
        )
    );
  };

  // section.hidden tofrom template hides the
  const isSectionNotHidden = !(sectionData.hidden === 'true' || sectionData.hidden === true || sectionData.hidden === 'NONE');

  return (isSectionNotHidden && (
    <div className={sectionCls && sectionCls.includes("exclude-print") ?"report-section-cls report-hidden-cls" : "report-section-cls"}>
      <section className={`section-root ${sectionCls}`} id={sectionData.name}>
        {
          // We dynamically add NB_OF_PAGE_BREAK_INDICATORS break indicators divs
          // 710px is also an arbitrary value as we thought it matched best most of the cases
          // page-ruler css class is defined in print.scss
          sectionCls && sectionCls.includes('technical-evaluation-section') &&
          Array.from(Array(NB_OF_PAGE_BREAK_INDICATORS), (_, i) => <div className="page-ruler" key={i} style={{top: `${i*710}px`}}></div>)
        }
        {renderTitle()}
        <div className="section-top" style={getStyle("top")}>
          {renderContents("top")}
        </div>
        <div className="section-main">
          <div className="section-left" style={getStyle("left")}>
            {renderContents("left")}
          </div>
          <div className="section-middle" style={getStyle("middle")}>
            {renderContents("middle")}
          </div>
          <div className="section-right" style={getStyle("right")}>
            {renderContents("right")}
          </div>
        </div>
        <div className="section-bottom" style={getStyle("bottom")}>
          {renderContents("bottom")}
        </div>
      </section>
    </div>
    ));
};

ReportSections.propTypes = {
  sectionData: PropTypes.shape({
    name: PropTypes.string,
    layout: PropTypes.string,
    contents: PropTypes.array,
    label: PropTypes.string,
    hiddenLabel: PropTypes.bool,
    hidden : PropTypes.string
  }),
  sectionCls: PropTypes.string
};
export default ReportSections;
