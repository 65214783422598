import { GET_USERDATA_SUCCESS, GET_USERDATA_FAILURE, UPDATE_USERDATA_SUCCESS, UPDATE_USERDATA_FAILURE, DELETE_FILE_FAILURE, UPLOAD_FILE_FAILURE, GET_IMAGEB64_SUCCESS, GET_IMAGEB64_FAILURE, GET_USER_PERMISSION_SUCCESS, GET_USER_PERMISSION_FAILURE } from "./userDataActionType";
import { API_CALL } from "../../utils/api.middleware";
import { GET_USER_DATA, API_METHOD_GET, UPDATE_USER_DATA, API_METHOD_PUT, API_METHOD_DEL, ROOT_REPORTS, API_METHOD_POST, GET_USER_PERMISSION } from "../../utils/api.actions";
import getConfig from "../../config";
const config = getConfig();
const apiName = config.API.endpoints[0].name; // api endpoint resolved from aws for the project

// get userdata. attachments is present here
export const apiGetUserData = (reportName) => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: GET_USER_DATA.replace("{key}", reportName),
      method: API_METHOD_GET,
    },
    handlers: [getUserDataSuccess, getUserDataFailure],
    componentName: 'userData-attachment'
  };
};
export const getUserDataSuccess = (data) => ({ type: GET_USERDATA_SUCCESS, payload: data })
export const getUserDataFailure = (error) => ({ type: GET_USERDATA_FAILURE, error: error })

// upload file to aws. if success, update userData with filename, ref and description
export const apiUploadFile = (reportName, fileInformation, userData, directory) => {
  const componentName = 'userDataUploadFile'
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: `${ROOT_REPORTS}/${reportName}/directories/${directory}/files`,
      method: API_METHOD_POST,
      params: {
        body: fileInformation
      }
    },
    handlers: [uploadFileSuccess, uploadFileFailure],
    passData: { reportName, userData, componentName },
    componentName
  };
}

export const apiUpdateFile = (reportName, fileInformation, userData, directory) => {
  const componentName = 'userDataUploadFile';
  const { id } = fileInformation;
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: `${ROOT_REPORTS}/${reportName}/directories/${directory}/files/${id}`,
      method: API_METHOD_PUT,
      params: {
        body: fileInformation
      }
    },
    handlers: [uploadFileSuccess, uploadFileFailure],
    passData: { reportName, userData, componentName },
    componentName
  };
}

//update report
export const uploadFileSuccess = (response, passData) => updateUserData(passData)
export const uploadFileFailure = (error) => ({ type: UPLOAD_FILE_FAILURE, error: error })

export const updateUserDataSuccess = (data) => ({ type: UPDATE_USERDATA_SUCCESS, payload: data })
export const updateUserDataFailure = (error) => ({ type: UPDATE_USERDATA_FAILURE, error: error })

// userdata in api is completely managed by frontend, so when updating or deleting or anyaction,
// expected final output should be passed, rather than just the changes
export const updateUserData = (passData) => {
  if (passData.userData && passData.userData.user) {
    delete passData.userData.user;
  }
  const attachmentsData = {
    key: passData.reportName,
    userdata: passData.userData
  }

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: UPDATE_USER_DATA.replace("{key}", passData.reportName),
      method: API_METHOD_PUT,
      params: {
        body: attachmentsData
      },
    },
    handlers: [updateUserDataSuccess, updateUserDataFailure],
    componentName: passData.componentName
  };
}


export const deleteFileSuccess = (response, passData) => updateUserData(passData)
export const deleteFileFailure = error => ({ type: DELETE_FILE_FAILURE, error: error });
//delete file and update userdata api
export const apiDeleteFile = (reportName, fileName, userData, directory) => {
  const componentName = 'userDataDeleteFile'
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: `${ROOT_REPORTS}/${reportName}/directories/${directory}/files/${fileName}`,
      method: API_METHOD_DEL,
    },
    handlers: [deleteFileSuccess, deleteFileFailure],
    // passData: { reportName, attachments, componentName },
    passData: { reportName, userData, componentName },
    componentName
  };
};


export const apiGetImage = (reportName, fileName, directory) => {
  const downloadSuccess = (response, passData) => {
    return { type: GET_IMAGEB64_SUCCESS ,payload: { key: passData.fileName, value: response.body } }
  }
  const downloadFailure = error => ({ type: GET_IMAGEB64_FAILURE, error: error });
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: `${ROOT_REPORTS}/${reportName}/directories/${directory}/files/${fileName}`,
      method: API_METHOD_GET,
      params: {},
    },
    handlers: [downloadSuccess, downloadFailure],
    passData: { fileName }
  };
};

// get user role for posthog logging
export const apiGetUserPosthogRole = (email) => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: GET_USER_PERMISSION.replace("{email}", email),
      method: API_METHOD_GET,
    },
    handlers: [getUserPermissionSuccess, getUserermissionFailure],
  };
};
export const getUserPermissionSuccess = (data) => ({ type: GET_USER_PERMISSION_SUCCESS, payload: data })
export const getUserermissionFailure = (error) => ({ type: GET_USER_PERMISSION_FAILURE, error: error })