/*
declare all the api routes to be used in here [one single place]
*/

export const API_METHOD_POST = 'post';
export const API_METHOD_GET = 'get';
export const API_METHOD_PUT = 'put';
export const API_METHOD_DEL = 'del';

export const IMAGE_LIST = '/res/images';
export const GET_TEMPLATE = '/res/templates';
export const TEMPLATE_LIST = '/res/templates';
export const GET_REPORT_LIST = '/api/reports';
export const PDF_GENERATE = '/pdf/generate';
export const ROOT_REPORTS = '/api/reports'
export const EXECUTE_QUERY='/api/reports/{key}/datasets/{id}/query';
export const EXECUTE_QUERY_FOUNDRY_POSTGRE='/api/foundry/postgre/data';
export const GET_USER_DATA='/api/reports/{key}?userdata=true';
export const UPDATE_USER_DATA='/api/reports/{key}';
export const DOSSIER_STATUS='/fxy/dossierstatus/{key}';
export const DOSSIER_STATUS_LIST='/fxy/dossierstatus';
export const GET_SYNC_STATUS='/api/reports/{key}?status=true';
export const SAVE_MPO_EDITS = `/fcs/editsmpo`;
export const EXECUTE_QUERY_PN_DROPDOWN='/api/foundry/postgre/data';
export const GET_USER_PERMISSION='/api/permission/{email}';