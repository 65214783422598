import React from 'react';
import loaderGif from "../assets/resources/images/loading.gif";
import { uploadImageHandler } from '../utils/uploadHelper';

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox='0 0 18 18'>
    <polygon className='ql-fill ql-stroke' points='6 10 4 12 2 10 6 10' />
    <path
      className='ql-stroke'
      d='M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9'
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox='0 0 18 18'>
    <polygon className='ql-fill ql-stroke' points='12 10 14 12 16 10 12 10' />
    <path
      className='ql-stroke'
      d='M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5'
    />
  </svg>
);

//Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}
export async function imageHandler() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();
  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();
    formData.append('image', file);
    // Save current cursor state
    const range = this.quill.getSelection(true);
    // Insert temporary loading placeholder image
    this.quill.insertEmbed(
      range.index,
      'image',
      loaderGif,
    );
    // Move cursor to right side of image (easier to continue typing)
    this.quill.setSelection(range.index + 1);
    // Fetches s3 URL of uploaded image
    const url = await uploadImageHandler(file);
    // Remove placeholder image
    this.quill.deleteText(range.index, 1);
    // Insert uploaded image
    this.quill.insertEmbed(range.index, 'image', url);
  };
}

// Add sizes to whitelist and register them
// const Size = Quill.import("formats/size");
// Size.whitelist = ["extra-small", "small", "medium", "large"];
// Quill.register(Size, true);

// // Add fonts to whitelist and register them
// const Font = Quill.import("formats/font");
// Font.whitelist = [
//   "arial",
//   "comic-sans",
//   "courier-new",
//   "georgia",
//   "helvetica",
//   "lucida"
// ];
// Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  clipboard: {
    matchVisual: false,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'list',
  'bullet',
  'indent',
  'image',
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id='toolbar'>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-underline' />
      <button className='ql-strike' />
    </span>
    <span className='ql-formats'>
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
      <button className='ql-indent' value='-1' />
      <button className='ql-indent' value='+1' />
    </span>
    <span className='ql-formats'>
      <button className='ql-image' />
    </span>
    <span className='ql-formats'>
      <button className='ql-undo'>
        <CustomUndo />
      </button>
      <button className='ql-redo'>
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
