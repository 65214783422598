import {UPDATE_TOAST} from "./toastActionType";

const toastReducer = (state, action) => {
    if(action.type === UPDATE_TOAST){
        return {...action.payload}
    }
    return {...state}
}

export default toastReducer
