import {
  SELECT_TEMPLATE,
  SELECT_REPORT,
  RECEIVE_DYNAMIC_DATA,
  CLEAR_REPORT
} from "./reportActionType";

export const selectTemplate = selectedTemplate => ({
  type: SELECT_TEMPLATE,
  payload: selectedTemplate
});
export const selectReport = selectedReport => ({
  type: SELECT_REPORT,
  payload: selectedReport
});

export const getDynamicData = dynamicData => ({
  type: RECEIVE_DYNAMIC_DATA,
  data: dynamicData
});

export const clearReport = () => ({ type: CLEAR_REPORT })