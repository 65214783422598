import React, {useCallback, useEffect, useState} from 'react';
import { IconButton, Spinner, Tooltip } from '@airbus/components-react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  PictureAsPdf,
  Save,
  Web,
  ViewModule,
  PersonAdd,
  HowToReg,
  Cached,
  PersonAddDisabled
} from '@airbus/icons/react';
import ReportSections from '../../components/ReportSections/ReportSections';
import reportTexts from '../../locales/reportTexts.json';
import { apiGetTemplate } from '../../models/reportModel/reportApi';
import {
  selectTemplate,
  selectReport,
  clearReport,
} from '../../models/reportModel/reportActions';
import { removeMessage } from '../../models/notificationModel/notificationActions';
import {
  clearDossierStatus,
  setFilterInfo,
} from '../../models/dossierModel/dossierStatusActions';
import { getSyncStatus } from '../../models/syncStatusModel/syncStatusApi';
import { apiGetUserData } from '../../models/userDataModel/userDataAPI';
import Footer from '../../components/Footer/Footer';
import PDFRenderer from '../../components/PDFRenderer/PDFRenderer';
import './ReportHandler.scss';
import { formatSaveData } from '../../utils/localStorageHelper';
import { apiSaveMpoEdits } from '../../models/teEditableModel/teEditableApi';
import { ROLES, STATUS, CARDINALITIES } from '../../config/dossier.status';
import SectionSelector from '../../components/SectionSelector/SectionSelector';
import { getData } from '../../utils/data.request';
import { updateChairmanStatus } from '../../models/dossierModel/dossierStatusApi';
import FeatureToggle from 'react-feature-toggles/lib/FeatureToggle';
import { FEATURES } from '../../config/feature.toggles';
import { getAccessRestriction } from '../../models/accessRestrictionModel/accessRestrictionAction';
import DailogBox from '../../components/DialogBox/DialogBox';
import { ReactComponent as BreakLineIcon} from '../../assets/resources/images/page-break.svg'


const ReportHandler = () => {
  const dispatch = useDispatch();
  const { teEditable, dossierStatus, userData, reports, loading, accessRestriction} = useSelector(
    (store) => store
  );
  const { dossierKey } = dossierStatus;
  sessionStorage.setItem("dossierKey",dossierKey);
  const report_level_key = `TextEdit_${dossierStatus.dossierType}_${dossierStatus.exerciseType}_${dossierStatus.dossierKey}`;

  const { email } = userData.user;
  const { teEditableSaveEdits } = loading;
  const SELECTOR_ENABLED_TYPES = ['system', 'structure'];
  const reportTemplate = reports.template;
  const allowedSelectorType =
    SELECTOR_ENABLED_TYPES.indexOf(dossierStatus.dossierType) > -1;
  const [selectorData, setSelectorData] = useState({ data: [], error: null });
  // save button and selector button will be displayed on 2 conditions
  // 1. if user has specialist role 2. dossier status is TE
  const isFloatingButtonVisible =
    ROLES.onlySpecialist.indexOf(userData.user.role) > -1 &&
    STATUS.TECHNICAL_EVALUATION === dossierStatus.status;

  const isContributorLogin = STATUS.MPE_REVIEW === dossierStatus.status &&
    ROLES.onlyContributor.indexOf(userData.user.role) > -1;

  const { templateName, reportName } = useParams();
  const [shouldRenderPdf, setShouldRenderPdf] = useState(false);
  const [showSelector, setShowSelector] = useState(false);
  const [cardinalityCheck, setCardinalityCheck] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showPageBreakBtn, setShowPageBreakBtn] = useState(true);
  const [isMultiInterval, setIsMultiInterval] = useState(false);
  const handleOpen = () => setShowSelector(true);
  const handleClose = () => setShowSelector(false);
  const handleSaveClick = () => {
    setSaveDisabled(true);
    setTimeout(() => {
      setSaveDisabled(false);
    }, 5000);
  };

  const [customDisableSave, setCustomDisableSave] = useState(false)
  const checkSaveStatusCallBack = useCallback((event) => {
   setTimeout(() => {
     const item = localStorage.getItem(report_level_key)
     /* istanbul ignore if */
     if(!item && event.key === report_level_key && !event.newValue){
         setCustomDisableSave(true)
       } else {
         setCustomDisableSave(false)
       }
   }, 100)
  },[report_level_key])

  useEffect(() => {
      window.addEventListener('storage', checkSaveStatusCallBack)
      return () => {
        window.removeEventListener('storage', checkSaveStatusCallBack)
      }
  }, [checkSaveStatusCallBack])

  const computeIsMultiInterval = (data) => {
    const columnList = ['primaryInterval', 'secondaryInterval', 'ternaryInterval'];
    return columnList.reduce((acc, columnName) => {
      return data[columnName] && data[columnName] !== 'N/A'
       ? acc + 1 : acc;
    }, 0) > 1;
  };

  useEffect(() => {
    //updating store with template and report information for header to get the data
    dispatch(selectTemplate(templateName));
    dispatch(selectReport(reportName));
    dispatch(apiGetUserData(reportName));
    dispatch(apiGetTemplate(templateName));
    return () => {
      dispatch(removeMessage('STATUS_ID'));
      dispatch(clearDossierStatus());
      dispatch(clearReport());
    };
  }, [dispatch, reportName, templateName]);

  useEffect(() => {
    dispatch(getAccessRestriction(dossierStatus, userData.user));
  }, [userData, dossierStatus, dispatch]);

  const {isMPEIconVisible ,
         contributoriconStyle,
         isMPEFeedbackReceived,
         isDossierInChairmanReview,
         tooltip,
         rollBackIconVisible
        } = accessRestriction.smiedEditable;

 // load selector information
  useEffect(() => {
    getData(reportTexts.SelectorSources, reportName)
      .then((response) => {
        const data = response.data;
        if (response && data) {
          setCardinalityCheck(
            data.every((d) => CARDINALITIES.includes(d.cardinality))
          );
          // For now we show selector for multi intervals dossiers only when system.
          setIsMultiInterval(dossierStatus.dossierType === 'system' && computeIsMultiInterval(data[0]));
          setSelectorData({ data: data });
          data &&
            data.length &&
            dispatch(
              setFilterInfo({
                cardinality: data[0].cardinality,
                mrbTask: data[0].mrbTask,
              })
            );
        }
      })
      .catch((error) => {
        setSelectorData({ error });
      });
  }, [dispatch, showSelector, reportName, dossierStatus.dossierType]);

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    teEditable.enableSave &&
      window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [teEditable]);

  const [editsSaveState, setEditsSaveState] = useState(teEditableSaveEdits);
  useEffect(() => {
    if (editsSaveState === true && teEditableSaveEdits === undefined) {
      setTimeout(() => {
        dispatch(getSyncStatus(dossierStatus.dossierKey));
      }, 5000);
    }
    setEditsSaveState(teEditableSaveEdits);
  }, [dispatch, teEditableSaveEdits, dossierStatus.dossierKey, editsSaveState]);

  const OnTEChangesSaved = () => {
    handleSaveClick();
    //the below PostReqSaveData will be used as post req body in api call
    const postReqSaveData = formatSaveData(
      teEditable.te_storage_key,
      dossierStatus,
      teEditable.disabledColList
    );
    dispatch(apiSaveMpoEdits(postReqSaveData));
  };
  const getSectionCls = (index, section) => {
    const replacementStrings =
      / - Predictive Graph| - Task Reports by Actual Interval and by Age| - Findings by Finding Type and by Climatic Areas/g;
    const splitInterval = section.label && section.label.replace(replacementStrings, '').split(' (');
    const splitLabel = splitInterval[0] && splitInterval[0].split(' - ');
    const first = splitLabel.shift();
    const last = splitLabel.pop();
    const splitKey = last ? last.split(' ') : false;
    const finalKey = splitKey
      ? `${splitKey[0].toLowerCase()}_${splitKey[2]}`
      : userData.sectionHidden && userData.sectionHidden[first] && !last
      ? 'dossier'
      : false;
    const interval = section.intervalType;
    const shouldExcludePrint =
      (finalKey &&
      (
        userData.sectionHidden[first] &&
        userData.sectionHidden[first].includes(finalKey)
      ))
      ||
      // If sections where duplicated for each interval, use sectionHidden to hide desired intervals
      (
        splitInterval.length > 1 &&
        userData.sectionHidden[interval] &&
        userData.sectionHidden[interval].includes(finalKey)
      )
      ? ' exclude-print'
      : '';
    const intervalClass = interval
      ? ` ${interval.replace(' ', '-').toLowerCase()}`
      : '';
    const technicalEvaluation = first === 'Technical Evaluation'
      ? ' technical-evaluation-section'
      : '';
    return !index
      ? 'first-section'
      : index === reportTemplate.sections.length - 1
      ? 'last-section'
      : `middle-section${shouldExcludePrint}${technicalEvaluation}${intervalClass}`;
  };
  const renderSections = () => {
    return reportTemplate.sections.map((section, index) => (
      <div key={section.name}>
        <ReportSections
          sectionData={section}
          sectionCls={getSectionCls(index, section)}
        />
      </div>
    ));
  };
  const renderMain = () => {
    return shouldRenderPdf ? (
       <PDFRenderer />
    ) : (
      <div className='report-handler-cls'>{renderSections()}</div>
    );
  };
  const toggleMain = () => {
    if (!shouldRenderPdf && teEditable.enableSave) {
      if (
        window.confirm(
          'Changes are not saved. Do you still want to switch the view ?'
        )
      ) {
        setShouldRenderPdf(!shouldRenderPdf);
      }
    } else {
      setShouldRenderPdf(!shouldRenderPdf);
    }
  };

  const renderToggle = () => {
    return shouldRenderPdf ? (
      <Web fontSize='large' className='web-icon-cls' />
    ) : (
      <PictureAsPdf fontSize='large' className='pdf-icon-cls' />
    );
  };

  /**
   * Function to show or hide the break indicators.
   * It adds a whole class (defined in src/print.scss) which define subclasses related to the break indicator
   */
  const showPageBreak = () => {
    setShowPageBreakBtn(!showPageBreakBtn);
    if (showPageBreakBtn){
      document.documentElement.classList.add('show-page-break');
    } else {
      document.documentElement.classList.remove('show-page-break');
    }
  };

  /**
   * Function to render the break indicator button / SVG.
   * Shows the button in pink when active, black when inactive
   * @return {JSX.Element}
   */
  const renderPageBreak = () => {
    return showPageBreakBtn ? (
      <BreakLineIcon fontSize='large' className='page-break-icon-cls' />
    ) : (
      <BreakLineIcon  fontSize='large' className='hide-page-break-icon-cls' />
    );
  };

  const OnClickSubmitToContributor = () => {
    const status = STATUS.MPE_REVIEW;
    const rollbackMPEReview = false;
    dispatch(
      updateChairmanStatus({
        status,
        email,
        dossierKey,
        rollbackMPEReview
      })
    );
  };

  const updateDossierToTERevokeBySpecialist = () => {
    const status = STATUS.TECHNICAL_EVALUATION;
    const rollbackMPEReview = true;
    dispatch(updateChairmanStatus({ status, email, dossierKey, rollbackMPEReview}));
    window.scrollTo({ top: 0 });
    confirmModalClose();
  }

  const confirmModalClose = () => {
    setConfirmModalOpen(false);
  };

  const renderConfirmBox = () => {
    return isConfirmModalOpen ? (
      <DailogBox
        className="confirm-modal"
        isOpen={isConfirmModalOpen}
        onConfirm={updateDossierToTERevokeBySpecialist}
        onClose={confirmModalClose}
        buttonName={'Confirm'}
        body={'Do you want to revoke the dossier from comments ?'}
        title={'Are you sure ?'}
      />
    ) : (
      false
    );
  };

  const mpeSubmitMethod = isFloatingButtonVisible ? OnClickSubmitToContributor : ()=>{};

  return reportTemplate ? (
    <React.Fragment>
      {renderConfirmBox()}
      <div className='spl-btn-cls'>
        <IconButton
            size='large'
            variant='secondary'
            className='toggle-btn-cls exclude-print'
            onClick={toggleMain}
          >
            <Tooltip label='Toggle View'>{renderToggle()}</Tooltip>
          </IconButton>
          {isFloatingButtonVisible && !isContributorLogin && !shouldRenderPdf && (
            <IconButton
              size='large'
              variant='secondary'
              className='pagebreak-btn-cls exclude-print'
              onClick={showPageBreak}
            >
              <Tooltip label={showPageBreakBtn ? 'Display Page Break Indication' : 'Remove Page Break Indication'}>{renderPageBreak()}</Tooltip>
            </IconButton>)}
        {isFloatingButtonVisible &&
          !isContributorLogin &&
          ((cardinalityCheck || isMultiInterval) &&
          allowedSelectorType) && (
            <IconButton
              size='large'
              variant='secondary'
              className='selection-btn-cls exclude-print'
              onClick={handleOpen}
            >
              <Tooltip label='Selection Menu'>
                <ViewModule />
              </Tooltip>
            </IconButton>
          )}
        {isFloatingButtonVisible && !isContributorLogin && (
          <IconButton
            className="save-te-btn-cls exclude-print"
            size="large"
            disabled={teEditable.isTEEditing|| customDisableSave || saveDisabled || !teEditable.enableSave || teEditableSaveEdits }
            onClick={OnTEChangesSaved}     >
            <Tooltip label='Save Technical Evaluation'>
              <Save />
            </Tooltip>
          </IconButton>
        )}
        <FeatureToggle featureName={FEATURES.SMIED_ACCESS}>
          {isMPEIconVisible &&  <IconButton
              className={`submit-cr-btn-cls exclude-print${contributoriconStyle}`}
              size='large'
              onClick={mpeSubmitMethod}
            >
              <Tooltip label={tooltip}>
               {isMPEFeedbackReceived ? <HowToReg /> :
                isDossierInChairmanReview ? <Cached />:
                    <PersonAdd />}
              </Tooltip>
            </IconButton>
          }
        </FeatureToggle>

        <FeatureToggle featureName={FEATURES.SMIED_ACCESS}>
          {rollBackIconVisible &&  <IconButton
              className={`revoke-cr-btn-cls exclude-print`}
              size='large'
              onClick={()=>setConfirmModalOpen(true)}
            >
              <Tooltip label='Revoke'>
                  <PersonAddDisabled />
              </Tooltip>
            </IconButton>
          }
        </FeatureToggle>

     </div>
      {renderMain()}
      {showSelector && (
        <SectionSelector
          showSelector={showSelector}
          userData={userData}
          selectorData={selectorData}
          reportName={reportName}
          handleClose={handleClose}
          sources={reportTexts.SelectorSources}
        />
      )}
      {!!reportName && <Footer />}
    </React.Fragment>
  ) : (
    <div>
      <Spinner
        dots
        style={{ paddingTop: 100 }}
        label={reportTexts.LoadingTemplate}
      />
    </div>
  );
};

export default ReportHandler;
