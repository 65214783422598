import {
  SELECT_REPORT,
  SELECT_TEMPLATE,
  GET_REPORTS_SUCCESS,
  GET_TEMPLATE_SUCCESS,
  GET_TEMPLATES_SUCCESS,
  UPDATE_ATTACHMENT_INFO_SUCCESS,
  UPDATE_ATTACHMENT_INFO_FAILURE,
  DOWNLOAD_PDF_FILE_SUCCESS,
  CLEAR_REPORT,
  GET_TEMPLATE_FAILURE,
  DOWNLOAD_PDF_FILE_FAILURE
} from "../reportModel/reportActionType";
import computeTOC from "../../models/reportModel/reportHelper";
import { TBLCONTENTS } from "../../config/content.category";
import { ATTACHMENT_DOWNLOAD_SUCCESS } from "../attachmentModel/attachmentActionType";
// NOTE This Reducer handles the template(contains report info)

const reportReducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_TEMPLATE:
      return { ...state, selectedTemplate: action.payload }; // update state with selected report type
    case SELECT_REPORT:
      return { ...state, selectedReport: action.payload }; // update state with selected report type
    case GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.payload.data,
        toc: computeTOC(action.payload.data, TBLCONTENTS)
      }; //table of content#
    case GET_TEMPLATES_SUCCESS:
      return { ...state, templates: action.payload.data }; //gets the types of reports template
    case GET_REPORTS_SUCCESS:
      return { ...state, reportList: action.payload.data }; //gets the types of reports template
    case ATTACHMENT_DOWNLOAD_SUCCESS:
      return { ...state, attachmentDownloaded: true };
    case UPDATE_ATTACHMENT_INFO_SUCCESS:
      return { ...state, updateReportStatus: action.payload.body.id };
    case UPDATE_ATTACHMENT_INFO_FAILURE:
      return { ...state, updateReportStatus: action.payload.body.id };
    case GET_TEMPLATE_FAILURE:
      return { ...state, template: { sections: [] } };
    case  DOWNLOAD_PDF_FILE_SUCCESS:
      return { ...state, pdfURL: action.payload };
    case  DOWNLOAD_PDF_FILE_FAILURE:
      return { ...state, pdfError: action.error };
    case CLEAR_REPORT:
      return {};
    default:
      return state;
  }

};

export default reportReducer;
