import React, {useEffect, useState} from 'react';
import './ToastComponent.scss';
import {Inline, Stack, Banner} from "@airbus/components-react";
import {useDispatch, useSelector} from "react-redux";
import {updateToast} from "../../models/toastModel/toastActions";

const ToastComponent = () => {

    const [duration, setDuration] = useState(5)
    const toast = useSelector(store => store.toast)
    const dispatch = useDispatch()
    const [toastData, setToastData] = useState([])
    const [show, setShow] = useState(false)

    const manageData = (input, status) => {
        const data = input.filter(elt => elt.status === status).reduce((acc, curr) => {
            const found = acc.find(item => item.message === curr.message);
            if (found) {
                found.lines.push(curr.index);
            } else {
                acc.push({message: curr.message, lines: [curr.index]});
            }
            return acc;
        }, []).map(item => ({message: item.message, lines: item.lines.filter(elt => elt)}));
        return {status, data};
    }

    useEffect(() => {
        const toastDataArray = Object.keys(toast).reduce((acc, curr) => {
            acc.push(toast[curr])
            return acc
        }, [])

        /* istanbul ignore else */
        if (toastDataArray.length) {
            const success = manageData(toastDataArray, "success")
            const warning = manageData(toastDataArray, "warning")
            const error = manageData(toastDataArray, "error")
            const result = [success, warning, error]
            const countData = result.reduce((acc, curr) => {
                acc += curr.data.length
                return acc
            }, 0)
            /* istanbul ignore next */
            countData > 1 ? setDuration(20) : setDuration(15)
            setToastData(result)
            setShow(true)
        }
    }, [toast])


    useEffect(() => {
        if (toastData.length > 0) {
            const timer = setTimeout(() => {
                setShow(false);
                dispatch(updateToast({}))
            }, duration * 1000);
            return () => clearTimeout(timer);
        }
    }, [toastData, duration, dispatch]);


    return (
        <div className={`toast-container-cls ${show ? '' : 'disappear'}`}>
            <Inline spacing="2-x">
                <Stack spacing="2-x" wrapChildren>
                    {toastData.map((elt, key) => {
                        const {data, status} = elt
                        return data.map((msgJson, index) => {
                            const {message, lines} = msgJson
                            return ((
                                    <Banner
                                        key={`${key}-${index}`}
                                        allowClose
                                        onClose={() => {
                                            setShow(false);
                                            dispatch(updateToast({}))
                                        }}
                                        variant={status}
                                        className="banner-cls"
                                    >

                                        {/* istanbul ignore next */
                                            lines.length ? `${message} : ${lines.join(', ')}` : message
                                        }
                                    </Banner>
                                )
                            );
                        });
                    })}
                </Stack>
            </Inline>
        </div>
    );
};
export default ToastComponent;
