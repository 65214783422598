import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Collapsible,
  Container
} from "@airbus/components-react";
import ContributorComment from "./ContributorComment";
import './ViewComment.scss';
import moment from "moment";
import { SECTION_DROPDOWN } from "../../config/dossier.status";
import { Info } from "@airbus/icons/react";

const ViewComment = (props) => {
  const { comments, mpeComments, toc} = props
  const isRejectionCommentsPresent = comments.length !== 0 ;
  const isChairmanCommentsPresent = mpeComments.length !== 0 ;
  const [mpeCommentData, setMpeCommentData] = useState([]);
  const [rejectCommentData, setRejectCommentData] = useState([]);
  const [mpeLoppData, setmpeLoppData] = useState([]);
  useEffect(() => {
    const latestChairmanCommentData = toc.reduce((acc, item) => {
     const targetComment = mpeComments.filter((comment) => comment.sectionName === item.label);
     acc.push({ title: item.label, comment: targetComment});
     return acc;
    }, []);
     const updatedComments = latestChairmanCommentData.filter(c => c.comment.length > 0);
     setMpeCommentData(updatedComments);


     const latestRejectionCommentData = toc.reduce((acc, item) => {
      const targetComment = comments.filter((comment) => comment.sectionName === item.label);
      acc.push({ title: item.label, comment: targetComment});
      return acc;
     }, []);
      const updatedRejectComments = latestRejectionCommentData.filter(c => c.comment.length > 0);
      setRejectCommentData(updatedRejectComments);

      const mpeloophistory = []
      const targetComment = mpeComments.filter((comment) => comment.sectionName === SECTION_DROPDOWN.MPE_COMMENT_KEY);
      const updatedmpeloophistory = targetComment.filter(c => c.comment.length > 0);
      updatedmpeloophistory.length > 0 && mpeloophistory.push({ title: '', comment: targetComment})
      updatedmpeloophistory.length > 0 && setmpeLoppData(mpeloophistory)
  }, [mpeComments, comments, toc]);

  const getCommentHisotryHeader = <div className="comment-history-header" ><Info /><span className="comment-history-title">Comments history status</span></div>

  return (
    <Container className="view-comment-cls">
       {mpeLoppData.length > 0 &&
          <div className="w-480 mpe-comment-history">
            <Collapsible title={getCommentHisotryHeader}>
            {mpeLoppData.map((comments, index) => (
              <ContributorComment comment={comments} key={index} index={index} isAvatarInitials={true} isNoComment={'-nocomment'} />
            ))}
            </Collapsible>

          </div>
      }
      {isRejectionCommentsPresent  && <h4 className="heading">Rejection Feedback/Comments</h4> }
      {rejectCommentData.map((rejectComment, index) => (
        <div className="paragraph" key={index}>
          <h6>{rejectComment.title}</h6>

          {rejectComment.comment.map((comment , i) => (
            <div key={`comment-card-${i}`}>
              <div className="comment-desc" >
                <span className="header-content">{comment.reason} </span>
                <span className="comment-date">{comment.createdAt && moment(comment.createdAt).format('MMMM DD YYYY, HH:mm:ss')} </span>
              </div>
            </div>
         )
      )}
      </div>
      ))}

     {isChairmanCommentsPresent && mpeCommentData.length > 0 && <h4 className="heading">Feedback/Comments</h4> }
     {
       mpeCommentData.map((comments, index) => (
        <ContributorComment comment={comments}  key={index} index={index} isAvatarInitials={true}/>
     ))}
    </Container>
  )
};

ViewComment.defaultProps = {
  comments: [],
  mpeComments : [],
  toc: [],
}

ViewComment.propTypes = {
  comments: PropTypes.array.isRequired,
  mpeComments : PropTypes.array.isRequired,
  toc: PropTypes.array.isRequired,
}

export default ViewComment;
