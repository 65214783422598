const DataProps = {
  line: {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderWidth: 1,
    pointHoverRadius: 6,
    pointHoverBorderWidth: 2,
    pointRadius: 4,
    pointHitRadius: 10,
    order: 0
  },
  bar: {
    borderWidth: 1,
    hoverBackgroundColor: '',
    order: 1
  },
  scatter: {
    pointRadius: 6,
    pointHitRadius: 10,
    order: 0
  }
};
export default DataProps;
