import { ATTACHMENT_DOWNLOAD_SUCCESS } from "./attachmentActionType";

const initialState = {
  attachments: []
};
const attachmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ATTACHMENT_DOWNLOAD_SUCCESS:
      return { ...state, attachmentDownloaded: true }
    default: {
      return state;
    }
  }
};

export default attachmentReducer;
