import {
  API_METHOD_GET,
  DOSSIER_STATUS,
  API_METHOD_PUT,
  DOSSIER_STATUS_LIST
} from "../../utils/api.actions";
import getConfig from "../../config";
import { API_CALL } from "../../utils/api.middleware";
import * as statusActions from "./dossierStatusActionType";
const config = getConfig();
const apiName = config.API.endpoints[0].name; // api endpoint resolved from aws for the project

const getStatusSuccess = (data, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: statusActions.GET_STATUS_SUCCESS,
  payload: data.body,
  user: store.userData.user
});
const getStatusFailure = (error, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: statusActions.GET_STATUS_FAILURE,
  error: error,
  user: store.userData.user
});
const listStatusSuccess = (data, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: statusActions.LIST_STATUS_SUCCESS,
  payload: data.body,
  user: store.userData.user
});
const listStatusFailure = (error, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: statusActions.LIST_STATUS_FAILURE,
  error: error,
  user: store.userData.user
});

const updateStatusSuccess = (data, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: statusActions.UPDATE_STATUS_SUCCESS,
   payload: data.body,
   user: store.userData.user
  });
const updateStatusFailure = (error, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: statusActions.UPDATE_STATUS_FAILURE,
  error: error,
  user: store.userData.user
 });

export const getStatus = (dossierKey) => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: DOSSIER_STATUS.replace('{key}', dossierKey),
      method: API_METHOD_GET,
    },
    handlers: [getStatusSuccess, getStatusFailure],
    componentName: 'getDossierStatus'
  }
}

export const updateStatus = (options) => {
  const { status, email, dossierKey, comments, mpeCommentsStatus, stats } = options;
  const params = {
    body: {
      status: status,
      modifiedBy: email,
      comments,
      mpeCommentsStatus,
      stats
    }
  };

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: DOSSIER_STATUS.replace('{key}', dossierKey),
      method: API_METHOD_PUT,
      params
    },
    handlers: [updateStatusSuccess, updateStatusFailure],
    componentName: 'updateDossierStatus'
  }
}

export const apiListDossierStatus = () => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: DOSSIER_STATUS_LIST,
      method: API_METHOD_GET
    },
    handlers: [listStatusSuccess, listStatusFailure],
    componentName: 'listDossierStatus'
  }
}


export const updateChairmanStatus = (options) => {
  const { status, email, dossierKey, comments, stats ,mpeComments ,mpeCommentsStatus , rollbackMPEReview = false} = options;
  const params = {
    body: {
      status: status,
      modifiedBy: email,
      comments,
      stats,
      mpeComments,
      mpeCommentsStatus,
      rollbackMPEReview,
      restrictPhonoUpdate  : true,
    }
  };

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: DOSSIER_STATUS.replace('{key}', dossierKey),
      method: API_METHOD_PUT,
      params
    },
    handlers: [updateStatusSuccess, updateStatusFailure],
    componentName: 'updateDossierStatus'
  }
}