/**
 * Given an object (details), and a string with properties separated by dots
 * (key), returns the value in details corresponding to the path specified in
 * key.
 */
const findProperty = (details, key) => {
  const keys = key ? key.split(".") : [];
  let value = keys.length ? details : "";
  while (value && keys.length) {
    value = value[keys.shift()];
  }
  return value;
}

/**
 * Returns the property in details corresponding to the path in key, returns null otherwise
 */
export const getIsEditable = (details) => {
  const value = findProperty(details, "editable");
  if (value === undefined) {
    return null;
  }
  return value;
};

/**
 * Returns the property in details corresponding to the path in key, and if it's
 * undefined return the property corresponding to defaultKey.
 */
export const getValue = (details, key, defaultKey) => {
  let value = findProperty(details, key);
  if (value === undefined) {
    value = findProperty(details, defaultKey);
  }
  return value || key || "";
};

export const prepareSubtitle = (details, subtitle) => {
  let actualSubtitle;
  let value = getValue(details, subtitle.value, subtitle.defaultValue);
  if (value && (value.startsWith('userData.edits') || value.startsWith('details.data'))) {
    value = subtitle.label;
  }
  const isEditable = getIsEditable(subtitle);
  if (subtitle.append === "post") {
    actualSubtitle = value + " " + subtitle.label;
  } else if (subtitle.append === "pre") {
    actualSubtitle = subtitle.label + " " + value;
  } else {
    actualSubtitle = value;
  }
  const key = subtitle.value.split(".");
  return { value: actualSubtitle, isEditable, keyName: key[key.length - 1] };
};
