import { combineReducers } from "redux";
import reports from "../models/reportModel/reportReducer";
import image from "../models/imageModel/imageReducer";
import attachments from "../models/attachmentModel/attachmentReducer";
import loading from "./loadingReducer";
import userData from "../models/userDataModel/userDataReducer";
import notification from "../models/notificationModel/notificationReducer";
import dossierStatus from "../models/dossierModel/dossierStatusReducer";
import dossierStatusList from "../models/dossierModel/dossierStatusListReducer";
import dossierStats from "../models/dossierModel/dossierStatsReducer";
import syncNotification from "../models/syncStatusModel/syncStatusReducer";
import teEditable from "../models/teEditableModel/teEditableReducer";
import accessRestriction from "../models/accessRestrictionModel/accessRestrictionReducer.js";
import toast from "../models/toastModel/toastReducer"
export const rootReducer = combineReducers({
  reports,
  image,
  attachments,
  loading,
  userData,
  notification,
  dossierStatus,
  dossierStatusList,
  dossierStats,
  syncNotification,
  teEditable,
  accessRestriction,
  toast,
});

