import React, {useEffect, useState} from 'react';
import {cloneDeep} from "lodash";
import {getData} from "../../../utils/data.request";
import {updateDossierStats} from "../../../models/dossierModel/dossierStatusActions";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Banner, Table, TableBody, TableHead} from "@airbus/components-react";
import {defaultFormatColumns, formatTableBody, formatTableHeader} from "./TableUtils";
import ResetChangesDialog from "../Dialogs/ResetChangesDialog/ResetChangesDialog";
import EditRowDialog from "../Dialogs/EditRowDialog/EditRowDialog";
import idx from "idx";
import {DERIVED_SYNC_STATUS, ROLES, STATUS} from "../../../config/dossier.status";
import {
    getDataFromLS,
    removeDataFromLS,
    removeKey,
    removeKeyFromLS,
    setDataToLS,
    updateDataFromLSCR
} from "../LSUtils";
import {getSectionKey} from "../../../utils/localStorageHelper";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import {setSaveStatus} from "../../../models/teEditableModel/teEditableAction";
import * as LABELS from "../../../config/content.label";
import DialogComponentReliabilityContent
    from "../../ComponentReliability/Dialogs/content/DialogComponentReliabilityContent";
import DialogComponentReliabilityHeader
    from "../../ComponentReliability/Dialogs/header/DialogComponentReliabilityHeader";
import DialogComponentReliabilityAction
    from "../../ComponentReliability/Dialogs/action/DialogComponentReliabilityAction";
import PropTypes from "prop-types";
import DialogPopup from '../Dialogs/DialogPopup/DialogPopup';
import {
    CANCEL_LABEL,
    CONFIRM_LABEL,
    DELETE_LABEL,
    DIALOGPOPUP_CR_DELETE,
    DIALOGPOPUP_CR_RESET
} from '../../../config/content.popup';
import MultiSelection from '../../MultiSelectionPN/MultiSelectionPN';
import './ConsolidatedTasksTable.scss'
import {TECH_EVAL_COLUMN_NAMES} from "../../Essay/EssayUtils";

/**
 * Container for the consolidated tasks part. Consist of a reset btn, an add row btn, and the table itself
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const TableComponent = (props) => {

    /**
     * List of states:
     *
     * - rawTableData: The data retrieved from the backend
     * - editDialogOpen: A flag state to determine if the dialog (add OR edit) is opened
     * - rowIndex: A number to determine which row we're editing (set to -1 when adding a row)
     * - dataToDisplay: The data displayed in the table. Initially has the same data as rawTableData but changes
     *                  when the user makes changes in the table.
     *
     */
    const dispatch = useDispatch();
    const {sources, details} = idx(props, (_) => _.content);
    const label = idx(props, _ => _.content.label)

    const buttonContent = props.buttonContent

    // const to add content, to props of ResetChangesDialog
    const content = props.content;

    const [displayDialogPopup, setDisplayDialogPopup] = useState(false);

    const {reportName} = useParams();
    const [rawTableData, setRawTableData] = useState();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [rowIndex, setRowIndex] = useState();
    const [dataToDisplay, setDataToDisplay] = useState(undefined);
    const [deleteAction, setDeleteAction] = useState()
    const [pns, setPns] = useState([])
    const [labels, setLabels] = useState(details.labels)
    const [showBannerCR, setShowBannerCR] = useState(true)


    const allowedEdits = details.allowedEdits && !Array.isArray(details.allowedEdits) ? details.allowedEdits : {
        addRow: true,
        editRow: true,
        deleteRow: true,
        disabledEdits: []
    };
    const {dossierStatus, userData, syncNotification, reports} =
        useSelector((store) => store);
    const defaultInterval = useSelector((store) => store.dossierStats?.dossier?.data?.['Current Interval']);

    const section_level_key = getSectionKey(sources, details.title, dossierStatus.filters);
    const report_level_key = `TextEdit_${dossierStatus.dossierType}_${dossierStatus.exerciseType}_${dossierStatus.dossierKey}`;


    const store = useSelector(state => state)
    const titleInfo = store.dossierStats?.dossier?.data?.titleInfo?.[0];
    const mpdTaskMaintenanceProgram = titleInfo?.mpdTaskMaintenanceProgram;

    const systemType = reports.selectedTemplate.includes('system')
    const zonalType = reports.selectedTemplate.includes('zonal')
    const CT = props.parentName === "Consolidated Task"
    const TR = props.parentName === "Task Review"
    const hideStatement = (!systemType && !TR) && (!zonalType || !CT) && (CT || TR)

    /*
      Function to persist the data in local storage, both when adding and editing a row
     */
    const persistData = (editedData) => {
        if(TR && details.title === LABELS.COMPONENT_RELIABILITY){
            const item = JSON.parse(localStorage.getItem(report_level_key))
            localStorage.setItem(report_level_key, JSON.stringify(removeKey(item, TECH_EVAL_COLUMN_NAMES["TA_CR_DA"])))

        }
        setDataToLS(report_level_key, editedData, section_level_key);
        setEditDialogOpen(false);
        dispatch(setSaveStatus(report_level_key));
    }

    /*
      Function to remove a row from the Consolidated Tasks table.
      We re-write the entire table instead of editing in-place.
     */
    const removeRow = (index) => {
        updateDataFromLSCR(report_level_key);
        const copiedData = [...dataToDisplay].reduce((acc, curr) => {
            const map = {}
            for (const key in curr) {
                if (key !== "statusCR") {
                    map[key] = curr[key]
                }
            }
            acc.push(map)
            return acc
        }, []);
        copiedData.splice(index, 1);
        setLabels(details.labels)
        setDataToLS(report_level_key, copiedData, section_level_key);
        setDataToDisplay(copiedData);
        dispatch(setSaveStatus(report_level_key));
    };

    /**
     * function to get dialog renderer depending on label and section
     * @param label
     * @param section
     * @returns {*|null}
     */
    const getDialogRenderer = (label, section) => {
        const COMPONENT_MAP = {
            [LABELS.COMPONENT_RELIABILITY]: {
                "header": DialogComponentReliabilityHeader,
                "content": DialogComponentReliabilityContent,
                "action": DialogComponentReliabilityAction
            },
        };
        return COMPONENT_MAP[label]?.[section] || null
    }

    /*
      Simple function to build props for the EditDialog component in order not to bloat the render function
     */

    const buildPropsForEditDialog = () => ({
            title: details.title,
            data: dataToDisplay,
            openDialog: editDialogOpen,
            openDialogHandler: setEditDialogOpen,
            columns: defaultFormatColumns(labels),
            defaultInterval,
            rowIndex,
            setRowIndex,
            persistData,
            DialogContentRenderer: label ? getDialogRenderer(label, "content") : <></>,
            DialogHeaderRenderer: label ? getDialogRenderer(label, "header") : <></>,
            DialogActionRenderer: label ? getDialogRenderer(label, "action") : <></>
        }
    )

    const displayEdit = ROLES.onlySpecialist.indexOf(userData.user.role) > -1 &&
        STATUS.TECHNICAL_EVALUATION === dossierStatus.status &&
        syncNotification.sync_info &&
        syncNotification.sync_info.status !== DERIVED_SYNC_STATUS.IN_PROGRESS;

    useEffect(() => {
        getData(sources, reportName)
            .then((response) => {
                /**
                 * Gets the data from backend, and merge the results with things already in local storage
                 * Then sets the merged result in dataToDisplay
                 */
                /* istanbul ignore else */
                if (response && response.data) {
                    setRawTableData(cloneDeep(response));
                    const dataInLS = getDataFromLS(report_level_key, section_level_key);
                    dataInLS ? setDataToDisplay(cloneDeep(dataInLS)) : setDataToDisplay(cloneDeep(response.data));
                }
                response &&
                response.data &&
                response.data.length &&
                dispatch(updateDossierStats(reportName, response));
            })
            .catch((err) => {
                setRawTableData({error: err});
            });
    }, [sources, reportName, dispatch, report_level_key, section_level_key]);

    /**
     * Simple function to reset the changes made in the local storage.
     * We also set the data to display to the raw data we got from backend
     */
    const resetChanges = () => {
        removeKeyFromLS(report_level_key, section_level_key)
        removeKeyFromLS(report_level_key, TECH_EVAL_COLUMN_NAMES["TA_CR_DA"])
        const item = JSON.parse(localStorage.getItem(report_level_key))
        if(item && Object.keys(item).length === 0){
            removeDataFromLS(report_level_key)
        }

        /* istanbul ignore else */
        if (rawTableData && rawTableData.data) {
            setDataToDisplay(cloneDeep(rawTableData.data));
            setLabels(details.labels)
        }
    };

    /*
      Props for the ResetChangesDialog component as const in order not to bloat the render function
     */
    const resetChangesDialogProps = {
        title: details.title,
        openEditDialog: setEditDialogOpen,
        setCurrentRow: setRowIndex,
        resetChanges,
        buttonContent,
        program: mpdTaskMaintenanceProgram,
        report_level_key,
        section_level_key,
        rawTableData,
        persistData,
        setDataToDisplay,
        setDisplayDialogPopup,
        setDeleteAction,
        content,
        pns,
        labels,
        setLabels
    }

    const displayPopup = () => {
        setDisplayDialogPopup(true)
    };
    /**
     * function that return object that content depends on action type (delete or reset)
     * @param action
     * @returns {{buttons: [{variant: string, callback: removePopup, className: string, label: string},{variant: string, callback: removeElement, className: string, label: string}], text: string, title: string}|{buttons: [{variant: string, callback: removePopup, className: string, label: string},{variant: string, callback: resetTable, className: string, label: string}], text: string, title: string}}
     */
    const popupData = (action) => {
        if (action === 'deleteRow') {
            return {
                title: DIALOGPOPUP_CR_DELETE.title,
                text: DIALOGPOPUP_CR_DELETE.text,
                buttons: [
                    {
                        label: CANCEL_LABEL,
                        className: 'delete-cancel',
                        variant: 'secondary',
                        callback: removePopup
                    },
                    {
                        label: DELETE_LABEL,
                        className: 'delete-confirm',
                        variant: 'error',
                        callback: removeElement
                    }
                ]
            }
        } else /* istanbul ignore else */ if (action === 'resetTable') {
            return {
                title: DIALOGPOPUP_CR_RESET.title,
                text: DIALOGPOPUP_CR_RESET.text,
                buttons: [
                    {
                        label: CANCEL_LABEL,
                        className: 'reset-cancel',
                        variant: 'secondary',
                        callback: removePopup
                    },
                    {
                        label: CONFIRM_LABEL,
                        className: 'button-normal reset-confirm',
                        variant: 'primary',
                        callback: resetTable
                    }
                ]
            }
        }
    };
    const resetTable = () => {
        resetChanges();
        setDisplayDialogPopup(false);
    };
    const removeElement = () => {
        removeRow(rowIndex);
        setDisplayDialogPopup(false);
    };
    const removePopup = () => {
        setDisplayDialogPopup(false);
    };


    /**
     * useEffect to update the data to display whenever the Dialog box is opened or closed.
     * The EditDialog has a state on its own so we need to trigger a change for the data to be displayed in the table.
     */
    useEffect(() => {
        const dataInLS = getDataFromLS(report_level_key, section_level_key);
        if (dataInLS) {
            setDataToDisplay(cloneDeep(dataInLS));
            setLabels(details.labels)
        }
    }, [editDialogOpen, report_level_key, section_level_key, details.labels]);

    /**
     * useEffect to dispatch mapping dataset/dataToDisplay in redux store
     */
    useEffect(() => {
        const datasets = sources.map(source => source.value.dataset)
        for (const dataset of datasets) {
            if (dataToDisplay && dataset !== "com_report_details.csv") {
                dispatch(updateDossierStats(reportName, {dataset, data: dataToDisplay}))
            }
        }
    }, [dataToDisplay, sources, reportName, dispatch])

    /*
      Props for the TableBody component as const in order not to bloat the render function
     */
    const formatTableBodyProps = {
        data: dataToDisplay,
        columns: labels,
        tableStyles: details.tableStyles,
        allowedEdits,
        dialogHandler: setEditDialogOpen,
        setRowIndex,
        displayPopup,
        displayEdit,
        setDeleteAction,
        title: details.title
    }

    return dataToDisplay ?
        !hideStatement && (<>
            <div className="title-container">
                {details.title === LABELS.COMPONENT_RELIABILITY &&
                    <div className="title-div">
                        {LABELS.COMPONENT_RELIABILITY}
                    </div>}
            </div>
            {details.title === LABELS.COMPONENT_RELIABILITY &&
                showBannerCR &&
                <Banner className="banner-component-reliability exclude-print" variant="error"
                        onClose={() => setShowBannerCR(false)}>
                    Any update of the table will erase changes done in Technical Evaluation for Component Reliability
                </Banner>}


            <div className="table-container">
                {allowedEdits.addRow && displayEdit && (
                    <div className="dropdown-buttons exclude-print">
                        {details.title === LABELS.COMPONENT_RELIABILITY && (
                        <div className='multiselection-dropdown'>
                            <MultiSelection pns={pns} setPns={setPns}/>
                        </div>)
                        }
                        <ResetChangesDialog {...resetChangesDialogProps}/>
                    </div>
                )
                }
                <Table
                    className="table"
                    style={{tableLayout: details.tableLayout}}
                >
                    <TableHead className="table-head">
                        {
                            formatTableHeader(labels, details.tableStyles, displayEdit, allowedEdits)
                        }
                    </TableHead>
                    <TableBody>
                        {formatTableBody(formatTableBodyProps)}
                    </TableBody>
                </Table>
                <EditRowDialog {...buildPropsForEditDialog()} />
                {deleteAction && <DialogPopup
                    displayDialogPopup={displayDialogPopup}
                    setDisplayDialogPopup={setDisplayDialogPopup}
                    title={popupData(deleteAction).title}
                    mainText={popupData(deleteAction).text}
                    buttons={popupData(deleteAction).buttons}
                    rowIndex={rowIndex}
                />}
            </div>
        </>) : (
            <ErrorHandler error={rawTableData?.error}/>
        );
};

export default TableComponent;

TableComponent.propTypes = {
    buttonContent: PropTypes.string,
    parentName: PropTypes.string,
    content: PropTypes.object
}
