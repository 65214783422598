import React from 'react'
import idx from 'idx'
import { useSelector } from 'react-redux';

import ImagePreview from '../ImagePreview/ImagePreview';
import NoData from "../NoData/NoData";

import './ImageRenderer.scss';


const ImageRenderer = (props) => {
  const { sources, details } = idx(props, _ => _.content);
  const { height, width } = details;
  const userData = useSelector(store => store.userData);
  const renderSources = () => {
    return userData.images.length ? userData.images.map((image) => (
      <ImagePreview key={image.name} source={sources[0]} imageName={image.name} height={height} width={width} />)
    ) : ( <NoData sources={sources} />)
  };
  return (
    <div className="image-renderer-cls">
      {renderSources()}
    </div>
  );
}

export default ImageRenderer;

