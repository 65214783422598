export const getToCConfig = (template, tocString) => {
  const tocSection = getSectionWithCategory(template, tocString);
  return tocSection
    ? tocSection.contents.find(content => content.category === tocString)
    : null;
};
/**
 * Ignores a section or content based on ignore field or section.hidden field
 * @param {*} template getting data from compute TOC
 * @param {*} depth to display sub content or not
 * @param {*} ignore sections to ignore in Table of Content
 */
const computeToCList = (template, depth, ignore) => {
  return template.sections.reduce((acc, section) => {
    if (ignore.indexOf(section.name) === -1 && section.hidden !== true && section.hidden !== 'true' && section.hidden !== 'NONE') {
      acc.push({
        label: section.defaultLabel || section.label,
        name: section.name,
        contents:
          depth > 0
            ? section.contents.map(content => ({
                label: content.label,
                name: content.name
              }))
            : []
      });
    }
    return acc;
  }, []);
};

/**
 * 
 * @param {*} template template data
 * @param {*} category category of content
 */

export const getSectionWithCategory = (template, category) => {
  return template.sections.find(section =>
    section.contents.find(content => content.category === category)
  );
};

/**
 * Getting template from reducer
 * @param {*} template to fetch template data from reports
 */

const computeTOC = (template, categoryName) => {
  const tocConfig = getToCConfig(template, categoryName);
  if (tocConfig) {
    const { depth, ignore } = tocConfig.details;
    const tocList = computeToCList(template, depth, ignore);
    return tocList;
  } else {
    return false;
  }
};

export default computeTOC;
