import React from 'react'
import {Tooltip} from "@airbus/components-react";
import "./DialogComponentReliabilityHeader.scss"
import {Info} from "@airbus/icons/react";


/**
 * Title for Dialog popup Add P/N Details CR
 * @returns {JSX.Element}
 * @constructor
 */
const DialogComponentReliabilityHeader = () => {

    /**
     * label tooltip renderer
     * @returns {JSX.Element}
     */
    const labelTooltip = () => {
        return (
            <div className="tooltip-content">
                <div className="tooltip-item">
                    <span className="span-star"> * </span>
                    <span>The Component is mandatory</span>
                </div>
                <div className="tooltip-item">
                    <span className="span-star"> ** </span>
                    <span>The P/N or P/N Family must be filled</span>
                </div>
            </div>
        )
    }

    return (<div className="header-main">
        <div className="title">Add P/N Details</div>
        <Tooltip placement="bottom" label={labelTooltip()}>
            <div className="tooltip">
                <Info/>
            </div>
        </Tooltip>

    </div>);
}

export default DialogComponentReliabilityHeader
