import { Storage } from 'aws-amplify';

/**
 * Takes image file object upload to s3 and returns signed url
 * @param {Object} file
 * @returns uploaded files signed url
 */
export const uploadImageHandler = async (file) => {
  const fileName = `${sessionStorage.getItem('dossierKey')}/${
    file.name
  }_${Date.now()}`;
  try {
    const fileData = await Storage.put(fileName, file, {
      contentType: 'image/png', // contentType is optional
      /*use below to show upload percentage
      progressCallback: (progress) => {
        const uploadPerc = Math.round((progress.loaded / progress.total) * 100);
        console.log(`Uploaded: ${uploadPerc} %`);
      },
      */
    });
    const url = await getImageURL(fileData.key);
    return url;
  } catch (error) {
    console.error('Error uploading file: ', error);
  }
};

/**
 * use storage method to get image url using unique key
 * @param {String} fileKey
 * @returns signed url
 */
export const getImageURL = async (fileKey) => {
  try {
    return await Storage.get(fileKey, {
      contentType: 'image/png',
      expires: 7200,
    });
  } catch (error) {
    console.error('Error fetching file URL: ', error);
  }
};
