import React, {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import idx from "idx";
import {
    Container,
    IconButton,
    Tooltip,
    Toggle,
} from "@airbus/components-react";
import ReactQuill from "react-quill";
import parser from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import {Edit, Visibility, Restore} from "@airbus/icons/react";
import {getData} from "../../utils/data.request";
import QuillToolbar, {modules, formats} from "../../lib/quillToolbar";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import NoData from "../NoData/NoData";
import {
    essayDataHandler,
    getEditableObj,
    essayDataReset,
    storageEssayDataHandler, buildParagraph, MAP_TITLE_STORE_DATASET,
} from "./EssayUtils";
import "./Essay.scss";
import {setSaveStatus} from "../../models/teEditableModel/teEditableAction";
import FeatureToggle from "react-feature-toggles/lib/FeatureToggle";
import {FEATURES} from "../../config/feature.toggles";
import {
    saveUpdatedStorageData,
    resetSavedDatafromLS,
    getSectionKey,
    checkDataInStorage,
} from "../../utils/localStorageHelper";
import {
    setTEStorageKey,
    setTEDisabledColumns,
    setIsTEEditable,
} from "../../models/teEditableModel/teEditableAction";
import {ROLES, STATUS} from "../../config/dossier.status";
import {
    DERIVED_SYNC_STATUS,
    ED_DISABLE_TYPE,
} from "../../config/dossier.status";

const Essay = (props) => {
    const {reportName} = useParams();
    const {dossierStatus, userData, teEditable, syncNotification, dossierStats} = useSelector(
        (store) => store
    );
    const {dossier} = dossierStats
    const storeData = dossier?.data
    const {dossierKey, dossierType, exerciseType, filters} = dossierStatus;
    const {enableSave} = teEditable;
    const {sources, label} = idx(props, (_) => _.content);
    const section_level_key = getSectionKey(sources, label, filters);
    const report_level_key = `TextEdit_${dossierType}_${exerciseType}_${dossierKey}`;
    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {columns: [], data: [], error: null}
    );
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(null);
    const [essayData, setEssayData] = useState(null);
    const [editedEssayData, setEditedEssayData] = useState({});
    const [editedHeadings, setEditedHeadings] = useState([]);

    /**
     * useCallback to set essay data based on LocalStorage or buildParagraph with data from CR Redux store
     * @type {(function(): void)|*}
     */
    const customEssayDataSetter = useCallback(() => {
        setEssayData(e => {
            /* istanbul ignore if */
            if (e) {
                const dataFiltered = Object.entries(e)
                    .filter(([, value]) => MAP_TITLE_STORE_DATASET[value.heading])
                    .reduce((acc, [key, value]) => {
                        const mappedTitleStoreValue = MAP_TITLE_STORE_DATASET[value.heading]
                        const LSParagraph = checkDataInStorage(report_level_key, section_level_key).isDataAvailable?.[value.heading]
                        const {paragraph,customSectionKey} = buildParagraph(storeData[mappedTitleStoreValue],state,report_level_key, value.heading)
                        value.paragraph = LSParagraph || paragraph
                        const map = {}
                        map[value.heading] = value.paragraph
                        if (!report_level_key.includes("undefined")) {
                            if (checkDataInStorage(report_level_key, customSectionKey).isDataAvailable) {
                                saveUpdatedStorageData(
                                    report_level_key,
                                    section_level_key,
                                    map
                                )
                            }
                        }
                        acc[key] = value
                        return acc
                    }, {})
                return {...e, ...dataFiltered}
            }
            return e
        })
    }, [storeData, report_level_key, section_level_key, state])

    //edit button will be displayed on 3 conditions
    // 1. if user has specialist role 2. dossier status is TE 3. sync is not in progress
    const displayEdit =
        ROLES.onlySpecialist.indexOf(userData.user.role) > -1 &&
        STATUS.TECHNICAL_EVALUATION === dossierStatus.status &&
        syncNotification.sync_info &&
        syncNotification.sync_info.status !== DERIVED_SYNC_STATUS.IN_PROGRESS;

    useEffect(() => {
        getData(sources, reportName)
            .then((response) => {
                response && setState({data: response.data});
            })
            .catch((error) => {
                setState({error});
            });
    }, [sources, reportName]);

    const {data, error} = state;

    useEffect(() => {
        const editableObject = data.length && getEditableObj(data.length);
        setIsEditable(editableObject);
        const disabledData = data.filter((item) => item.isDisabled === 'true');
        const disabledHeadingsList = disabledData.length
            ? disabledData.map((item) => item.heading)
            : [];
        disabledHeadingsList.length &&
        dispatch(
            setTEDisabledColumns({
                type: ED_DISABLE_TYPE.INIT,
                data: disabledHeadingsList,
                section: section_level_key,
            })
        );
        const getFormattedData = async () => {
            return checkDataInStorage(report_level_key, section_level_key)
                .isDataAvailable
                ? storageEssayDataHandler(data, report_level_key, section_level_key)
                : essayDataHandler(data);
        };
        data.length &&
        getFormattedData().then((formattedData) => {
            setEssayData(formattedData);
            setEditedHeadings(
                checkDataInStorage(report_level_key, section_level_key)
                    .editedDataHeadings
            );
            dispatch(setTEStorageKey(report_level_key));
            dispatch(setSaveStatus(report_level_key));
        }).catch((error) => {
            setState({error});
        });
    }, [dispatch, data, report_level_key, section_level_key]);

    useEffect(() => {
        if (isEditable !== null && isEditable !== 0) {
        const enableSave = Object.values(isEditable).includes(true);
        dispatch(setIsTEEditable(enableSave));
        }
      }, [isEditable, dispatch]);

    const editorRef = useRef("");

    const handleDataChange = (value, id) => {
        essayData[id].paragraph = value;
        const editedPara = {};
        editedPara[essayData[id].heading] = value;
        setEditedEssayData({...editedEssayData, ...editedPara});
        setEssayData({...essayData});
    };

    const handleEdit = (id) => {
        const editableObject = {
            ...getEditableObj(data.length),
            [id]: !isEditable[id],
        };
        Object.keys(editedEssayData).length &&
        saveUpdatedStorageData(
            report_level_key,
            section_level_key,
            editedEssayData
        );
        setEditedHeadings(
            checkDataInStorage(report_level_key, section_level_key).editedDataHeadings
        );
        dispatch(setSaveStatus(report_level_key));
        setIsEditable(editableObject);
    };

    const handlePreview = () => {
        saveUpdatedStorageData(
            report_level_key,
            section_level_key,
            editedEssayData
        );
        setEditedHeadings(
            checkDataInStorage(report_level_key, section_level_key).editedDataHeadings
        );
        setIsEditable(getEditableObj(data.length));
        dispatch(setSaveStatus(report_level_key));
        customEssayDataSetter()
    };

    const handleToggle = (e, checked) => {
        const currHeading = e.target.getAttribute("data-toggle-heading");
        const id = e.target.getAttribute("data-id");
        if (essayData[id].isDisabled) {
            essayData[id].isDisabled = checked ? "false" : "true";
            setEssayData({...essayData});
        }
        dispatch(
            setTEDisabledColumns({
                type: ED_DISABLE_TYPE.TOGGLE,
                data: {checked: checked, currHeading},
                section: section_level_key,
            })
        );
    };

    const handleReset = (id) => {
        const currHeading = essayData[id].heading;
        resetSavedDatafromLS(currHeading, report_level_key, section_level_key);
        setEssayData(essayDataReset(essayData, data, id));
        setIsEditable(getEditableObj(data.length));
        delete editedEssayData[currHeading];
        setEditedEssayData({...editedEssayData});
        setEditedHeadings(
            checkDataInStorage(report_level_key, section_level_key).editedDataHeadings
        );
        dispatch(setSaveStatus(report_level_key));
        customEssayDataSetter()
    };

    const renderParaEditalert = (heading) => {
        return editedHeadings.length && editedHeadings.includes(heading) ? (
            <span className="para-edit-info">&nbsp; - edited</span>
        ) : null;
    };

    useEffect(() => {
        customEssayDataSetter()
    }, [customEssayDataSetter])

    const renderTitle = (item, index) => {
        return (
            <div
                className={index === data.length - 1  && data.length !==1 ? "heading last" : "heading"}
            >
                <span style={{textDecoration: "underline"}}> {item.heading}</span>
                {enableSave && renderParaEditalert(item.heading)}
                <div className="flex-container">
                    <FeatureToggle featureName={FEATURES.EDITABLE_TE}>
                        {displayEdit && (
                            <>
                                {!isEditable[index] && item.isDisabled !== "true" && (
                                    <Tooltip title="Edit" placement="top">
                                        <IconButton
                                            variant="ghost"
                                            size="small"
                                            className="edit-btn"
                                            onClick={() => handleEdit(index)}
                                        >
                                            <Edit/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {!isEditable[index] && (
                                    <Tooltip title="Enable/Disable Section" placement="top">
                                        <div className="toggle-style">
                                            <Toggle
                                                className="toggle-btn"
                                                defaultChecked={item.isDisabled !== "true"}
                                                onChange={handleToggle}
                                                data-toggle-heading={item.heading}
                                                data-id={index}
                                                size="small"
                                            />
                                        </div>
                                    </Tooltip>
                                )}
                                {isEditable[index] && (
                                    <Tooltip title="Preview" placement="top">
                                        <IconButton
                                            variant="ghost"
                                            size="small"
                                            className="preview-btn"
                                            onClick={() => handlePreview()}
                                        >
                                            <Visibility/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isEditable[index] && (
                                    <Tooltip title="Reset" placement="top">
                                        <IconButton
                                            variant="ghost"
                                            size="small"
                                            className="restore-btn"
                                            onClick={() => handleReset(index)}
                                        >
                                            <Restore/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </FeatureToggle>
                </div>
            </div>
        );
    };
    const renderEssay = () => {
        return essayData ? (
            Object.values(essayData).map((item, index) => {
                const {isDisabled, paragraph} = item
                return (
                    <React.Fragment key={index}>
                        {/* <div className={"-------", essayData[index]}> */}
                        <div
                            className={
                                paragraph && isDisabled !== "true"
                                    ? "essEdit"
                                    : "essEdit exclude-print"
                            }
                        >
                            {/* use disable-content class when disable logic is moved from GD to ED*/}
                            {renderTitle(item, index)}
                            <div
                                className={
                                    isDisabled !== "true"
                                        ? "paragraph"
                                        : "paragraph grayedParagraph"
                                }
                            >
                                {isEditable[index] ? (
                                    <div id="quillEditor">
                                        <QuillToolbar/>
                                        <ReactQuill
                                            theme="snow"
                                            defaultValue={paragraph}
                                            onChange={(e) => handleDataChange(e, index)}
                                            modules={modules}
                                            formats={formats}
                                            ref={editorRef}
                                        />
                                    </div>
                                ) : (
                                    <div className="ql-editor">
                                        {parser(paragraph || "")}
                                    </div>
                                )}
                            </div>
                        </div>
                    </React.Fragment>
                )
            })
        ) : (
            <NoData sources={sources}/>
        );
    };
    return (
        <Container className="essay-cls">
            {!error ? renderEssay() : <ErrorHandler error={error}/>}
        </Container>
    );
};

export default Essay;
