import {cloneDeep} from 'lodash';
import {checkDataInStorage, getEditsFromLocalStorage} from '../../utils/localStorageHelper';
import {getImageURL} from '../../utils/uploadHelper';

export const TECH_EVAL_COLUMN_NAMES = {
    TA_INTRO: "Introduction", //com
    TA_REASON: "Reason for the Task", // znl
    TA_COMPLIANCE: "IP44 Compliance", //znl
    TA_SM_DA: "Scheduled Maintenance Data Analysis", //com
    TA_OI_DA: "Operational Interruption Data Analysis", //sys
    TA_CR_DA:
        "Component Reliability and Other Unscheduled Maintenance Data Analysis", //sys
    TA_EA: "Economic Assessment", //sys
    TA_RISK_ASSESSMENT: "Risk Assessment", //str
    TA_CC: "Consecutive Checks", //znl
    TA_USM_DA: "Unscheduled Maintenance Data Analysis", //znl
    TA_ECO_IMPACT_ASS: "Economic Impact Assessment (including EWIS)", //znl
    TA_OPE_IMPACT_ASS: "Operational Impact Assessment (including EWIS)", //znl
    TA_EWIS_FINDING: "EWIS related Findings", //znl
    TA_ODC: "Other Data for Consideration", //com
    TA_CONCLU: "Final Conclusion", //com
};

export const MAP_TITLE_STORE_DATASET = {
    "Component Reliability and Other Unscheduled Maintenance Data Analysis": "component_reliability"
}

//regex to get img tag
const rex = /<img[^>]+src="([^">]+)/g;

//code to formulate essay data with indexing
export const essayDataHandler = async (teData) => {
    return await cloneDeep(teData).reduce(async (prevPromise, teItem, index) => {
        const result = await prevPromise;
        //checking with regex that para contains image url then refreshing the url
        if (rex.test(teItem.paragraph)) {
            teItem.paragraph = await refreshParaUrl(teItem.paragraph);
        }
        result[index] = teItem;
        return result;
    }, Promise.resolve({}));
};

//code to reset essay data
export const essayDataReset = (essayData, data, id) => {
    const resData = cloneDeep(essayData);
    resData[id] = cloneDeep(data)[id];
    return resData;
};

export const getEditableObj = (len) => {
    return [...Array(len).keys()].reduce((acc, item) => {
        acc[item] = false;
        return acc;
    }, {});
};

//code to overwrite the essay data with edited text data from local storage
export const storageEssayDataHandler = async (
    teData,
    report_key,
    section_key
) => {
    const localTextEditSectionData =
        getEditsFromLocalStorage(report_key)[section_key];
    const editedParas = Object.keys(localTextEditSectionData);
    return await cloneDeep(teData).reduce(async (prevPromise, teItem, index) => {
        const result = await prevPromise;
        if (editedParas.includes(teItem.heading)) {
            //checking with regex that para contains image url then refreshing the url
            const lsPara = localTextEditSectionData[teItem.heading];
            if (rex.test(lsPara)) {
                teItem.paragraph = await refreshParaUrl(cloneDeep(lsPara));
            } else {
                teItem.paragraph = lsPara;
            }
        }
        result[index] = teItem;
        return result;
    }, Promise.resolve({}));
};

/**
 * execute regex to identify expired image url and provide refreshed one
 * @param {String} teParagraph
 * @returns paragraph with updated image urls
 */
export const refreshParaUrl = async (teParagraph) => {
    /* regex object is stateful.
    as regex is global here, if we call a method on the same regex object,
    it will start from the index past the end of the last match.
    So need to set lastindex to 0
    */
    rex.lastIndex = 0;
    const urls = [];
    let match = [];
    while ((match = rex.exec(teParagraph))) {
        urls.push(match[1]);
    }
    for (const url of urls) {
        const currImgUrl = await getCurrentUrl(url);
        teParagraph = teParagraph.replace(url, currImgUrl);
    }
    return teParagraph;
};

/**
 * use current url keys to get new url
 * @param {String} url
 * @returns refreshed image presigned url
 */
const getCurrentUrl = async (url) => {
    const imgsrc = url.substring(
        url.indexOf(`${sessionStorage.getItem('dossierKey')}`),
        url.lastIndexOf('?')
    );
    const currImgKey = imgsrc ? imgsrc : '';
    return await getImageURL(currImgKey);
};

/**
 * Build sentence for CR Technical Evaluation section based on CR Table
 * @param PN_MTBUR
 * @param PN_MTBF
 * @param PN
 * @param Component
 * @returns {string|string[]}
 */

const buildSentence = ({PN_MTBUR, PN_MTBF, PN, Component}) => {
    const MTBURSentence = `The MTBUR for the ${Component} (P/N ${PN}) is ${PN_MTBUR !== "N/A" ? PN_MTBUR + " FH." : "not available."}`
    const MTBFSentence = PN_MTBF !== "N/A" ? `The MTBF for the ${Component} (P/N ${PN}) is ${PN_MTBF} FH.` : undefined
    return MTBFSentence ? [MTBURSentence, MTBFSentence].join("\n\n") : [MTBURSentence]
}

/**
 * get section key from given title
 * @param report_level_key
 * @param title
 * @returns {`${string}@${*}`}
 */

const getCustomSectionKey = ({report_level_key, title}) => {
    const existingLSItem = JSON.parse(localStorage.getItem(report_level_key))
    const existingLSItemKeys = existingLSItem ? Object.keys(existingLSItem) : []
    return `${title}@${existingLSItemKeys.find(key => key.startsWith(title))?.split("@")[1]}`
}
/**
 * Build Paragraph for standard sentence Component Reliability
 * @param data : {Array} data from CR table
 * @param state initial state of standard sentences : response from backend
 * @param report_level_key {string}
 * @param sectionKeyMappedTitleStoreValue {string} custom section level key
 * @param title :{string} section title
 * @returns {{paragraph: string, customSectionKey: string}}
 */

export const buildParagraph = (data, state, report_level_key, title) => {
    let paragraph = "";
    let LSTable = []
    let customSectionKey = "dossier"

    if (data) {
        switch (title) {
            case TECH_EVAL_COLUMN_NAMES["TA_CR_DA"]:
                customSectionKey = getCustomSectionKey({report_level_key, title: "Component_Reliability"})
                LSTable = checkDataInStorage(report_level_key, customSectionKey)?.isDataAvailable?.table
                if (LSTable) {
                    paragraph = LSTable.map(elt => buildSentence(elt)).join('\n\n')
                } else {
                    paragraph = state.data.find(obj => obj.heading === title)?.paragraph
                }
                break;
        }
    }
    return {paragraph, customSectionKey}
}

/**
 * Set first letter of a word into uppercase
 * @param string : {string}
 * @returns {string}
 */

export const firstLetterUpperCase = (string) => {
    const firstLetterOnlyUppercase = string[0].toUpperCase()
    const otherLetters = string.slice(1)
    return firstLetterOnlyUppercase + otherLetters
}

/**
 * Set first letter of several words separated by a separator into uppercase: e.g. component_reliability => Component_Reliability
 * @param string
 * @param separator
 * @returns {*}
 */

export const firstLetterUppercaseSeparatedWords = (string, separator) => {
    const split = string.split(separator)
    const mappedFirstUppercase = split.map(elt => firstLetterUpperCase(elt))
    return mappedFirstUppercase.join(separator)
}
