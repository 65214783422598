export const globalRow = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "10px",
  fontSize: "large",
  fontWeight: "600",
  filter: "opacity(.85)",
  columnGap: "20px",
  whiteSpace: "pre-line",
};

export const hiddenDiv = {
  display: "flex",
  alignItems: "center",
  fontWeight: "400",
  fontSize: "small",
  columnGap: "5px",
  opacity: "0",
};

export const hiddenValidateIcon = {
  color: "#08875B",
  minHeight: "17px",
  minWidth: "17px",
};

export const editIcon = {
  color: "#da1884",
  cursor: "pointer",
};

export const editIconContainer = {
  display: "flex",
};

export const editableRow = {};

export const editableInput = {
  borderWidth: "1px",
  borderColor: "rgb(160,160,255, 0.25)",
  fontSize: "large",
  fontWeight: "600",
  width: '100%'
};

export const textContainer = {
  display: "flex",
  alignItems: "center",
  columnGap: "20px",
};

export const text = {
  fontSize: "large",
  fontWeight: "600",
};

export const editableContainer = {
  display: "flex",
  width: '80vw'
};

export const iconBtn = {};