export const CONFIRM_LABEL = "Confirm"
export const CANCEL_LABEL = "Cancel"
export const RESET_LABEL = "Reset"
export const DELETE_LABEL = "Delete"

export const DIALOGPOPUP_CR_DELETE = {
    title: DELETE_LABEL,
    text: 'Do you confirm that you want to delete this PN / PN Family?',
};
export const DIALOGPOPUP_CR_RESET = {
    title: RESET_LABEL,
    text: `Are you sure you want to reset the Component reliability section ?
    Any changes or additions made will be lost as resetting the full table content.
    Please confirm.`,
};
