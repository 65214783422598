import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Banner, Stack } from "@airbus/components-react";
import "./Notification.scss";
import { removeMessage } from "../../models/notificationModel/notificationActions";
import SyncStatus from "./SyncStatus";

const Notification = () => {
  const messages = useSelector((store) => store.notification);
  const dispatch = useDispatch();

  const onClose = (id) => {
    dispatch(removeMessage(parseInt(id)));
  };
  return (
    <div className="notification-cls exclude-print">
      <Stack>
        {Object.keys(messages).map((key) => {
          const { closable, status, description, message, autoHideDuration } = messages[key];
          const closeEnabled = closable ? () => onClose(key) : undefined;
          return (
            <Banner
              key={key}
              variant={status}
              onClose={closeEnabled}
              autoHideDuration={autoHideDuration}
              className={closeEnabled ? "notification-position-cls" : ""}
            >
              <div>
                <div>{message}</div>
                {!closable && <SyncStatus desc={description} />}
              </div>
            </Banner>
          );
        })}
      </Stack>
    </div>
  );
};

export default Notification;
