export const EXCERCISE_TYPES = {
  MOEE: "moee",
  FWEE: "fwee",
  SOEE: "soee",
};

/**
 * supported dossier types
 */
export const DOSSIER_TYPES = {
  SYSTEM: "system",
  ZONAL: "zonal",
  STRUCTURE: "structure",
};

export const TASK_REVIEW_NON_EDITABLE_FIELDS = ['mpdTaskReference','maintenanceProgram', 'ompTaskReference'];
export const TASK_REVIEW_DESCRIPTION_FIELDS = ['ompTaskDescription','taskDescription'];