import React from "react";
import { Link } from 'react-router-dom';
import { Error } from "@airbus/icons/react";

import "./NotFound.scss";

const NotFound = () =>  (
    <div className="not-found-cls">
      <Error htmlColor="#ff0000" style={{ fontSize: "30rem" }}/>
      <span> Page Not Found </span>
      <span> Take Me <Link to="/"> Home </Link> </span>
    </div>
  );

export default NotFound;
