import { API, Auth } from 'aws-amplify';

const requestApi = async (options) => {

  const { apiName, path, params = {}, method } = options;
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const headers = params.headers || {};
  const request = {
    ...params,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  };
  return await API[method](apiName, path, request);
}

export default requestApi;