export const GET_ALL_IMAGES = "GET_ALL_IMAGES";
export const GET_IMAGE_BY_NAME = "GET_IMAGE_BY_NAME";

export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const GET_IMAGE_FAILURE = 'GET_IMAGE_FAILURE';

export const GET_ALL_IMAGES_SUCCESS = 'GET_ALL_IMAGES_SUCCESS';
export const GET_ALL_IMAGES_FAILURE = 'GET_ALL_IMAGES_FAILURE';

export const DEL_IMAGE_SUCCESS = 'DEL_IMAGE_SUCCESS';
export const DEL_IMAGE_FAILURE = 'DEL_IMAGE_FAILURE';

export const CLEAR_REPORT = 'CLEAR_REPORT';
