import React, {useMemo} from 'react'
import "./DialogComponentReliabilityAction.scss"
import {Button} from "@airbus/components-react";
import {useSelector} from "react-redux";
import {getCustomFieldsCR, isFieldValid} from "../../utils";
import PropTypes from "prop-types";

/**
 * Button for Dialog popup for Add P/N details CR
 * @param cancelHandler
 * @param confirmHandler
 * @param edited
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const DialogComponentReliabilityAction = ({cancelHandler, confirmHandler, edited, data}) => {
    const store = useSelector(state => state)
    const titleInfo = store.dossierStats?.dossier?.data?.titleInfo?.[0];
    const mpdTaskMaintenanceProgram = titleInfo?.mpdTaskMaintenanceProgram;
    const requiredCustomFields = getCustomFieldsCR(mpdTaskMaintenanceProgram)
        .filter(field => field.required) // [Component, PN, family_id]

    /**
     * useMemo to disable validation button if fields are not valid
     * @type {boolean}
     */
    const disabled = useMemo(() => {
        if(edited){
            return requiredCustomFields.every(key => isFieldValid(key, edited, data))
        } else {
            return true
        }
    }, [edited, requiredCustomFields, data])
    return (<div className="action-main">
        <Button className="button" onClick={cancelHandler} variant="secondary">Cancel</Button>
        <Button className="button" onClick={confirmHandler} disabled={!disabled} variant="primary" aria-label="Confirm CR Dialog">Confirm</Button>
    </div>);
}

export default DialogComponentReliabilityAction

DialogComponentReliabilityAction.propTypes = {
    cancelHandler: PropTypes.func, confirmHandler: PropTypes.func, edited: PropTypes.object, data: PropTypes.array
}
