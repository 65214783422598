import {
  GET_SYNC_STATUS_SUCCESS,
  GET_SYNC_STATUS_FAILURE,
} from "./syncStatusActionType";
import {
  DOSSIER_SYNC_STATUS,
  DERIVED_SYNC_STATUS,
} from "../../config/dossier.status";

const syncNotification = (state = {}, action) => {
  switch (action.type) {
    case GET_SYNC_STATUS_SUCCESS: {
      return reduceSyncStatus(state, action);
    }
    case GET_SYNC_STATUS_FAILURE: {
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.UNKNOWN,
          msg: "unable to get latest sync status",
        },
      };
    }
    default:
      return state;
  }
};

/**
 * reduces sync status data based on incoming status
 * @param {Object} state  current state of the store
 * @param {Object} action action to reduce
 */
const reduceSyncStatus = (state, action) => {
  const error = action.payload.error
    ? `[Last Sync Failed] : ${action.payload.error}`
    : "[Last Sync Failed]";
  switch (action.payload.status) {
    case DOSSIER_SYNC_STATUS.INIT:
    case DOSSIER_SYNC_STATUS.SYNC:
    case DOSSIER_SYNC_STATUS.READY: {
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.IN_PROGRESS,
          progress : action.payload.status,
          msg: "Sync in progress...",
        },
      };
    }
    case DOSSIER_SYNC_STATUS.FAIL: {
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.FAILED,
          msg: error,
        },
      };
    }
    default:
      return {
        ...state,
        sync_info: {
          status: DERIVED_SYNC_STATUS.SUCCESS,
          progress : DERIVED_SYNC_STATUS.SUCCESS,
          msg: "",
        },
      };
  }
};

export default syncNotification;
