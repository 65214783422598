import { getLocalStorageKeys } from '../../utils/localStorageHelper';
import {
  TOGGLE_TE_STATUS,
  SET_TE_STORAGE_KEY,
  SAVE_EDITS_FAILURE,
  SAVE_EDITS_SUCCESS,
  SET_ED_DISABLED_COLUMNS,
  SET_IS_TE_EDITABLE,
} from './teEditableActionType';
import { clearEditsFromLocalStorage } from '../../utils/localStorageHelper';
import { ED_DISABLE_TYPE } from '../../config/dossier.status';

const initState = {
  enableSave: false,
  te_storage_key: '',
  disabledColList: {},
  toggleEnabled: false,
  isTEEditing : false
};
const teEditableReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_TE_STATUS: {
      return {
        ...state,
        enableSave: getLocalStorageKeys().includes(action.payload),
      };
    }
    case SET_TE_STORAGE_KEY: {
      return { ...state, te_storage_key: action.payload };
    }
    case SAVE_EDITS_SUCCESS: {
      clearEditsFromLocalStorage(state.te_storage_key);
      return { ...state, enableSave: false };
    }
    case SAVE_EDITS_FAILURE: {
      return { ...state, enableSave: true };
    }
    case SET_ED_DISABLED_COLUMNS: {
      const disabledColList = handleDisbaledCols(action.payload, state);
      return {
        ...state,
        disabledColList,
        enableSave: state.toggleEnabled,
      };
    }
    case SET_IS_TE_EDITABLE : {
      return {
        ...state,
        isTEEditing: action.payload,
      };
    }
    default:
      return state;
  }
};

const handleDisbaledCols = (disabledCols, state) => {
  const currDisabledCols = state.disabledColList;
  const { type, data, section } = disabledCols;
  const result = {};
  if (type === ED_DISABLE_TYPE.INIT) {
    const currSecCols = currDisabledCols[section]
      ? currDisabledCols[section]['ED_DISABLED_COLUMNS']
      : [];
    result[section] = {
      ED_DISABLED_COLUMNS: [...new Set([...data, ...currSecCols])],
    };
  } else if (type === ED_DISABLE_TYPE.TOGGLE) {
    state.toggleEnabled = true;
    if (!data.checked) {
      if (Object.keys(currDisabledCols).includes(section)) {
        const cols = currDisabledCols[section]['ED_DISABLED_COLUMNS'];
        cols.push(data.currHeading);
        result[section] = { ED_DISABLED_COLUMNS: [...cols] };
      } else {
        result[section] = { ED_DISABLED_COLUMNS: [data.currHeading] };
      }
    } else {
      const filteredCols = currDisabledCols[section].ED_DISABLED_COLUMNS.filter(
        (col) => col !== data.currHeading
      );
      if (filteredCols.length) {
        result[section] = {
          ED_DISABLED_COLUMNS: filteredCols,
        };
      } else {
        currDisabledCols[section].ED_DISABLED_COLUMNS = [];
      }
    }
  }
  return { ...currDisabledCols, ...result };
};
export default teEditableReducer;
