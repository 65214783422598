import React, { useState, useEffect } from "react";
import { useParams  } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@airbus/components-react";
import reportTexts from "../../locales/reportTexts.json";
import { apiDownloadAttachment } from "../../models/attachmentModel/attachmentApi";


const AttachmentsHandler = () => {
  const { reportName, fileName } = useParams()
  const [loading, setLoading] = useState(true);
  const attachmentDownloaded = useSelector(store => store.attachments.attachmentDownloaded)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(apiDownloadAttachment(reportName, fileName))
  }, [dispatch, reportName, fileName]);
  useEffect(()=> {
    setLoading(!attachmentDownloaded)
  },[attachmentDownloaded])

  return (
    <div  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      {loading && <Spinner>{reportTexts.LoadingTemplate}</Spinner>}
    </div>
  )
}

export default AttachmentsHandler;