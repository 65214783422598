import React from 'react';
import Proptypes from  'prop-types';
import { Spinner } from "@airbus/components-react";

// when use blocking loading, make sure to keep its parent as
// position:relative

const Loader = ({ isBlocking }) => isBlocking
  ? (<div style={{ position: 'absolute', backgroundColor: '#ffffff73', height: '99%', width: '100%', display: 'flex', zIndex: 1 }} >
      <div style={{ margin: 'auto' }}>
        <Spinner size="medium" label="Loading..." />
      </div>
    </div>)
  : (<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Spinner size="medium" label="Loading..." />
    </div>);

Loader.defaultProps = {
  isBlocking: false
}

Loader.propTypes = {
  isBlocking: Proptypes.bool
}

export default Loader
