import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {IconButton, Textarea, Tooltip} from "@airbus/components-react";
import {Check, CheckCircle, Close, Create} from "@airbus/icons/react";
import "./EditableField.scss";
import {useDispatch, useSelector} from "react-redux";
import * as defaultCss from "./cssConstants";
import {
    checkDataInStorage,
    clearEditsFromLocalStorage,
    getEditsFromLocalStorage,
    getSectionKey,
    resetSignature,
    saveEditsToLocalStorage
} from "../../../../utils/localStorageHelper";
import {setSaveStatus, setTEStorageKey} from "../../../../models/teEditableModel/teEditableAction";
import {DERIVED_SYNC_STATUS, ROLES, STATUS} from "../../../../config/dossier.status";


/**
 * Component to render children with tooltip (input or textarea e.g.)
 * @param children
 * @param editableContainerStyle
 * @param btnStyle
 * @param handleClickEdit
 * @param signature
 * @param onReset
 * @returns {JSX.Element}
 * @constructor
 */
const EditableMode = ({children, editableContainerStyle, btnStyle, handleClickEdit, signature, onReset, isDisabled}) => {
    return (
        <div style={editableContainerStyle} key="editable-key">
            {children}
            <Tooltip title="Validate" placement="top">
                <IconButton id={signature ? "hoverChangeTick" : ""} className="hoverChange"
                    variant="ghost"
                    size="small"
                    style={btnStyle}
                    onClick={() => handleClickEdit()}
                    disabled={isDisabled}
                >
                    {signature ? <CheckCircle/> : <Check/>}
                </IconButton>
            </Tooltip>
            <Tooltip title="Reset" placement="top">
                <IconButton  id={signature ? "hoverChangeCross" : ""} className="hoverChange"
                    variant="ghost"
                    size="small"
                    onClick={onReset}
                    style={btnStyle}
                >
                    <Close/>
                </IconButton>
            </Tooltip>
        </div>
    );
};
/**
 * A reusable EditableField component
 * List of props :
 * - valueToDisplay: self explanatory, the value to display.
 * - keyName: The key of the field to modify on the userData part in Redux store.
 * - reportName: The ID of the report (usually given by urlParams)
 * - userData: the userData part from the Redux Store
 * - idPopText: a UNIQUE ID to give to the hidden div node
 * - customCss: Custom CSS to modify every part of this component. See cssConstants.js file for reference
 * - onValidate:
 * @param props
 * @return {JSX.Element}
 * @constructor
 */

const EditableField = (props) => {
    const {
        valueToDisplay,
        keyName,
        idPopText,
        customCss,
        onValidate,
        signature,
    } = props;
    const [editMode, setEditMode] = useState(false);
    const dispatch = useDispatch();
    const {dossierStatus, userData, syncNotification} = useSelector((store) => store);
    const {dossierKey, dossierType, exerciseType, filters} = dossierStatus;
    const section_level_key = getSectionKey([], "Report Cover", filters, keyName)
    const report_level_key = `TextEdit_${dossierType}_${exerciseType}_${dossierKey}`;
    const [inputValue, setInputValue] = useState(checkDataInStorage(report_level_key, section_level_key)
        .isDataAvailable
        ? getEditsFromLocalStorage(report_level_key)[`Report_Cover@${keyName}`]
        : valueToDisplay || "");

    const displayEdit =
        ROLES.onlySpecialist.indexOf(userData.user.role) > -1 &&
        STATUS.TECHNICAL_EVALUATION === dossierStatus.status &&
        syncNotification.sync_info &&
        syncNotification.sync_info.status !== DERIVED_SYNC_STATUS.IN_PROGRESS;

    const textareaStyleCondition = customCss?.editableInput || defaultCss.editableInput
    const textareaStyle = {...textareaStyleCondition, minHeight: "fit-content"}
    const editableContainerStyleCondition = customCss?.editableContainer || defaultCss.editableContainer
    const editableContainerStyle = {...editableContainerStyleCondition, gap: "0.4rem"}
    const btnStyleCondition = customCss?.iconBtn || defaultCss.iconBtn
    const btnStyleNoColor = {...btnStyleCondition, position: "relative", zIndex: 5}
    const btnStyle = ["preparedBy", "checkedBy"].includes(keyName) ? {
        ...btnStyleNoColor,
        color: "rgb(255,255,255)"
    } : btnStyleNoColor

    const textAreaRef = useRef(null)


    const onValidateDefault = () => {
        const mapValue = getEditsFromLocalStorage(report_level_key);
        mapValue[section_level_key] = inputValue
        saveEditsToLocalStorage(report_level_key, mapValue)
        dispatch(setTEStorageKey(report_level_key));
        dispatch(setSaveStatus(report_level_key));
        /* istanbul ignore else */
        if (signature) {
            saveEditsToLocalStorage(report_level_key, mapValue)
        }
    };

    const onReset = () => {
        const mapValue = getEditsFromLocalStorage(report_level_key);
        delete mapValue[section_level_key]
        if (Object.keys(mapValue).length) {
            saveEditsToLocalStorage(report_level_key, mapValue)
            signature && resetSignature(report_level_key)
        } else {
            clearEditsFromLocalStorage(report_level_key)
        }

        setEditMode(!editMode);
        setInputValue(valueToDisplay);
    };

    const onSetVal = (value) => {
        if (signature && value === "N/A") {
            return null;
        } else {
            setInputValue(value);
        }
    };

    const renderReadOnlyMode = () => {
        const textStyleCondition = defaultCss?.text || {}
        const textStyle = {...textStyleCondition, wordBreak: "break-word"}
        return (
            <div style={defaultCss?.textContainer}>
                <div style={textStyle}>{`${inputValue}`}</div>
                {displayEdit ? <div
                    style={customCss?.editIconContainer || defaultCss.editIconContainer}
                >
                    <Tooltip title="Edit" placement="top">
                        <Create
                            onClick={() => {
                                setEditMode(!editMode);
                            }}
                            style={customCss?.editIcon || defaultCss.editIcon}
                        />
                    </Tooltip>
                </div> : null}
            </div>
        );
    };

    const handleClickEdit = () => {
        if (!signature) {
            setInputValue(inputValue.toUpperCase());
        }
        onValidate ? onValidate() : onValidateDefault();
        setEditMode(!editMode);
        const el = document.getElementById(idPopText);
        if (el.style.animation) {
            el.style.animation = "none";
            el.getBoundingClientRect();
            el.style.animation = null;
        }
        el.style.animation = "disappear 3s linear 1";
    }

    const renderTextArea = () => {
        return (<Textarea
            key="textarea-key"
            value={inputValue}
            style={textareaStyle}
            onChange={(e) => {
                onSetVal(e.target.value)
            }}
            ref={textAreaRef}
        />);
    };
    const renderInput = () => {
        return (
            <input
                key="input-key"
                maxLength={100}  // To Restrict the length if Preparedby & CheckedBy to 100 (longest name)
                value={inputValue}
                style={customCss?.editableInput || defaultCss.editableInput}
                onChange={(e) => {
                    setInputValue(signature ? e.target.value : e.target.value.toUpperCase());
                }}
            />
        )
    }

    useEffect(() => {
        const current = textAreaRef?.current
        if (current && editMode) {
            // We need to reset the height momentarily to get the correct scrollHeight for the textarea
            current.style.height = "0px";
            const scrollHeight = current.scrollHeight;

            // We then set the height directly, outside of the render loop
            // Trying to set this with state or a ref will product an incorrect value.
            current.style.height = scrollHeight + "px";
        }
    }, [textAreaRef, inputValue, editMode]);


    return (
        <div style={customCss?.globalRow || defaultCss.globalRow}>
            {!editMode ? renderReadOnlyMode() : <EditableMode
                btnStyle={btnStyle}
                editableContainerStyle={editableContainerStyle}
                handleClickEdit={handleClickEdit}
                signature={signature}
                onReset={onReset}
                isDisabled= {inputValue === 'N/A' || inputValue === '' ? true : false}>
                {["preparedBy", "checkedBy"].includes(keyName) ? renderInput() : renderTextArea()}
            </EditableMode>}
            <div id={idPopText} style={customCss?.hiddenDiv || defaultCss.hiddenDiv}>
                <CheckCircle
                    style={customCss?.hiddenValidateIcon || defaultCss.hiddenValidateIcon}
                />
                Edited
            </div>
        </div>
    );
};

EditableField.propTypes = {
    valueToDisplay: PropTypes.string.isRequired,
    keyName: PropTypes.string.isRequired,
    reportName: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
    idPopText: PropTypes.string.isRequired,
    customCss: PropTypes.shape({
        hiddenValidateIcon: PropTypes.object,
        editIconContainer: PropTypes.object,
        editIcon: PropTypes.object,
        globalRow: PropTypes.object,
        hiddenDiv: PropTypes.object,
        editableInput: PropTypes.object,
        editableContainer: PropTypes.object,
        iconBtn: PropTypes.object
    }),
    onValidate: PropTypes.func,
    signature: PropTypes.bool,
};

EditableMode.propTypes = {
    children: PropTypes.node.isRequired,
    signature: PropTypes.bool.isRequired,
    editableContainerStyle: PropTypes.object.isRequired,
    btnStyle: PropTypes.object.isRequired,
    handleClickEdit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool

};

export default EditableField;
