import React from 'react';
import {
    Dialog,
    DialogHeader,
    DialogContent,
    DialogActions,
    Button
} from "@airbus/components-react";
import idx from "idx";
import './DialogPopup.scss'

/**
 * 
 * @param {*} props
 *            displayDialogPopup (hook): the state hook of the parent component. Allow to control the display of the DialogPopup component
 *            setDisplayDialogPopup (hook's setter): the setter of the state hook
 *            title (string): the title of the popup
 *            mainText (array/string): the text displayed in the popup (might be a array or a string)
 *            buttons (object): array of elements representing the buttons a the footer of the popup. Will have the following properties
 *              - label (string): text displayed into the button
 *              - variant (string): refert to "react.design-system.airbus.corp"
 *              - className (string): useful for tests
 *              - callback (callbak function): a function to invoke, defined into the parent component 
 * @returns {JSX.Element} 
 */
const DialogPopup = (props) => {

    const {
        displayDialogPopup,
        setDisplayDialogPopup,
        title,
        mainText,
        buttons
    } = idx(props, (_) => _);

    const componentTitle = () => {
        return title || 'Title'; 
    };

    const componentText = () => {
        if (Array.isArray(mainText)) { 
            return mainText.map((e, i) => (
                <p key={i}>{e}</p>
            ))
        } else if (mainText) {
            return <p>{mainText}</p>
        } else {
            return <p>Text</p>
        }
    };

    const componentButtons = () => {
        return buttons.map((e, i) => (
            <Button
                key = {i}
                className = {e.className}
                variant = {e.variant}
                onClick = {e.callback}
            >{e.label}</Button>
        ))
    };

    return (
        <Dialog
            open = {displayDialogPopup}
            onClose = {() => setDisplayDialogPopup(false)}
            className = "popup-dialog">
            <DialogHeader>
                {componentTitle()}
                </DialogHeader>
            <DialogContent>
                {componentText()}
            </DialogContent>
            <DialogActions>
                {componentButtons()}
            </DialogActions>
        </Dialog>
    );
}

export default DialogPopup;