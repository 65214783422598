import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {FormControl, Input, Typography} from "@airbus/components-react";
import "./DialogComponentReliabilityContent.scss"
import {getCustomFieldsCR, isFieldValid} from "../../utils";
import {useSelector} from "react-redux";

/**
 * Content of Component Reliability Dialog of "Add P/N Details"
 * @param fields
 * @param currentRow : undefined if it's a new line
 * @param defaultData: map of default data
 * @param disabledEdits: custom editing disable
 * @param edited: edited line
 * @param setEditedData: edited data setter
 * @param setDefaultDataCustom custom default data setter
 * @param data: All data from CR table
 * @param rowIndex : index of the row (-1 if it's a new line)
 * @returns {*}
 * @constructor
 */

const DialogComponentReliabilityContent = ({
                                               fields,
                                               currentRow,
                                               defaultData,
                                               disabledEdits,
                                               edited,
                                               setEditedData,
                                               setDefaultDataCustom,
                                               data,
                                               rowIndex
                                           }) => {
    const [placeholder, setPlaceholder] = useState({})
    const [primaryKey, setPrimaryKey] = useState()
    const store = useSelector(state => state)
    const titleInfo = store.dossierStats?.dossier?.data?.titleInfo?.[0];
    const mpdTaskMaintenanceProgram = titleInfo?.mpdTaskMaintenanceProgram;

    /**
     * memoized function for custom default data setter to prevent infinite re-render
     * @type {(function(*): void)|*}
     */
    const memoizedSetDefaultDataCustom = useCallback((data) => {
        Object.entries(data).forEach(([k, v]) => {
            data[k] = v || ''
        })
        setDefaultDataCustom(data);
    }, [setDefaultDataCustom]);

    /**
     * memoized fields to prevent infinite re-render
     * @type {*}
     */
    const memoizedFields = useMemo(() => {
        return fields
    }, [fields])

    /**
     * memoized custom fields to prevent infinite re-render
     * @type {*}
     */
    const memoizedCustomFields = useMemo(() => {
        return getCustomFieldsCR(mpdTaskMaintenanceProgram)
    }, [mpdTaskMaintenanceProgram])

    /**
     * useEffect to apply default value + placeholders
     */
    useEffect(() => {
        const {defaultDataCustom, placeholderMap} = memoizedFields.reduce((acc, initField) => {
            const field = memoizedCustomFields.filter(elt => elt.key === initField.key)[0]
            if (field) {
                let defaultValue = null
                if (field.defaultData) {
                    defaultValue = field.defaultData
                }
                let placeholderDefault = null
                if (field.placeholder) {
                    placeholderDefault = field.placeholder
                }
                if (!acc["defaultDataCustom"]) {
                    acc["defaultDataCustom"] = {}
                }
                if (!acc["placeholderMap"]) {
                    acc["placeholderMap"] = {}
                }
                acc["defaultDataCustom"][field.key] = defaultValue
                acc["placeholderMap"][field.key] = placeholderDefault
            }
            return acc
        }, {})
        memoizedSetDefaultDataCustom(defaultDataCustom)
        setPlaceholder(placeholderMap)

    }, [memoizedCustomFields, memoizedFields, memoizedSetDefaultDataCustom])

    /**
     * useEffect to build primary key for backend
     */
    useEffect(() => {
        if (Object.keys(edited).length && primaryKey) {
            const filteredPrimaryKey = memoizedCustomFields.filter(element => element.primaryKey).map(elt => elt.key)
            const editedPrimaryKeys = Object.keys(edited).filter(key => filteredPrimaryKey.includes(key))
            const values = editedPrimaryKeys.map((key) => {
                return edited[key]
            })
            setEditedData(primaryKey, rowIndex === -1 ? `${values.join('_')}_${Date.now()}` : currentRow[primaryKey])
            setPrimaryKey("")
        }
    }, [edited, primaryKey, memoizedCustomFields, setEditedData, rowIndex, currentRow])

    return (
        fields.map(initField => {
            /**
             * logic to set default value and render of field with field control
             */
            const field = memoizedCustomFields.filter(elt => elt.key === initField.key)[0]
            let defaultValue = null

            if (field) {
                if (currentRow?.[field.key] || currentRow?.[field.key] === "") {
                    defaultValue = currentRow?.[field.key]
                } else if (!placeholder[field.key]) {
                    defaultValue = defaultData?.[field.label] || field.defaultData || ""
                }
                return (
                    <div key={`dialog-editrow-${field.key}`} className="main-container">
                        <div className="label">
                            <Typography variant="medium">
                                {field.label}
                            </Typography>
                        </div>
                        <FormControl className="input" placeholder={placeholder[field.key]}
                                     error={!isFieldValid(field, edited, data)}
                                     errorText={field.errorText || field.errorText === "" ? field.errorText : 'Required'}
                                     required={field.required}>
                            <Input
                                disabled={disabledEdits?.includes(field.label)}
                                name="dialog"
                                onChange={(e) => {
                                    setPrimaryKey(memoizedCustomFields.find(element => element.primaryKey)?.primaryKey)
                                    setEditedData(field.key, e.target.value)
                                }}
                                defaultValue={defaultValue}
                            />
                        </FormControl>
                    </div>
                )
            }
        })
    );
}

export default DialogComponentReliabilityContent
