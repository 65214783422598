
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const GET_STATUS_FAILURE = 'GET_STATUS_FAILURE';
export const LIST_STATUS_SUCCESS = 'LIST_STATUS_SUCCESS';
export const LIST_STATUS_FAILURE = 'LIST_STATUS_FAILURE';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const CLEAR_DOSSIER_STATUS    = 'CLEAR_DOSSIER_STATUS';
export const SAVE_COMMENTS_TO_LOCAL = 'SAVE_COMMENTS_TO_LOCAL';
export const UPDATE_DOSSIER_STATS = 'UPDATE_DOSSIER_STATS';
export const SET_DOSSIER_FILTERS = 'SET_DOSSIER_FILTERS';
