import {
  UPDATE_DOSSIER_STATS, CLEAR_DOSSIER_STATUS
} from "./dossierStatusActionType";

const getKeyFromFilters = (filters = []) => {
  return filters.length ? filters[0].split(' ')[2] : 'dossier';
}

const handleFFRME = (state, data, filters) => {
  const key = getKeyFromFilters(filters);
  state[key] = state[key] || { data: {} };
  const newData = data.reduce((acc, item) => {
    acc[item.name] = item.percentage;
    return acc;
  }, {});
  state[key].data = {
    ...state[key].data,
    ...newData
  }
  return state;
};

const computeIntervalIndicator =({ current, proposed }) => {
  const [ currentValue, currentUnit ] = current.split(' ').map((str) => str.trim());
  const [ proposedValue, proposedUnit ] = proposed.split(' ').map((str) => str.trim());
  if (currentUnit === proposedUnit && proposedValue !== currentValue) {
    return Number(proposedValue) - Number(currentValue) > 0 ? 'up' : 'down';
  } else {
    return '';
  }
}

const handleIntervalProposal = (state, data, filters = []) => {
  const key = getKeyFromFilters(filters);
  state[key] = state[key] || { data: {} };
  const newData = data.reduce((acc, item) => {
    if (item.remarks && item.remarks.length) {
      acc["Current Interval"] = item.current;
      acc["Proposed Interval"] = item.proposed;
      state[key].remarks = item.remarks;
      state[key].intervalIndicator = computeIntervalIndicator(item);
    }
    return acc;
  }, {});
  state[key].data = {
    ...state[key].data,
    ...newData
  }
  return state;
};

// reduce stats updates
const reduceDossierStatsUpdates = (state, action) => { // eslint-disable-line no-unused-vars
  const { dataset, filters, data } = action.payload;
  if (ALLOWED_STATS[dataset]) {
    return ALLOWED_STATS[dataset](state, data, filters);
  }
  return state;
};

//to clear dossier stats
const clearDossierStats = () =>{
 return {};
};
// main reducer
const dossierStats = (state = {}, action) => {
  if (REDUCER_MAP[action.type]) {
    return REDUCER_MAP[action.type](state, action);
  } else {
    return state;
  }
};

const handleReportDetails = (state, data, filters = []) => {
  const key = getKeyFromFilters(filters);
  state[key] = state[key] || { data: {} };
  const newData = {"titleInfo": data};
  state[key].data = {
    ...state[key].data,
    ...newData
  }
  return state;
}

const handleComponentReliability = (state, data, filters = []) => {
  const key = getKeyFromFilters(filters);
  state[key] = state[key] || { data: {} };
  const newData = {"component_reliability": data};
  state[key].data = {
    ...state[key].data,
    ...newData
  }
  return state;
}


const ALLOWED_STATS = {
  'str_ffr_me.csv': handleFFRME,
  'sys_ffr_me.csv': handleFFRME,
  'com_interval_proposal.csv': handleIntervalProposal,
  'com_report_details.csv': handleReportDetails,
  'sys_component_reliability.csv': handleComponentReliability
};

const REDUCER_MAP = {
  [UPDATE_DOSSIER_STATS]: reduceDossierStatsUpdates,
  [CLEAR_DOSSIER_STATUS] : clearDossierStats
};

export default dossierStats;
