
export const FEATURES = {
  PDF_RENDERER: 'FEATURE_PDF_RENDERER',
  EDITABLE_TE: 'FEATURE_EDITABLE_TE',
  EDITABLE_TABLE: 'FEATURE_EDITABLE_TABLE',
  SMIED_ACCESS: 'FEATURE_SMIED_ACCESS'
};

/**
 * feature toggle
 */
export const getFeatureToggleList = () => {
  const { REACT_APP_STAGE } = process.env;
  const releaseToggles = {
    dev: {
      [FEATURES.EDITABLE_TE]: true,
      [FEATURES.EDITABLE_TABLE]: true,
      [FEATURES.SMIED_ACCESS]: true,
    },
    int: {
      [FEATURES.EDITABLE_TE]: true,
      [FEATURES.EDITABLE_TABLE]: true,
      [FEATURES.SMIED_ACCESS]: true,
    },
    val: {
      [FEATURES.EDITABLE_TE]: true,
      [FEATURES.EDITABLE_TABLE]: true,
      [FEATURES.SMIED_ACCESS]: true,
    },
    pro: {
      [FEATURES.EDITABLE_TE]: true,
      [FEATURES.EDITABLE_TABLE]: true,
      [FEATURES.SMIED_ACCESS]: true,
    }
  };
  return releaseToggles[REACT_APP_STAGE];
};
