import {IconButton, Input, Textarea, Typography} from "@airbus/components-react";
import React from "react";
import {Visibility} from "@airbus/icons/react";

/**
 * A simple default function to render the body of the edit/add popup
 * @param fields
 * @param data
 * @param defaultData
 * @param disabledEdits
 * @return JSX
 */
const defaultDialogAction = (onClickHandler) => {
    return (
        <IconButton
            variant="ghost"
            className="preview-btn"
            size="small"
            onClick={() => onClickHandler()}
        >
            <Visibility/>
        </IconButton>);
};

const defaultDialogBody = ({fields, currentRow, defaultData, disabledEdits, setEditedData}) => {
    return (
        fields.map(field => {
            const warningText = [',', '[', ']'].some(item => (
                currentRow?.[field.key]?.includes(item) || defaultData?.[field.key]?.includes(item)
            ));
            return (
                <div key={`dialog-editrow-${field.key}`}>
                    <Typography variant="medium" className="remarks-typo">
                        {field.label}
                    </Typography>
                    {field.multiline ?
                        (<>
                            <Textarea
                                disabled={disabledEdits?.includes(field.label)}
                                multiline={field.multiline}
                                value={currentRow?.[field.key] || defaultData?.[field.key] || ""}
                                className="ip-remarks"
                                name="dialog"
                                onChange={(e) => {
                                    setEditedData(field.key, e.target.value)
                                }}
                            />
                            {warningText && <p style={{
                                color: 'red',
                                fontWeight: 'bold'
                            }}>{`Due to an incompatibility, commas and brackets(",", "[" and "]") are disabled.\nYou can still write them but they'll be striped from the final text.`}</p>
                            }
                        </>) : (
                            <>
                                <Input
                                    disabled={disabledEdits?.includes(field.label)}
                                    multiline={field.multiline}
                                    value={currentRow?.[field.key] || ""}
                                    className="ip-remarks"
                                    name="dialog"
                                    onChange={(e) => {
                                        setEditedData(field.key, e.target.value);
                                    }}
                                />
                                {warningText && <p style={{
                                    color: 'red',
                                    fontWeight: 'bold'
                                }}>{`Due to an incompatibility, commas and brackets(",", "[" and "]") are disabled.\nYou can still write them but they'll be striped from the final text.`}</p>
                                }
                            </>
                        )
                    }
                </div>
            )
        })
    );
};

export {defaultDialogBody, defaultDialogAction};
