import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import idx from "idx";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../utils/data.request";
import TitleData from "./components/TitleData/TitleData";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Loader from "../Loader/Loader";
import { updateDossierStats } from "../../models/dossierModel/dossierStatusActions";

const ReportTitle = (props) => {
  const { sources, details } = idx(props, (_) => _.content);
  const { reportName } = useParams();
  const dispatch = useDispatch();
  const [titleDetails, setTitleDetails] = useState({});
  const userData = useSelector((state) => state.userData);

  useEffect(() => {
    getData(sources, reportName)
      .then((response) => {
        setTitleDetails((state) => ({ ...state, details: response }));
      })
      .catch((error) => {
        setTitleDetails({ error });
      });
    getData(sources, reportName, "edits")
      .then((response) => {
        setTitleDetails((state) => ({
          ...state,
          userData: { edits: response.data },
        }));
      })
      .catch((error) => {
        setTitleDetails({ error });
      });
  }, [sources, reportName, userData]);

  useEffect(() => {
    titleDetails &&
      titleDetails.details &&
      titleDetails.details.data &&
      titleDetails.details.data.length &&
      dispatch(updateDossierStats(reportName, titleDetails.details));
  }, [titleDetails, reportName, dispatch]);

  return titleDetails.details ? (
    <TitleData
      details={details}
      titleDetails={titleDetails}
      reportName={reportName}
    />
  ) : titleDetails.error ? (
    <ErrorHandler error={titleDetails.error} />
  ) : (
    <Loader />
  );
};

export default ReportTitle;
