import { API, Auth } from 'aws-amplify';

export const API_CALL = 'API_CALL';
export const API_CALL_BEGIN = 'API_CALL_BEGIN'
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS'
export const API_CALL_FAILURE = 'API_CALL_FAILURE'

const begin = (componentName) => ({type: API_CALL_BEGIN, payload: componentName })
const success = (componentName) => ({type: API_CALL_SUCCESS, payload: componentName })
const failure = (componentName) => ({type: API_CALL_FAILURE, payload: componentName })

export const requestApi = async (options) => {

    const { apiName, path, params = {}, method, isAccess } = options;
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const accessToken = user.signInUserSession.accessToken.jwtToken;
    const headers = params.headers || {};
    const request = {
      ...params,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
        Authorization: `Bearer ${isAccess ? accessToken : token}`
      }
    };
    return await API[method](apiName, path, request);
  }

export const apiHandler = ({ dispatch, getState }) => next => action => {
    next(action);
    if(action.type !== API_CALL) { return }

    const { apiOptions, handlers, passData, componentName } = action

    componentName && dispatch(begin(componentName))

    apiMiddleware.requestApi(apiOptions).then((response)=>{
      dispatch(handlers[0](response, passData, getState()))
      componentName && dispatch(success(componentName))
    }).catch((err)=>{
      dispatch(handlers[1](err, passData, getState()))
      componentName && dispatch(failure(componentName))

    })

}
const apiMiddleware = {
  apiHandler,
  requestApi
}
export default apiMiddleware