import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import PropTypes from "prop-types";
import {
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogHeader,
  Input,
  Typography,
  Checkbox,
  Textarea,
  Toggle,
} from "@airbus/components-react";
import {Edit, Visibility, Restore} from "@airbus/icons/react";
import {formatIPdata, removeIsDisabled} from "./TableUtils";
import {
  saveUpdatedStorageData,
  getEditsFromLocalStorage,
  saveEditsToLocalStorage,
  clearEditsFromLocalStorage,
} from "../../utils/localStorageHelper";
import {
  setSaveStatus,
  setTEDisabledColumns,
} from "../../models/teEditableModel/teEditableAction";
import "./TableComponent.scss";
import {ED_DISABLE_TYPE} from "../../config/dossier.status";
import {DOSSIER_TYPES} from "../../lib/constants";

const TableEditable = (props) => {
  const {
    data,
    labels,
    section_level_key,
    report_level_key,
    handleReRender,
    title,
    allowedEdits,
    dossierType
  } = props;

  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [editedTableData, setEditedTableData] = useState({});
  const [updatedTableData, setUpdatedTableData] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    isSampleIntervalEnabled: false,
    isSampleThresholdEnabled: false,
  });

  useEffect(() => {
    if (title === "Interval Proposal") {
      const formattedIPdata = formatIPdata(allowedEdits, data);
      const updatedData = removeIsDisabled(formattedIPdata);
      setUpdatedTableData(updatedData);
      data[0] &&
      data[0].isDisabled === "true" &&
      dispatch(
        setTEDisabledColumns({
          type: ED_DISABLE_TYPE.INIT,
          data: ["Interval Proposal"],
          section: "Technical_Evaluation@" + section_level_key.split("@")[1],
        })
      );
    } else {
      setUpdatedTableData(data);
    }
  }, [
    data,
    report_level_key,
    dossierType,
    allowedEdits,
    title,
    section_level_key,
    dispatch,
  ]);

  const handleOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  const handlePreview = () => {
    saveUpdatedStorageData(
      report_level_key,
      section_level_key,
      editedTableData
    );
    dispatch(setSaveStatus(report_level_key));
    handleReRender();
    handleClose();
  };

  const handleReset = () => {
    const storageData = getEditsFromLocalStorage(report_level_key);
    if (Object.keys(storageData).includes(section_level_key)) {
      delete storageData[section_level_key];
      Object.keys(storageData).length === 0
        ? clearEditsFromLocalStorage(report_level_key)
        : saveEditsToLocalStorage(report_level_key, storageData);
    }
    handleClose();
    dispatch(setSaveStatus(report_level_key));
    handleReRender();
  };

  const handleCheckBox = (e) => {
    const targetCBid = e.target.id;
    checkboxState[targetCBid] = !checkboxState[targetCBid];
    setCheckboxState({...checkboxState});
    setEditedTableData({...editedTableData, ...checkboxState});
  };

  const handleTextChange = (e, rowObj) => {
    const editedColumn = {};
    editedColumn[e.target.id] = e.target.value;
    const name = rowObj.name ? rowObj.name : "Interval";
    editedTableData[name] = {...editedTableData[name], ...editedColumn};
    setEditedTableData(editedTableData);
  };

  const handleRemarksUpdate = (e) => {
    editedTableData["Remarks"] = e.target.value;
    setEditedTableData(editedTableData);
  };

  const handleInputDisable = (objKey, rowObj) => {
    if (objKey === "name") {
      return true;
    } else if (rowObj.name && rowObj.name === "Sample Interval") {
      return !checkboxState.isSampleIntervalEnabled;
    } else if (rowObj.name && rowObj.name === "Sample Threshold") {
      return !checkboxState.isSampleThresholdEnabled;
    } else {
      return false;
    }
  };

  const handleToggle = (e, checked) => {
    const currHeading = e.target.getAttribute("data-toggle-heading");
    data[0].isDisabled = checked ? "false" : "true";
    dispatch(
      setTEDisabledColumns({
        type: ED_DISABLE_TYPE.TOGGLE,
        data: {checked: checked, currHeading},
        section: "Technical_Evaluation@" + section_level_key.split("@")[1],
      })
    );
  };

  const getUsedLabels = () => {
    return labels.filter(it => it.key !== 'remarks')
  }

  const renderColumns = (labelsUsed) => {
    return (
      <div className='row-flex'>
        {labelsUsed.map(item => {
          return (<Typography variant="medium" key={item.key} className='columns-labels'>
            <b>{item.label || ""}</b>
          </Typography>)
        })}
      </div>
    )
  }

  const renderInputs = (data, labelsUsed) => {
    const keys = labelsUsed.map(it => it.key);
    return (
      data.map((rowData, idxData) => {
        return (
          <div className='row-flex' key={`data-row-${idxData}`}>
            {keys.map((key, idx) => {
              return (
              <div className='inputs-container' key={`input-${key}-row-${idx}`}>
                <Input
                  key={key}
                  id={key}
                  defaultValue={rowData[key]}
                  className={
                    handleInputDisable(key, rowData)
                      ? "ip-textinput-disabled"
                      : "ip-textinput"
                  }
                  disabled={handleInputDisable(key, rowData)}
                  onChange={(e) => handleTextChange(e, rowData)}
                />
              </div>
              );
            })}
          </div>
        )
      })
    );
  }

  const renderDialogContentForm = (data) => {
    const labelsUsed = getUsedLabels();
    return (
      <div className="form-table-container">
        {renderColumns(labelsUsed)}
        {renderInputs(data, labelsUsed)}
        {renderCBandRemarks(data)}
      </div>
    );
  }

  const renderCBandRemarks = (data) => {
    return (
      <div>
        <Typography variant="medium">
          <b>Remarks</b>
        </Typography>
        <Textarea
          multiline={5}
          defaultValue={data[0]["remarks"]}
          id="remarks"
          className="ip-remarks"
          onChange={(e) => handleRemarksUpdate(e)}
        />
        {dossierType === DOSSIER_TYPES.STRUCTURE ? (
          <>
            <Checkbox
              label="Enable Sample Threshold"
              checked={checkboxState.isSampleThresholdEnabled}
              id="isSampleThresholdEnabled"
              className="st-checkbox"
              onChange={(e) => handleCheckBox(e)}
            />
            <Checkbox
              label="Enable Sample Interval"
              checked={checkboxState.isSampleIntervalEnabled}
              id="isSampleIntervalEnabled"
              className="si-checkbox"
              onChange={(e) => handleCheckBox(e)}
            />
          </>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="table-edit exclude-print">
        {data[0] && data[0].isDisabled !== "true" && (
          <Tooltip title="Edit">
            <IconButton
              variant="ghost"
              className="edit-btn"
              size="small"
              onClick={handleOpen}
            >
              <Edit/>
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Enable/Disable Section" placement="top">
          <div style={{padding: "6px"}}>
            <Toggle
              className="toggle-btn"
              defaultChecked={data[0] && data[0].isDisabled !== "true"}
              onChange={handleToggle}
              data-toggle-heading={title}
              size="small"
            />
          </div>
        </Tooltip>
      </div>
      <div>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          className="dialog-edit"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogHeader className="dialog-header">{`Edit ${title}`}</DialogHeader>
          <DialogContent>
            {updatedTableData.length &&
              renderDialogContentForm(updatedTableData)}
          </DialogContent>
          <DialogActions className="dialog-action">
            <IconButton
              variant="ghost"
              className="preview-btn"
              size="small"
              onClick={handlePreview}
            >
              <Visibility/>
            </IconButton>
            <IconButton
              variant="ghost"
              className="reset-btn"
              size="small"
              onClick={handleReset}
            >
              <Restore/>
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

TableEditable.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  section_level_key: PropTypes.string.isRequired,
  report_level_key: PropTypes.string.isRequired,
  handleReRender: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  allowedEdits: PropTypes.array.isRequired,
  dossierType: PropTypes.string.isRequired,
  maintenanceProgram: PropTypes.string.isRequired,
};

export default TableEditable;
