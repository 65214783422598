import { API_METHOD_POST, SAVE_MPO_EDITS } from "../../utils/api.actions";
import { SAVE_EDITS_FAILURE, SAVE_EDITS_SUCCESS } from "./teEditableActionType";
import { API_CALL } from "../../utils/api.middleware";

import getConfig from "../../config";
const config = getConfig();
const apiName = config.API.endpoints[0].name;

const saveEditsSuccess = (data, passData, store) => { // eslint-disable-line no-unused-vars
  return { type: SAVE_EDITS_SUCCESS, payload: data.body };
};
const saveEditsFailure = (error, passData, store) => ({ // eslint-disable-line no-unused-vars
  type: SAVE_EDITS_FAILURE,
  error: error,
  autoHideDuration: 10000,
});

export const apiSaveMpoEdits = (editsData) => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: SAVE_MPO_EDITS,
      method: API_METHOD_POST,
      params: {
        body: editsData,
      },
    },
    handlers: [saveEditsSuccess, saveEditsFailure],
    passData: { dossierKey: editsData.dossierKey },
    componentName: "teEditableSaveEdits",
  };
};
