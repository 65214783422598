/**
 * Function to get custom fields mapping for CR with default program
 * @param mpdTaskMaintenanceProgram
 * @returns {[{multiline: number, label: string, placeholder: string, key: string, required: boolean},{bypass: string, multiline: number, label: string, placeholder: string, key: string, required: boolean, primaryKey: string},{bypass: string, multiline: number, label: string, placeholder: string, key: string, required: boolean},{errorText: string, multiline: number, constraint: {allowedValues: string[], type: string}, label: string, placeholder: string, key: string, required: boolean},{errorText: string, multiline: number, constraint: {allowedValues: string[], type: string}, label: string, placeholder: string, key: string, required: boolean},null,null]}
 */

export const getCustomFieldsCR = (mpdTaskMaintenanceProgram) => {
    return [
        {
            "key": "Component",
            "label": "Component *",
            "multiline": 1,
            "required": true,
            "placeholder": "Enter the Component description",
            "errorText": ""
        },
        {
            "key": "PN",
            "label": "P/N **",
            "multiline": 1,
            "required": true,
            "bypass": "family_id",
            "placeholder": "Enter the Part Number",
            "primaryKey": "PN_unique",
            "errorText": ""
            //remove commentary if you need to implement PN unicity (except for VARIOUS and DIVERSE)
            /*"constraint":{
                "type":"unique",
                "allowedValues":["VARIOUS", "DIVERSE"],
            },
            "errorText": "P/N is already existing"*/

        },
        {
            "key": "family_id",
            "label": "P/N Family **",
            "multiline": 1,
            "required": true,
            "bypass" : "PN",
            "placeholder": "Enter the Part Number Family",
            "errorText": ""


        },
        {
            "key": "PN_MTBUR",
            "label": "MTBUR (FH)",
            "multiline": 1,
            "placeholder": "Enter the P/N or P/N Family MTBUR",
            "required": true,
            "constraint":{
                "type":"number",
                "allowedValues":["N/A"],
            },
            "errorText": "A number is required (or 'N/A')"
        },
        {
            "key": "PN_MTBF",
            "label": "MTBF (FH)",
            "multiline": 1,
            "placeholder": "Enter the P/N or P/N Family MTBF",
            "required": true,
            "constraint":{
                "type":"number",
                "allowedValues":["N/A"],
            },
            "errorText": "A number is required (or 'N/A')"
        },
        {
            "key": "AC_Program",
            "label": "A/C Program",
            "multiline": 1,
            "placeholder": "Enter the A/C Program",
            "defaultData": mpdTaskMaintenanceProgram
        },
        {
            "key": "Source",
            "label": "Source",
            "multiline": 0,
            "placeholder": "Enter Source",
            "defaultData": "In Service Data"
        }
    ]
}
const regexNumber = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/


/**
 * Function of field control
 * @param field
 * @param edited
 * @param data
 * @returns {*|boolean|boolean}
 */
export const isFieldValid = (field, edited, data) => {
    const {constraint, required: isRequired, bypass, key} = field
    const bypassStatus = bypass ? edited[bypass] : false
    const value = edited[key]
    const isEmpty = !value
    const commonRestrict = isRequired && isEmpty

    if(constraint){
        let typeResticted = !!constraint.type
        let unique = true
        switch(constraint.type){
            case "number":
                typeResticted = regexNumber.test(value)
                break;
            case "unique":
                unique = !data.some(obj => obj[key] === value )
        }
        const allowedValue = constraint.allowedValues.includes(value)
        const result = !commonRestrict && (typeResticted || allowedValue)  && (unique || allowedValue)

        return result
    }
    return bypassStatus || !commonRestrict
}
