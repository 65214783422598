import * as LABELS from "../../config/content.label";

/**
 * Simple functions to handle changes to local storage
 */

const getDataFromLS = (key, sectionKey) => {
    const data = JSON.parse(localStorage.getItem(key));
    return data?.[sectionKey]?.table;
};

const setDataToLS = (key, val, sectionKey) => {
    const value = {
        [sectionKey]: {
            table: val
        }
    }
    const savedData = JSON.parse(localStorage.getItem(key))
    const result = {...savedData, ...value}
    localStorage.setItem(key, JSON.stringify(result));
    const dispatchOption = {
        key,
        newValue: JSON.stringify(result)
    }
    window.dispatchEvent(new StorageEvent('storage', dispatchOption))
};

const removeDataFromLS = (key) => {
    localStorage.removeItem(key);
    const dispatchOption = {
        key,
        newValue: null
    }
    window.dispatchEvent(new StorageEvent('storage', dispatchOption))
}

const updateDataFromLSCR = (key) => {
    const item = JSON.parse(localStorage.getItem(key))
    const labelCR = 'Component_Reliability'
    const labelTE = 'Technical_Evaluation'
    const keys = item && Object.keys(item)
    const filteredKeys = keys?.filter(key => !key.startsWith(labelCR) && !key.startsWith(labelTE))
    const keyTE = keys?.find(key => key.startsWith(labelTE))
    const keysTEObject = keyTE && Object.keys(item[keyTE])
    const filteredTEKeys = keysTEObject?.filter(key => !key.startsWith(LABELS.COMPONENT_RELIABILITY))
    const filteredTEObject = filteredTEKeys?.reduce((acc, curr) => {
        acc[curr] = item[keyTE][curr]
        return acc
    },{})
    const filteredItemObject = filteredKeys?.reduce((acc, curr) => {
        acc[curr] = item[curr]
        return acc
    },{})
    const result = {...filteredItemObject, [keyTE]: filteredTEObject }

    localStorage.setItem(key, JSON.stringify(result));

    const dispatchOption = {
        key,
        newValue: null
    }
    window.dispatchEvent(new StorageEvent('storage', dispatchOption))
}

/**
 * Remove key from an object recursively
 * @param object
 * @param keyToRemove
 * @returns {undefined|*}
 */

const removeKey = (object, keyToRemove) => {
    if(typeof object !== "object" || Array.isArray(object) || object === null){
        return object
    }
    const newObj = {...object}
    for(const key in object){
        if(key !== keyToRemove){
            newObj[key] = removeKey(object[key], keyToRemove)
        } else {
            delete newObj[keyToRemove]
        }
    }
    const result = Object.keys(newObj).length ? newObj : undefined
    return result
}

/**
 * Remove key from an object recursively in LS
 * @param key
 * @param sectionKey
 */
const removeKeyFromLS = (key, sectionKey) => {
    const item = JSON.parse(localStorage.getItem(key));
    const result = removeKey(item, sectionKey)
    /* istanbul ignore else */
    if(result){
        localStorage.setItem(key, JSON.stringify(result));
        const dispatchOption = {
            key,
            newValue: JSON.stringify(result)
        }
        window.dispatchEvent(new StorageEvent('storage', dispatchOption))
    } else {
        removeDataFromLS(key)
    }
}

export {getDataFromLS, setDataToLS, removeDataFromLS, removeKey, removeKeyFromLS, updateDataFromLSCR};
