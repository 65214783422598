import React from "react";
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogContent, DialogActions
} from "@airbus/components-react";
import "../../AttachmentCard.scss";

const ConfirmModal = (props) => {
  const { isOpen, onClose, attachment, isPending, onConfirm } = props;
  return (
    <Dialog open={isOpen} title="Confirm" onClose={onClose} className="confirm-dialog-container">
      <DialogContent>
        Are you sure you want to Delete <b>{attachment.name}</b> ?
        </DialogContent>
      <DialogActions>
        <Button className="cancel-btn" onClick={onClose}>Cancel</Button>
        <Button className="confirm-btn" variant="primary" onClick={() => onConfirm(attachment.name)} disabled={isPending}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmModal.defaultProps = {
  isOpen: false,
  isPending: false
};
ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  attachment: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmModal;