import React, { useEffect } from "react";
import { Spinner, ComboBox } from "@airbus/components-react";
import { useDispatch, useSelector } from "react-redux";

import {
  apiTemplateList,
  apiReportList
} from "../../models/reportModel/reportApi";
import {
  selectTemplate,
  selectReport
} from "../../models/reportModel/reportActions";
import { Link } from "react-router-dom";

const HomePage = () => {
  const { templates, reportList, selectedTemplate, selectedReport } = useSelector(store => store.reports)
  const dispatch = useDispatch(); // this hook gives us dispatch method
  useEffect(() => {
    dispatch(apiTemplateList());
    dispatch(apiReportList());
  }, [dispatch]);

  let reportTypeOptions = {};
  let reportListOptions = {};
  templates &&
    (reportTypeOptions = templates.filter(
      item => !(/_details.json$/.test(item))
    ).map(item => ({ label: item, value: item })));
  reportList &&
    (reportListOptions = reportList.map(item => ({ label: item, value: item })));

  return (
    <div className="report-wizard-cls">
      <Link
        to={`/ReportHandler/${selectedTemplate}/${selectedReport}`}
        className="exclude-print">
        <button className="report-wizard-button button-preview" disabled={!selectTemplate || !selectedReport}>
          Preview Report
          </button>
      </Link>
      <hr />
      {templates && reportList ? (
        <div style={{ display: "inline-block", minWidth: 350 }}>
          <h4>
            {"Select a Report type: "} {selectedTemplate}
          </h4>
          <ComboBox className="template-selector"
            onChange={(event) => dispatch(selectTemplate(event.value))}
            options={reportTypeOptions}/>
          <br />
          <h4>
            {"Select a Report: "} {selectedReport}
          </h4>
          <ComboBox className="report-selector"
            onChange={(event) => dispatch(selectReport(event.value))}
            options={reportListOptions} />
        </div>
      ) : (
          <Spinner dots>Loading...</Spinner>
        )}
    </div>
  );
};

export default HomePage;
