import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import idx from "idx";
import { DragDrop } from "@uppy/react";
import Uppy from "@uppy/core";
import "../../AttachmentCard.scss";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@airbus/components-react";

const UploadModal = (props) => {
  const {
    isOpen,
    onClose,
    isPending,
    showDescription,
    sectionName,
    attachment,
    onEdit,
    onUpload,
  } = props;
  const descRef = useRef();
  const [fileInfo, setFileInfo] = useState({});
  // uppy provides drag and drop func
  const uppy = Uppy({
    restrictions: { maxNumberOfFiles: 1 },
    autoProceed: false,
  });

  useEffect(() => {
    const currentUppy = uppy && uppy.current;
    return () => currentUppy && currentUppy.close();
  }, [uppy]);

  uppy.on("file-added", (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.data);
    reader.onloadend = () => {
      const base64data = reader.result;
      const transformedName = file.name.replace(/ /g, "_");
      setFileInfo({ ...fileInfo, base64data, ...file, name: transformedName });
    };
  });
  // uppy provides drag and drop func
  const handleConfirm = () => {
    const description = idx(descRef, (_) => _.current.value);
    if (attachment) {
      onEdit({
        ...attachment,
        description,
      });
    } else {
      const attachmentObj = {
        ref: sectionName,
        name: fileInfo.name,
        description,
      };
      const fileInformation = {
        id: fileInfo.name,
        contentType: fileInfo.type,
        file: fileInfo.base64data,
      };
      onUpload({
        attachmentObj,
        fileInformation,
      });
    }
  };

  const renderFileInfo = () => {
    return attachment ? (
      <div style={{ textAlign: "center", fontWeight: 500, marginTop: 10 }}>
        Selected File : {attachment.name}
      </div>
    ) : (
      <div>
        <DragDrop
          height="170px"
          uppy={uppy}
          locale={{
            strings: { dropHereOr: "Drop here or %{browse}", browse: "browse" },
          }}
        />
        <div style={{ textAlign: "center", fontWeight: 500, marginTop: 10 }}>
          {fileInfo.name && `Selected File : ${fileInfo.name}`}
        </div>
      </div>
    );
  };
  return (
    <Dialog
      open={isOpen}
      title="Upload"
      onClose={onClose}
      className="upload-dialog-container"
    >
      <DialogContent>
        <div style={{ minWidth: 450 }}>
          {renderFileInfo()}
          {showDescription && (
            <textarea
              rows={4}
              maxLength="100"
              defaultValue={attachment ? attachment.description : ""}
              ref={descRef}
              style={{
                width: "100%",
                marginTop: 10,
                padding: 5,
                resize: "none",
              }}
              placeholder="Enter description here"
            />
          )}
        </div>
      </DialogContent>
      <DialogActions background>
        <Button className="cancel-btn" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="confirm-btn"
          onClick={handleConfirm}
          disabled={isPending}
        >
          {attachment ? "Update" : "Upload File"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
UploadModal.defaultProps = {
  isOpen: false,
  isPending: false,
};

UploadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  showDescription: PropTypes.bool.isRequired,
  sectionName: PropTypes.string.isRequired,
  attachment: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default UploadModal;
