import React, { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import idx from 'idx';
import Charts from '../Charts/Charts';
import { getData } from "../../utils/data.request";
import { prepare } from './ChartsHelper';
import NoData from "../NoData/NoData";

import './ChartsList.scss';

const ChartsList = (props) => {
  const { reportName } = useParams()
  const { sources, details } = idx(props, _ => _.content);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { data: {}, type: "", error: null }
  );

  useEffect(() => {
    getData(sources, reportName).then((response) => {
      response && setState({ data: response.data });
    }).catch(error => {
      setState({ error })
    });
  }, [sources, reportName]);

  const chList = prepare(state.data, details);
  return (
    <div className="charts-list-cls">
      {chList && chList.length ? chList.map((chart, index) => (
        <div key={index} className="chartList-item-cls">
          <Charts content={chart}/>
        </div>
      )): (
        <NoData sources={sources} />
      )}
    </div>
  );
}

export default ChartsList;