import React from 'react';

import {IconButton, TableCell, TableRow, Tooltip} from "@airbus/components-react";
import {Delete, Edit, Info, CheckCircle} from "@airbus/icons/react";
import {getStyle} from "../../TableComponent/TableUtils";
import {DataTableCell} from "./DataTableCell";
import * as LABELS from "../../../config/content.label";


/**
 * function to render table header with custom styles and displaying edit/remove based on permissions edits
 * @param labels
 * @param tableStyles
 * @param displayEdit
 * @param allowedEdits
 * @returns {JSX.Element}
 */
export const formatTableHeader = (labels, tableStyles, displayEdit, allowedEdits) => {
    const {editRow, deleteRow} = allowedEdits;
    const cellStyle = {
        "fontSize": 16,
        "fontWeight": "bold",
        "textAlign": "center",
        "minWidth": "40px"
    }
    return (
        <TableRow>

            {labels.map(item => {
                const style = {...cellStyle, ...getStyle(item.key, tableStyles, "head")}
                return (

                    <TableCell key={item.key} style={{...style, "fontWeight": "bold"}}>
                        {item.label}
                    </TableCell>
                )
            })}
            {displayEdit && editRow &&
                <TableCell style={cellStyle}>
                    <p>Edit</p>
                </TableCell>}
            {displayEdit && deleteRow &&
                <TableCell style={cellStyle}>
                    <p>Delete</p>
                </TableCell>}
        </TableRow>
    );
};

/**
 * function to render table body with custom styles, displaying edit/remove based on permissions edits,
 * CR no data management and status icon for CR only (statusCR)
 * @param data
 * @param columns
 * @param tableStyles
 * @param allowedEdits
 * @param dialogHandler
 * @param setRowIndex
 * @param displayPopup
 * @param displayEdit
 * @param setDeleteAction
 * @param title
 * @returns {JSX.Element|*}
 */
export const formatTableBody = ({data, columns, tableStyles, allowedEdits, dialogHandler, setRowIndex, displayPopup, displayEdit, setDeleteAction, title}) => {
    const {editRow, deleteRow} = allowedEdits;

    if(!data.length && title === LABELS.COMPONENT_RELIABILITY){
        const colSpanSize = columns.length + [!!editRow, !!deleteRow].filter(elt => elt).length
        return (
            <TableRow className="exclude-screen">
                <TableCell colSpan={colSpanSize} className="no-data">No component reliability data available</TableCell>
            </TableRow>)
    }


    return data.map((row, index) => {
        return (
            <TableRow key={`rowtable-${index}`}>
                {columns.map((column) => (
                    <TableCell key={`celltable-${column.key}`} style={{...getStyle(column, tableStyles, "body"), textAlign: '-webkit-center'}}>
                        <DataTableCell
                            maxLength={50}
                            maxLines={3}
                            cellValue={column.key === "statusCR" ? row[column.key] === "warning" ?
                                <Tooltip title={"Duplicated line"}>
                                    <IconButton variant="ghost" aria-label="Info">
                                        <Info color="#fabd00"/>
                                    </IconButton>
                                </Tooltip> : row[column.key] === "success" ?
                                    <IconButton variant="ghost" aria-label="Success">
                                        <CheckCircle color="#097350"/>
                                    </IconButton> : row[column.key] : row[column.key]}/>
                    </TableCell>
                ))}
                {editRow && displayEdit
                    && (<TableCell>
                            <IconButton
                                variant="ghost"
                                className={"ror-th-btn"}
                                aria-label="Edit Line"
                                onClick={() => {
                                    setRowIndex(index);
                                    dialogHandler(true);
                                }}
                            >
                                <Edit/>
                            </IconButton>
                        </TableCell>
                    )}
                {deleteRow && displayEdit
                    && (
                        <TableCell>
                            <IconButton
                                variant="ghost"
                                className={"ror-th-btn ror-th-btn-del"}
                                onClick={() => {
                                    displayPopup(true);
                                    setDeleteAction("deleteRow")
                                    setRowIndex(index);
                                }}
                            >
                                <Delete/>
                            </IconButton>
                        </TableCell>
                    )}
            </TableRow>
        )
    });
};

/**
 * function to set default multiline value
 * @param columns
 * @returns {*}
 */
export const defaultFormatColumns = (columns) => {
    return columns.map(item => ({...item, ...(['Source', 'Level', 'Interval'].includes(item.label) ? {multiline: 0} : {multiline: 1})}));
}

/**
 * function to check is a string can be parse into JSON
 * @param str
 * @returns {boolean}
 */
export const isJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/**
 * function to check if value is null
 * @param value
 * @returns {boolean}
 */
export const isCellEmpty = (value) => (
    value == null
);

/**
 * function to check if a value needs to be trimmed (length)
 * @param value
 * @param maxLength
 * @returns {boolean}
 */
export const isLengthTrimRequired = (value, maxLength) => (
    value.length > maxLength
);

/**
 * function to check function to check if a value needs to be trimmed (multi line)
 * @param value
 * @param maxLines
 * @returns {boolean}
 */
export const isLineTrimRequired = (value, maxLines) => (
    ((value.toString().match(/\n/g) || '').length + 1) > maxLines
);

/**
 * function to check if a value needs to be trimmed (global)
 * @param value
 * @param maxLines
 * @param maxLength
 * @returns {boolean}
 */
export const isTrimRequired = (value, maxLines, maxLength) => (
    isLineTrimRequired(value, maxLines) || isLengthTrimRequired(value, maxLength)
);

/**
 * function tu trim value
 * @param value
 * @param maxLines
 * @param maxLength
 * @returns {string|*}
 */
export const getTrimValue = (value, maxLines, maxLength) => {
    if (isLineTrimRequired(value, maxLines)) {
        return `${value.split('\n').slice(0, maxLines).join('\n')}...`;
    }
    if (isLengthTrimRequired(value, maxLength)) {
        return `${value.substring(0, maxLength)}...`;
    }
    return value;
};

/**
 * function to display value or trimmed value depending on the initial value
 * @param value
 * @param maxLines
 * @param maxLength
 * @param showTrimData
 * @returns {string|*}
 */
export const getCellValue = (value, maxLines, maxLength, showTrimData) => (
    !isCellEmpty(value) && showTrimData ? getTrimValue(value, maxLines, maxLength) : value
);
