import React from "react";
import idx from 'idx';
import * as CATEGORIES from "../../config/content.category";
import TableComponent from "../TableComponent/TableComponent";
import Charts from "../Charts/Charts";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import ReportTitle from "../ReportTitle/ReportTitle";
import ReportText from "../ReportText/ReportText";
import TableOfContents from "../TableOfContents/TableOfContents";
import FileAttachment from "../FileAttachment/FileAttachment";
import HashTable from "../HashTable/HashTable";
import AttachmentCard from "../AttachmentCard/AttachmentCard";
import Essay from "../Essay/Essay";
import ChartsList from "../ChartsList/ChartsList";
import ConsolidatedTasksTable from "../ConsolidatedTasks/Table/ConsolidatedTasksTable";

const getComponent = (category) => {
    const COMPONENT_MAP = {
        [CATEGORIES.TABLE]: TableComponent,
        [CATEGORIES.GENERIC_TABLE]: ConsolidatedTasksTable,
        [CATEGORIES.CHART]: Charts,
        [CATEGORIES.IMAGE]: ImageRenderer,
        [CATEGORIES.COVER]: ReportTitle,
        [CATEGORIES.TEXT]: ReportText,
        [CATEGORIES.TBLCONTENTS]: TableOfContents,
        [CATEGORIES.ATTACHMENTS]: FileAttachment,
        [CATEGORIES.HASHTABLE]: HashTable,
        [CATEGORIES.INFOCARD]: AttachmentCard,
        [CATEGORIES.ESSAY]: Essay,
        [CATEGORIES.CHARTSLIST]: ChartsList
    };
    return COMPONENT_MAP[category]
        ? COMPONENT_MAP[category]
        : React.Fragment;
}
const ContentRenderer = props => {
    const {content, sectionName, parentName} = idx(props, _ => _);
    const label = idx(content, _ => _.label)
    let ContentComp = getComponent(content.category);
    let buttonContent = "Add new entry"
    if (parentName === "Consolidated Task") { //temp if until Generic table is used for all table component
        ContentComp = getComponent("generictable")
    }
    if (label === "Component Reliability") {
        ContentComp = getComponent("generictable")
        buttonContent = "Add P/N Details"
    }
    return (
        <ContentComp content={content} sectionName={sectionName} parentName={parentName} buttonContent={buttonContent}/>
    );
};


export default ContentRenderer;
