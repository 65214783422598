import React from 'react';
import {SyncProblem} from "@airbus/icons/react"
import './ErrorHandler.scss';
import PropTypes from 'prop-types';

const ErrorHandler = (props) => {
  return (
    <div className="error-cls">
        <div className='header'>
          <span><SyncProblem style={{ verticalAlign: 'middle' }} />- Error</span>
        </div>
        <div className='content'>
          <span>{message(props.error)}</span>
        </div>
    </div>
  )
}
const message = (error) => {
  return error && error.response && error.response.data.errorMessage.replace(/\[.*?\]/g, '').trim();
};


ErrorHandler.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({
      data: PropTypes.shape({
        errorMessage: PropTypes.string.isRequired
      })
    })
  })
};
export default ErrorHandler;