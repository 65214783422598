import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Chevronright, Chevronleft } from "@airbus/icons/react";
import Loader from "../Loader";
import AddComment from './AddComment';
import ViewComment from "./ViewComment";
import "./SlidingBox.scss";

const SlidingBox = (props) => {
  const { isEditable, comments, toc, isSlidingBoxOpen, status, email, dossierKey, mpeComments, mpeLocalComments, setRejectionComments, rejectionLocalComments} = props
  const [isBoxOpen, setBoxOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleBoxStatus = () => {
    setBoxOpen(!isBoxOpen)
  } 
  useEffect(() => {
    setBoxOpen(isSlidingBoxOpen);
  }, [isSlidingBoxOpen])

  return (
    <div className={`sliding-box ${isBoxOpen ? 'sliding-box-closed' : 'sliding-box-opened'} exclude-print`}>
      <div className="handle" onClick={toggleBoxStatus}>
        {isBoxOpen
          ? <Chevronright htmlColor="#ffffff"  style={{ fontSize: "2rem" }} />
          : <Chevronleft htmlColor="#ffffff" style={{ fontSize: "2rem" }} />}
      </div>
      <div className="box" >
        {isLoading ? <Loader isBlocking={true} /> : null}
        {isEditable ? <AddComment
          dossierKey={dossierKey}
          comments={comments}
          setLoading={setLoading}
          toc={toc}
          isSlidingBoxOpen={isSlidingBoxOpen}
          toggleBoxStatus={toggleBoxStatus}
          status={status}
          email={email}
          mpeLocalComments={mpeLocalComments}
          setRejectionComments ={setRejectionComments}
          rejectionLocalComments={rejectionLocalComments}
        />
          : <ViewComment 
              setLoading={setLoading} 
              comments={comments} 
              mpeComments={mpeComments}
              toc={toc}/>}
      </div>
    </div>
  )
}

SlidingBox.defaultProps = {
  isEditable: false,
  comments: [],
  toc: [],
  isSlidingBoxOpen: false,
  mpeComments: [],
  mpeLocalComments: [],
  rejectionLocalComments : []
};

SlidingBox.propTypes = {
  isEditable: PropTypes.bool.isRequired,
  comments: PropTypes.array,
  toc: PropTypes.array,
  isSlidingBoxOpen: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dossierKey: PropTypes.string.isRequired,
  mpeComments: PropTypes.array,
  mpeLocalComments: PropTypes.array,
  setRejectionComments: PropTypes.func,
  rejectionLocalComments: PropTypes.array,
};

export default SlidingBox;
