import React from "react";
import { useSelector } from "react-redux";
import {
  Header as AppBar,
  Tabs,
  Tab,
  ProgressBar,
} from "@airbus/components-react";
import { Link } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  const { zipFilenames, zipDownload } = useSelector((store) => store.loading);
  return (
    <div className="exclude-print header-cls">
      <AppBar
        style={{ position: "sticky", marginBottom: "1px" }}
        appName="MPO PBS"
      >
        <Tabs>
          <Tab component={Link} to="/signout">SignOut</Tab>
        </Tabs>
      </AppBar>
      <div className="progress-bar">
        {zipFilenames || zipDownload ? <ProgressBar indeterminate /> : false}
      </div>
    </div>
  );
};
export default Header;
