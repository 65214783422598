/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {Button, Spinner, Tooltip} from "@airbus/components-react";
import idx from "idx";

import {getDataFromLS} from "../../LSUtils";
import {updateToast} from "../../../../models/toastModel/toastActions";
import {useDispatch} from "react-redux";
import "./ResetChangesDialog.scss"
import {getDataWithMultipleSources} from "../../../../utils/data.request";
import {useParams} from "react-router-dom";
import SRSButton from '../../../SRSButton/SRSButton';
import {buildInitData, handlePasteDataCR, reformatSRSJson, tryParseJson} from './utils';
import {defaultFormatColumns} from "../../Table/TableUtils";

/**
 *
 * @param props
 *        title: String => The title of the section to reset
 *        openEditDialog: Function(bool) => Handler for opening and closing dialog
 *        setCurrentRow: Function(number) => Handler for selecting row. Since it's for resetting changes, we set it to -1
 *        resetChanges: Function() => Handler to reset the changes mades to local storage
 *        buttonContent: String => text for add button
 *        program : string => program of the dossier
 *        report_level_key : string => current report_level_key
 *        section_level_key : string => current section_level_key
 *        rawTableData : Object => contains data of the table
 *        persistData : function to persist data into localStorage
 *        setDataToDisplay : useState setter to set data to display
 *        setDisplayDialogPopup : useState setter to set displaying of the dialog popup
 *        setDeleteAction : useState setter to set delete action
 *        content : Object content from redux store
 *        pns : array => pns from dropdown use for Open to SRS button
 *        labels : array => columns header
 *        setLabels : useState setter to set columns
 * @return {JSX.Element}
 * @constructor
 */

const ResetChangesDialog = (props) => {

    const {
        title,
        openEditDialog,
        setCurrentRow,
        buttonContent,
        program,
        report_level_key,
        section_level_key,
        rawTableData,
        persistData,
        setDataToDisplay,
        setDisplayDialogPopup,
        setDeleteAction,
        content,
        pns,
        labels,
        setLabels,
    } = idx(props, (_) => _);
    const {reportName} = useParams();
    const {sources} = content;


    // create the state for the SRS button dates
    const [srsDates, setSrsDates] = useState({});
    // create the state for the SRS button Operator ( only in SOEE case)
    const [srsOperator, setSrsOperator] = useState('')
    const [maintenanceProgram, setMaintenanceProgram] = useState('');
    // retreive the PN numbers from the store

    const dispatch = useDispatch();
    const [pasteDataLoading, setPasteDataLoading] = useState(false)

    const handlePaste = () => navigator.clipboard.readText()
        .then(text => {
            setPasteDataLoading(true)
            pasteData(text, title, labels).finally(() => setPasteDataLoading(false))
        })

    /**
     * Function to handle data paste from clipboard
     * @param json
     * @param title
     * @param columns
     * @returns {Promise<void>}
     */
    const pasteData = async (json, title, labels) => {
        let toastData = []
        const columns = defaultFormatColumns(labels)
        switch (title) {
            case "Component Reliability": {
                const dataInLS = getDataFromLS(report_level_key, section_level_key);
                let crResult = dataInLS
                const transformedParsedJson = reformatSRSJson(json, toastData)
                const parsedJson = tryParseJson(transformedParsedJson, toastData)
                /* istanbul ignore else */
                if (parsedJson) {
                    for (const line of parsedJson) {
                        const initData = buildInitData(crResult, rawTableData)
                        const handleResult = await handlePasteDataCR(line, crResult, toastData, columns, initData, program)
                        crResult = handleResult.crResult
                        toastData = handleResult.toastData
                    }

                    /* istanbul ignore else */
                    if (crResult.length) {
                        const crResultNoStatus = crResult.reduce((acc, curr) => {
                            const map = {}
                            for (const key in curr) {
                                if (key !== "statusCR") {
                                    map[key] = curr[key]
                                }
                            }
                            acc.push(map)
                            return acc
                        }, [])
                        persistData(crResultNoStatus)
                        setDataToDisplay(crResult)
                        setLabels(prev => {
                            const status = {"key": "statusCR", "label": "Status"}
                            return [status, ...prev.filter(elt => elt.key !== "statusCR")]
                        })
                    }
                    break;
                }
            }
        }
        dispatch(updateToast(toastData))
    }

    /**
     * useEffect to call date, operators and maintenance program from Backend
     * used later to build URL for "Open to SRS" button
     */
    useEffect(() => {
        getDataWithMultipleSources(sources, reportName)
            .then((response) => {
                /* istanbul ignore else */
                if (response) {
                    setSrsDates({
                        startDate: response[1]?.value?.data?.[0]?.taskReportStart,
                        endDate: response[1]?.value?.data?.[0]?.taskReportEnd
                    });
                    const operators = response[1]?.value?.data?.[0]?.operators
                    setSrsOperator(operators);
                    setMaintenanceProgram(response[1]?.value?.data?.[0].mpdTaskMaintenanceProgram);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [sources, reportName]);

    return (
        <div className='table-modify task-review-buttons exclude-print'>
            {title === "Component Reliability" ?
                <>
                    <Tooltip title="Open to SRS">
                        <SRSButton dates={srsDates} operators={srsOperator} pns={pns?.join(",")}
                                   maintenanceProgram={maintenanceProgram}/>
                    </Tooltip>
                    <Tooltip title="Paste">
                        <Button
                            variant="secondary"
                            className="secondary-btn padding-paste-add"
                            disabled={pasteDataLoading}
                            onClick={handlePaste}
                            id="paste"
                        >
                            {pasteDataLoading ? <Spinner/> : "Paste Clipboard"}
                        </Button>
                    </Tooltip>
                </> : <></>}
            <Tooltip title="Reset">
                <Button
                    variant="secondary"
                    className="secondary-btn"
                    id="reset"
                    onClick={() => {
                        setDisplayDialogPopup(true);
                        setDeleteAction("resetTable")
                    }}
                >
                    {"Reset"}
                </Button>
            </Tooltip>
            <Tooltip title="Add">
                <Button
                    variant="ghost"
                    className="edit-btn padding-paste-add"
                    size="small"
                    id="add"
                    onClick={() => {
                        setCurrentRow(-1);
                        openEditDialog(true);
                    }}
                >
                    {buttonContent}
                </Button>
            </Tooltip>
        </div>
    )
};

export default ResetChangesDialog;
