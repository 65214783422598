import { GET_IMAGE_SUCCESS, CLEAR_REPORT } from "./imageActionType";

const initialState = {};
const imageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE_SUCCESS:{
      const imgDtls = {}
      const base64String = "data:image/png;base64, " + action.payload.data.body
      imgDtls[action.payload.passData.key] = base64String
      return { ...state, ...imgDtls };
    }
    case CLEAR_REPORT: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export default imageReducer;
