import React from 'react';
import './DataTableCell.scss';
import {Link} from '@airbus/components-react';
import {getCellValue, isTrimRequired} from "./TableUtils";
import PropTypes from "prop-types";

/**
 * A generic component to represent a cell of data in the DataTable component.
 * Data greater than a predefined length (or number of lines) can be hidden/expanded using a show more/show less button.
 * @param {string} cellValue Value to be displayed in cell
 * @param {number} maxLines The maximum number of lines that can be displayed without truncating/hiding data
 * @param {number} maxLength The maximum length (number of charecters) of data that can be displayed without truncating/hiding data
 */
export const DataTableCell = function DataTableCell(props) {
    const {cellValue, maxLength, maxLines} = props;
    const cellValueFormat = Array.isArray(cellValue) ? cellValue.join(', ') : cellValue
    const [showTrimData, setShowTrimData] = React.useState(true);

    const renderShowMoreLessButton = () => {
        return (
            <Link
                className="data-table-cell-link"
                variant="underlined"
                id="ShowMoreLess"
                onClick={(e) => {
                    e.preventDefault();
                    setShowTrimData((prevState) => !prevState);
                }}
            >
                {showTrimData ? 'more' : 'less'}
            </Link>
        );
    };


    return (<>
        <div className="data-table-cell-cls exclude-print">
            {cellValueFormat != null &&  getCellValue(cellValueFormat, maxLines, maxLength, showTrimData)}
            {cellValueFormat != null && isTrimRequired(cellValue, maxLines, maxLength)  && renderShowMoreLessButton()}
        </div>
        <div className="data-table-cell-cls exclude-screen">
            {cellValueFormat != null &&  getCellValue(cellValueFormat, maxLines, maxLength, !showTrimData)}
        </div>
        </>
    )
    ;
};

DataTableCell.propTypes = {
    cellValue: PropTypes.string,
    maxLength: PropTypes.number,
    maxLines: PropTypes.number
};
