import React from "react";
import PropTypes from "prop-types";
import './ViewComment.scss';
import '../AvatarInitials/AvatarInitials.scss';
import './ContributorComment.scss';
import AvatarInitials from "../AvatarInitials/AvatarInitials";
import moment from "moment";


const ContributorComment = ({comment, isAvatarInitials , index, isNoComment}) => {
  return (
    <div className={`contributo-comments bg${isNoComment}`}  key={`contrib-comments-${index}`}>
    <h6>{comment.title}</h6>
     {comment.comment.map((com , i) => (
       <div key={`comment-card-${i}`}> 
         <div className="comment-card" >
           {isAvatarInitials && <div className="avatar-icon">
             <AvatarInitials class="avatar-icon" email='M.P E@airbus.com' />
           </div>}
           <div className={`comment-desc  comment-desc${isNoComment}`}>
             <span className="header-content">{com.comment || com.reason} </span>
             <span className={`comment-date${isNoComment}`}>{com.createdAt && moment(com.createdAt).format('MMMM DD YYYY, HH:mm:ss')} </span>
           </div>
         </div>

       </div>
      )
     )}
  </div>
  );
};

ContributorComment.defaultProps = {
    comment: {},
    isAvatarInitials : false,
    isNoComment : ''
};
ContributorComment.propTypes = {
    comment: PropTypes.object,
    isAvatarInitials: PropTypes.bool,
    index: PropTypes.number,
    isNoComment : PropTypes.string
};

export default ContributorComment;
