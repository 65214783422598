import { getEditsFromLocalStorage } from "../../utils/localStorageHelper";
import * as defaultStyle from "../../config/table.styles";
import { cloneDeep } from "lodash";
import reportTexts from "../../locales/reportTexts.json";
/**
 * prepare table data
 * @param  {Object} response
 * @param  {Objeck} details
 */
export const prepareData = (response, details) => {
  const { pivot } = details;
  if (!pivot) {
    return {
      columns: reportTexts.hideDisableData.includes(response.dataset)
        ? response.columns.filter((data) => data !== "isDisabled")
        : response.columns,
      data: response.data,
    };
  } else {
    const { row, column, fact } = pivot;
    const pivotData = response.data.reduce((acc, item) => {
      const tgtItem = acc.find((accItem) => accItem[row] === item[row]);
      if (tgtItem) {
        tgtItem[item[column]] = tgtItem[item[column]]
          ? Number(tgtItem[item[column]]) + Number(item[fact])
          : item[fact];
      } else {
        acc.push({ [row]: item[row], [item[column]]: item[fact] });
      }
      return acc;
    }, []);
    return {
      columns: pivotData.length ? Object.keys(pivotData[0]) : [],
      data: pivotData,
    };
  }
};
/**
 * Gets the label for the table with the input raw coulumn value
 * @param  {string} column
 * @param  {Array} labels
 */
export const getLabel = (column, labels) => {
  const item = labels && labels.find((label) => label.key === column);
  const result = item ? item.label : column;
  return result;
};

export const getStyle = (column, tableStyles, type) => {
  const styles = column ? {} : defaultStyle.index;
  let customStyles = tableStyles.custom && tableStyles.custom[column];
  customStyles = customStyles || {};
  const commonStyles = tableStyles[type]
    ? tableStyles[type]
    : defaultStyle[type];
  return { ...styles, ...commonStyles, ...customStyles };
};
/**
 * This function gets the data from local storage and prepare it for interval proposal table
 * @param  {Object} data
 * @param  {String} report_key
 * @param  {String} section_key
 * @param  {Array} allowedEdits
 */
export const formatDataWithLS = (
  data,
  report_key,
  section_key,
  allowedEdits
) => {
  const storageData = getEditsFromLocalStorage(report_key);
  const storageSectionData = storageData[section_key];
  const exludableKeys = [
    "Remarks",
    "isSampleIntervalEnabled",
    "isSampleThresholdEnabled",
  ];

  const storageSectionDataKeys = Object.keys(storageSectionData).filter(
    (key) => !exludableKeys.includes(key)
  );

  if (data.length && data[0].name) {
    const existingNames = data.map((item) => item.name);
    const addedNames = storageSectionDataKeys.filter(
      (name) => !existingNames.includes(name)
    );
    if (addedNames.length) {
      const { basicIPObj } = getBasicIPdata(allowedEdits);
      const addedDataObj = objValueMapper(addedNames, basicIPObj);
      data = cloneDeep([...data, ...addedDataObj]);
    }
  }
  // the below code formats the data with corner cases if Interval propsal
  const formattedData = data.reduce((acc, rowObj) => {
    const name = rowObj.name ? rowObj.name : "Interval";
    if (storageSectionDataKeys.includes(name)) {
      acc.push({ ...rowObj, ...storageSectionData[name] });
    } else if (name === "Sample Interval" || name === "Sample Threshold") {
      if (
        storageSectionData.isSampleIntervalEnabled &&
        name === "Sample Interval"
      ) {
        acc.push({ ...rowObj });
      }
      if (
        storageSectionData.isSampleThresholdEnabled &&
        name === "Sample Threshold"
      ) {
        acc.push({ ...rowObj });
      }
    } else {
      acc.push({ ...rowObj });
    }
    return acc;
  }, []);

  if (formattedData.length && storageSectionData.Remarks) {
    formattedData[0].remarks = storageSectionData["Remarks"];
  }

  return formattedData;
};

export const formatDataWithLSEdit = (data, report_key, section_key) => {
  const storageData = getEditsFromLocalStorage(report_key);
  const storageSectionData = storageData[section_key];
  return storageSectionData;
};

/**
 * Returns the basic Interval Propsal Object used for structure template
 * @param  {Array} allowedEdits
 */
export const getBasicIPdata = (allowedEdits) => {
  const IPcolumns = allowedEdits[0] && allowedEdits[0].columns;
  const IPvalues = IPcolumns[0].values ? IPcolumns[0].values : [];
  const basicIPObj = IPcolumns.reduce((acc, col) => {
    acc[col["name"]] = "";
    return acc;
  }, {});
  return { IPcolumns: IPcolumns, IPvalues: IPvalues, basicIPObj: basicIPObj };
};
/**
 * Formats the data to work with interval propsal editable box as input
 * data structure is different
 * @param  {Array} allowedEdits
 * @param  {Object} data
 */
export const formatIPdata = (allowedEdits, data) => {
  const { IPvalues, basicIPObj } = getBasicIPdata(allowedEdits);
  let formattedEditables = [];
  if (data.length && data[0].name && IPvalues.length) {
    const existingNames = data.map((item) => item.name);
    const missingNames = IPvalues.filter(
      (value) => !existingNames.includes(value)
    );
    const missingDataObj = objValueMapper(missingNames, basicIPObj);
    formattedEditables = [...data, ...missingDataObj];
  } else if (data.length) {
    formattedEditables = data;
  } else {
    formattedEditables = IPvalues.length
      ? objValueMapper(IPvalues, basicIPObj)
      : [basicIPObj];
  }
  return formattedEditables;
};
/**
 * returns array of object with provided values to base object
 * @param  {Array} values
 * @param  {Object} baseObj
 */
export const objValueMapper = (values, baseObj) => {
  return values.map((value) => {
    baseObj["name"] = value;
    return cloneDeep(baseObj);
  });
};

/**
 * below function checks if table row contains is disabled, if yes removes it
 * So the isdisabled value is not seen in the Table UI
 * @param {Table data} updatedData
 * @returns
 */
export const removeIsDisabled = (updatedData) => {
  return updatedData.length
    ? updatedData.map((data) => {
        if (Object.keys(data).includes("isDisabled")) {
          delete data["isDisabled"];
          return data;
        } else {
          return data;
        }
      })
    : updatedData;
};
// function to Sort formatted data by revision number.
export const sortData = (data, title) => {
  return data.sort((a, b) => {
    if (title === "Record of Revision") {
      return a.revision - b.revision;
    } 
    return 0;
  });
};
