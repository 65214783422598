import { Pie, Line, Bar, Doughnut, Radar, Polar, Scatter, Bubble, HorizontalBar } from 'react-chartjs-2';
import * as charts from './chart.type'

export const getChartComponent = (chartType) => {
    const COMPONENT_MAP = {
      [charts.PIE]: Pie,
      [charts.LINE]: Line,
      [charts.BAR]: Bar,
      [charts.DOUGHNUT]: Doughnut,
      [charts.RADAR]: Radar,
      [charts.POLAR]: Polar,
      [charts.BUBBLE]: Bubble,
      [charts.SCATTER]: Scatter,
      [charts.MIX]: Bar,
      [charts.HORIZONTALBAR]: HorizontalBar
    };
    return COMPONENT_MAP[chartType] ? COMPONENT_MAP[chartType] : ('');
  }