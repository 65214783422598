import React, { useEffect, memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Banner } from "@airbus/components-react";
import Loader from '../Loader/Loader';
import { apiDownloadPDF } from "../../models/reportModel/reportApi";

import './PDFRenderer.scss';

const PDFRenderer = () => {
  const { reportName } = useParams();
  const dispatch = useDispatch();
  const pdfURL = useSelector(store =>  store.reports.pdfURL);
  const pdfError = useSelector(store => store.reports.pdfError);
  const timers = useMemo(() => {
    return {};
  }, []);


  const notificationBanner = (type, message) => {
    return (
      <div className="notification-cls exclude-print">
        <Banner variant={type}>{message}</Banner>
      </div>
    );
  };
  useEffect(() => {
    reportName && dispatch(apiDownloadPDF(reportName));
    return ()=>{
      Object.keys(timers).forEach((timer) => clearTimeout(timer));
    }
  }, [dispatch, reportName, timers ]);
  return pdfURL ? 
    <div className="pdf-renderer-cls">
      <iframe src={pdfURL} width="100%" height="100%" type="application/pdf" />
    </div>
   : pdfError ? notificationBanner("error", pdfError.toString()) : <Loader />;
}

export default memo(PDFRenderer); 