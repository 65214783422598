import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {cloneDeep} from "lodash";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@airbus/components-react";
import "./SectionSelector.scss";
import reportTexts from "../../locales/reportTexts.json";
import NoData from "../NoData/NoData";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import {updateUserData} from "../../models/userDataModel/userDataAPI";
import {Warning} from "@airbus/icons/react";

const SectionSelector = (props) => {
  const {
    showSelector,
    handleClose,
    userData,
    sources,
    reportName,
    selectorData
  } = props;

  const hiddenSections =
    userData.sectionHidden && Object.keys(userData.sectionHidden).length
      ? { Dashboards: [], "Technical Evaluation": [], "Primary Interval": [], "Secondary Interval": [], "Ternary Interval": [], ...cloneDeep(userData.sectionHidden) }
      : { Dashboards: [], "Technical Evaluation": [], "Primary Interval": [], "Secondary Interval": [], "Ternary Interval": [] };
  const [disabledSections, setDisabledSections] = useState({
    ...hiddenSections,
  });
  const [canValidate, setCanValidate] = useState(false);
  const dispatch = useDispatch();

  const { data, error } = selectorData;
  const selectorTableData = useMemo(() => {
    return data && data.length && Object.keys(data[0]).length ? data[0] : [];
  }, [data])

  const soeeGrouping = selectorTableData?.cardinality === "n"

  const dashboardsSelector = selectorTableData.dashboardsSelector
    ? JSON.parse(selectorTableData.dashboardsSelector)
    : [];
  const teSelector = selectorTableData.teSelector
    ? JSON.parse(selectorTableData.teSelector)
    : [];
  const primaryInterval = selectorTableData.primaryInterval
    ? Array(teSelector.length).fill(selectorTableData.primaryInterval)
    : Array(teSelector.length).fill(null);
  const secondaryInterval = selectorTableData.secondaryInterval
    ? Array(teSelector.length).fill(selectorTableData.secondaryInterval)
    : Array(teSelector.length).fill(null);
  const ternaryInterval = selectorTableData.ternaryInterval
    ? Array(teSelector.length).fill(selectorTableData.ternaryInterval)
    : Array(teSelector.length).fill(null);

  const dashboardSelMap =
    dashboardsSelector.length && mapSelectorData(dashboardsSelector);
  const teSelMap = teSelector.length && mapSelectorData(teSelector);

  const rowInfo = teSelector.length
    ? teSelector.map((item) => item.split("#")[0])
    : [];


  const checkValidate = useMemo(() => {
    const mpdArray = Object.values(dashboardSelMap) //array of mpd for current dossier (e.g. ["dossier", "mpd_292000-02-1", "mpd_292000-05-1"])

    return mpdArray.every((mpd) => {
      const intervalsNamesRaw = [
        ['Primary Interval', 'primaryInterval'],
        ['Secondary Interval', 'secondaryInterval'],
        ['Ternary Interval', 'ternaryInterval']
      ];
      const intervalsNames = soeeGrouping ? intervalsNamesRaw.filter(elt => elt.includes("primaryInterval")) : intervalsNamesRaw

      const {Dashboards} = disabledSections // array of mpd NOT selected
      // e.g. when nothing is selected ["Overall / MRB Level#dossier", "mpd_292000-05-1", "dossier", "mpd_292000-02-1"]

      const isDashboardIncludesValue = Dashboards.includes(mpd) // if mpd is included (i.e. not selected) return true
      const checkIntervalNameDisableStatus = ([intervalName, intervalSelector]) => {
        const unselectedMpd = disabledSections[intervalName]
        const intervalSelectorValue = selectorTableData[intervalSelector]
        const isDisabled = unselectedMpd.includes(mpd) //if included return true
        const isNullOrNA = ["N/A", null].includes(intervalSelectorValue); // if contain null or N/A return true

        return !isDisabled && !isNullOrNA
      }
      return isDashboardIncludesValue
          || intervalsNames.some((intervalsArray) => checkIntervalNameDisableStatus(intervalsArray));
    });
  },[dashboardSelMap, disabledSections, selectorTableData, soeeGrouping])

  useEffect(() => {
    setCanValidate(checkValidate);
  }, [checkValidate]);

  const handleCheckbox = (e, checked) => {
    const [postfix, section] =
      e.currentTarget.attributes["data-context"].value.split("+");
      let mrb_mpd;
      switch (section) {
          case 'Dashboards':
            mrb_mpd = dashboardSelMap[postfix];
            break;
          case 'Technical Evaluation':
            mrb_mpd = teSelMap[postfix];
            break;
          case 'Primary Interval':
            mrb_mpd = dashboardSelMap[postfix];
            break;
          case 'Secondary Interval':
            mrb_mpd = dashboardSelMap[postfix];
            break;
          case 'Ternary Interval':
            mrb_mpd = dashboardSelMap[postfix];
            break;
      }

    if (!checked) {
      disabledSections[section].push(mrb_mpd);
    } else {
      const index = disabledSections[section].indexOf(mrb_mpd);
      disabledSections[section].splice(index, 1);
    }
    setDisabledSections({ ...disabledSections });
  };

  const handleValidate = () => {
    // eslint-disable-next-line no-unused-vars
    const {user, ...newUserData } = userData;
    let updatedUserData = { ...newUserData, sectionHidden: disabledSections };
    if(soeeGrouping){
      const mpdArray = Object.values(dashboardSelMap)
      const newDisabledSections = {... disabledSections,
        "Secondary Interval" : mpdArray,
        "Ternary Interval": mpdArray}
      updatedUserData = {...updatedUserData, sectionHidden: newDisabledSections}
    }
    dispatch(
      updateUserData({
        reportName,
        componentName: "SectionSelector",
        userData: updatedUserData,
      })
    );
    handleClose();
  };
  const renderSelectorTitles = () => {
    return reportTexts.SelectorTitles.map((item, index) => {
      return (
        <TableCell head key={index}>
          <p style={{width: 100}}>
            {item}
          </p>
        </TableCell>
      );
    });
  };

  const renderSelectorBody = () => {
    return rowInfo.map((rowData, index) => {
      return (
        <TableRow key={index}>
          <TableCell key={`${index}_title`}>{rowData}</TableCell>
          <TableCell key={`${index}_dashboard`}>
            <Checkbox
              defaultChecked={
                !hiddenSections["Dashboards"].includes(dashboardSelMap[rowData]) &&
                (selectorTableData.cardinality !== "n_1" || index === 0)
              }
              className="checkbox-btn-dash"
              disabled={selectorTableData.cardinality === "n_1"}
              key={`${rowData}_dashboard`}
              data-context={`${rowData}+Dashboards`}
              onChange={handleCheckbox}
            />
          </TableCell>
          <TableCell key={`${index}_te`}>
            <Checkbox
              defaultChecked={
                !hiddenSections["Technical Evaluation"].includes(
                  teSelMap[rowData]
                )
              }
              className="checkbox-btn-te"
              key={`${rowData}_te`}
              data-context={`${rowData}+Technical Evaluation`}
              onChange={handleCheckbox}
            />
          </TableCell>
          <TableCell key={`${index}_pi`}>
            {(primaryInterval[index] !== null && primaryInterval[index] !== "N/A") &&
              <div style={{display: 'flex', width:'70%'}}>
                <Checkbox
                  defaultChecked={
                    !hiddenSections["Primary Interval"].includes(dashboardSelMap[rowData]) &&
                    (selectorTableData.cardinality !== "n_1" || index === 0)
                  }
                  className="checkbox-btn-pi"
                  key={`${rowData}_pi`}
                  data-context={`${rowData}+Primary Interval`}
                  disabled={
                    disabledSections["Dashboards"].includes(dashboardSelMap[rowData]) ||
                    (selectorTableData.cardinality === "n_1" && index !== 0)
                  }
                  onChange={handleCheckbox}
                />
                <Typography variant="xsmall">{primaryInterval[index]}</Typography>
              </div>
            }
          </TableCell>
          <TableCell key={`${index}_si`}>
            {(secondaryInterval[index] !== null && secondaryInterval[index] !== "N/A") &&
              <div style={{display: 'flex'}}>
                <Checkbox
                  defaultChecked={
                    !hiddenSections["Secondary Interval"].includes(dashboardSelMap[rowData]) &&
                    (selectorTableData.cardinality !== "n_1" || index === 0) && !soeeGrouping
                  }
                  className="checkbox-btn-si"
                  key={`${rowData}_si`}
                  data-context={`${rowData}+Secondary Interval`}
                  disabled={
                    disabledSections["Dashboards"].includes(dashboardSelMap[rowData]) ||
                    (selectorTableData.cardinality === "n_1" && index !== 0) || soeeGrouping
                  }
                  onChange={handleCheckbox}
                />
                <Typography variant="xsmall">{secondaryInterval[index]}</Typography>
              </div>
            }
          </TableCell>
          <TableCell key={`${index}_ti`}>
            {(ternaryInterval[index] !== null && ternaryInterval[index] !== "N/A") &&
              <div style={{display: 'flex'}}>
                <Checkbox
                  defaultChecked={
                    !hiddenSections["Ternary Interval"].includes(dashboardSelMap[rowData]) &&
                    (selectorTableData.cardinality !== "n_1" || index === 0) && !soeeGrouping
                  }
                  className="checkbox-btn-ti"
                  key={`${rowData}_ti`}
                  data-context={`${rowData}+Ternary Interval`}
                  disabled={
                    disabledSections["Dashboards"].includes(dashboardSelMap[rowData]) ||
                    (selectorTableData.cardinality === "n_1" && index !== 0) || soeeGrouping
                  }
                  onChange={handleCheckbox}
                />
                <Typography variant="xsmall">{ternaryInterval[index]}</Typography>
              </div>
            }
          </TableCell>
        </TableRow>
      );
    });
  };

  const renderSelectorTable = () => {
    return data.length ? (
      teSelector.length ? (
        <Table>
          <TableHead>
            <TableRow>{renderSelectorTitles()}</TableRow>
          </TableHead>
          <TableBody>{renderSelectorBody()}</TableBody>
        </Table>
      ) : (
        <NoData sources={sources} />
      )
    ) : (
      <div style={{ marginLeft: "50%" }}>
        <Spinner size="small" label="Loading..." />
      </div>
    );
  };

  return !error ? (
    <Dialog
      open={showSelector}
      onClose={handleClose}
      className="dialog-main selector-cls"
      disableBackdropClick
      disableEscapeKeyDown
      scroll="dialog"
    >
      <DialogHeader className="dialog-header">
        {reportTexts.SectionSelectorTitle}
      </DialogHeader>
      <DialogContent>{renderSelectorTable()}</DialogContent>
      <DialogActions className="dialog-action">
        <Button
          variant="secondary"
          className="cancel-btn"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="validate-btn"
          onClick={handleValidate}
          disabled={!canValidate}
        >
          Validate
        </Button>
        {!canValidate && <Tooltip placement="bottom" label="Please select at least 1 interval for each selected Dashboard/Graphs">
          <Warning style={{color: "red"}}/>
        </Tooltip>}
      </DialogActions>
    </Dialog>
  ) : (
    <ErrorHandler error={error} />
  );
};
const mapSelectorData = (data) => {
  return data.reduce((acc, item) => {
    const [key, val] = item.split("#");
    acc[key] = val;
    return acc;
  }, {});
};
SectionSelector.propTypes = {
  showSelector: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
  sources: PropTypes.array.isRequired,
  selectorData: PropTypes.object.isRequired,
};
export default SectionSelector;
