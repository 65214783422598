import React from "react";
import {
  Button, Dialog, DialogContent, DialogActions, DialogHeader
} from "@airbus/components-react";
import PropTypes from 'prop-types';
import "./DailogBox.scss";

const DailogBox = (props) => {
  const { isOpen, onClose, title, body, buttonName, onConfirm } = props;
  return (
    <React.Fragment>
      <Dialog open={isOpen} onClose={onClose} className='dialog-box'>
        <DialogHeader className='title'>
          <strong>{title}</strong>
        </DialogHeader>
        <DialogContent>
          <p>
            {body}
          </p>
        </DialogContent>
        <DialogActions>
          <Button className="cancel-btn" variant="secondary" onClick={onClose}>
            No
          </Button>
          <Button className="confirm-btn" variant="primary" onClick={onConfirm}>
            {buttonName}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


DailogBox.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
};

export default DailogBox;