import { LIST_STATUS_SUCCESS } from "./dossierStatusActionType";
import { STATUS } from "../../config/dossier.status";
import moment from "moment";


// intitializes state of the reducer
const initializeState = () => Object.keys(STATUS).reduce((acc, status) => {
  acc[status] = [];
  return acc;
}, {});

// inverts status object
const invertStatus = () => Object.keys(STATUS).reduce((acc, status) => {
  acc[STATUS[status]] = status;
  return acc;
}, {});

// gets exxercise name from exercise name key
const getExerciseName = (exerciseNameKey) => {
  return exerciseNameKey.substring(0, exerciseNameKey.length - 11)
}
//method to form the duration in current status
const parseEvents = (item) => {
  const { events, status } = item;
  if (!events) {
    return {};
  }
  let lastEventDate, stats;
  for (let i = events.length - 1; i >= 0; i--) {
    if (events[i].stats && status === STATUS.DELIVERED ) {
      stats = events[i].stats;
    }
    if (events[i].status === status) {
      lastEventDate = events[i].lastModified;
    } else {
      break;
    }
  }
  const today = moment().startOf("day");
  const formattedDate = lastEventDate ? moment(lastEventDate).startOf("day") : today;
  return {
    duration: Math.round(moment.duration(today - formattedDate).asDays()),
    stats
  };
};

// reduce list based on status
const reduceListStatusSuccess = (state, action) => { // eslint-disable-line no-unused-vars
  const invertedStatusMap = invertStatus();
  return action.payload.reduce((acc, item) => {
    const statusKey = decodeURIComponent(item.status);
    if (invertedStatusMap[statusKey]) {
      const { exerciseNameKey } = item;
      acc[invertedStatusMap[statusKey]].push({
        ...item,
        ...parseEvents(item),
        exerciseName: getExerciseName(exerciseNameKey),
        searchTags: formSearchTags(item)
      });
    }
    return acc;
  }, initializeState());
};

// search tags added to search based on the tags
const formSearchTags = (dossier) => {
  const { exerciseNameKey, modifiedBy, createdBy, dossierType, exerciseType } = dossier;
  const searchTags = getExerciseName(exerciseNameKey).toLowerCase().split('-');
  modifiedBy && searchTags.push(modifiedBy.split('@')[0]);
  createdBy && searchTags.push(createdBy.split('@')[0]);
  dossierType && searchTags.push(dossierType);
  exerciseType && searchTags.push(exerciseType);
  return searchTags;
}

// main reducer
const dossierStatusListReducer = (state = {}, action) => {
  if (REDUCER_MAP[action.type]) {
    return REDUCER_MAP[action.type](state, action);
  } else {
    return state;
  }
};

const REDUCER_MAP = {
  [LIST_STATUS_SUCCESS] : reduceListStatusSuccess
};

export default dossierStatusListReducer;
