import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Auth, Hub } from 'aws-amplify';
import { Route, Redirect,useLocation } from 'react-router-dom';
import { Logger } from 'aws-amplify';
import Loader from '../Loader/Loader';

const logger = new Logger('PrivateRoute');

const PrivateRoute = ({ component: C, props: cProps, ...rest }) => {
  const { code } = queryString.parse(window.location.search);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() =>{
    if(!isAuthenticated && location.pathname !=="/"){
      localStorage.setItem('url',location.pathname);
    }
  });
  Hub.listen('auth', data => {
    if (code) {
      // the user is redirected from onelogin
      const { payload } = data;
      logger.info(`A new auth event has happened: ${payload.event}`);
      switch (payload.event) {
        case 'signOut':
          logger.info('Signed out');
          setIsAuthenticated(false);
          break;
        case 'signIn':
          logger.info('Logged in as:', payload.data.username);
          setIsAuthenticated(!!payload.data.username);
          break;
        default:
          logger.info('Unhandled event:', payload.event);
          break;
      }
    }
  });

  if (!code) {
    // the users comes directly to signin
    Auth.currentAuthenticatedUser()
      .then(user => {
        setIsAuthenticated(!!user.username);
      })
      .catch(() => {
        setIsAuthenticated(false);
      });
  }

  useEffect(() => {
    if (isAuthenticated !== undefined) {
      setLoading(false);
    }
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
          <Route
            {...rest}
            render={props => (isAuthenticated ? <C {...props} {...cProps} /> : <Redirect to="/signin" />)}
          />
        )}
    </React.Fragment>
  );
};

export default PrivateRoute;
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  props: PropTypes.object,
  exact: PropTypes.bool,
};
