export const GET_USERDATA_SUCCESS = 'GET_USERDATA_SUCCESS'
export const GET_USERDATA_FAILURE = 'GET_USERDATA_FAILURE'

export const UPDATE_USERDATA_SUCCESS = 'UPDATE_USERDATA_SUCCESS'
export const UPDATE_USERDATA_FAILURE = 'UPDATE_USERDATA_FAILURE'

export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE'

export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const GET_USERINFO_SUCCESS = 'GET_USERINFO_SUCCESS';
export const GET_USERINFO_FAILURE = 'GET_USERINFO_FAILURE';

export const GET_IMAGEB64_SUCCESS = 'GET_IMAGEB64_SUCCESS';
export const GET_IMAGEB64_FAILURE = 'GET_IMAGEB64_FAILURE';

export const GET_USER_PERMISSION_SUCCESS = 'GET_USER_PERMISSION_SUCCESS'
export const GET_USER_PERMISSION_FAILURE = 'GET_USER_PERMISSION_FAILURE'