import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Card,
  CardHeader,
  CardContent,
  Chip,
  Tooltip,
  IconButton,
  Collapsible,
} from "@airbus/components-react";
import { ArrowUpward,ArrowDownward,CompareArrows, Launch } from "@airbus/icons/react";
import AvatarInitials from "../AvatarInitials/AvatarInitials";

import "./DossierCards.scss";

const sortArray = ["Fleet FR", "ME", "Current Interval", "Proposed Interval"];

const handleClick = (dossier) => {
  const { exerciseType, dossierType, dossierKey } = dossier;
  const link = `${window.location.origin}/ReportHandler/mpo_${dossierType}_${exerciseType}.json/${dossierKey}`;
  window.open(link, "_blank");
};

const renderStats = (stats) => {
  return (
    stats &&
    Object.keys(stats).map((key) => (
      <div key={key}>
        <Collapsible
          className="dossier-stats-header"
          title={
            <div>
              <Tooltip title={stats[key].remarks || "No Remarks Mentioned"}>
                <span className="header-content">
                  {stats[key].intervalIndicator === "up" ? (
                    <ArrowUpward className="green icon" />
                  ) : stats[key].intervalIndicator === "down" ? (
                    <ArrowDownward className="red icon" />
                  ) : (
                    <CompareArrows />
                  )}
                </span>
              </Tooltip>
              <span className="header-content">{key}</span>
            </div>
          }
        >
          <div className="dossier-stats">
            {Object.keys(stats[key].data)
              .sort((a, b) => sortArray.indexOf(a) - sortArray.indexOf(b))
              .map((dKey) => (
                <div key={dKey} className="dossier-stats-data">
                  <span>{dKey}</span>
                  <span>{stats[key].data[dKey]}</span>
                </div>
              ))}
          </div>
        </Collapsible>
      </div>
    ))
  );
};

const DossierCards = ({ dossiers }) => {
  return dossiers.map((dossier) => (
    <Card
      className="dossier-cards-cls custom-padding"
      key={dossier.dossierKey}
      elevation={2}
    >
      <CardHeader
        className="card-header"
        action={
          <IconButton
            onClick={() => handleClick(dossier)}
            variant="ghost"
            size="small"
            className="launch-dossier"
          >
            <Launch />
          </IconButton>
        }
        icon={<AvatarInitials email={dossier.modifiedBy} />}
        title={<span className="header-content">{`${dossier.dossierType} ${dossier.exerciseType}`}</span>}
      />
      <CardContent className="card-content">
        <div className="card-main">
          <Chip
            className={dossier.duration >= 10 ? "red" : "green"}
            label={`${dossier.duration} days`}
          />
          <p>{dossier.exerciseName}</p>
          {renderStats(dossier.stats)}
        </div>
        <div className="card-footer">
          <span>Created {moment(dossier.createdAt).fromNow()}</span>
        </div>
      </CardContent>
    </Card>
  ));
};

DossierCards.defaultProps = {
  dossier: {},
};
DossierCards.propTypes = {
  dossiers: PropTypes.arrayOf(
    PropTypes.shape({
      dossierKey: PropTypes.string,
      dossierType: PropTypes.string,
      exerciseType: PropTypes.string,
      modifiedBy: PropTypes.string,
      lastModified: PropTypes.string,
      exerciseName: PropTypes.string,
      duration: PropTypes.number,
    })
  ),
  statusKey: PropTypes.string,
};

export default DossierCards;
