import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Autorenew, ErrorOutline } from "@airbus/icons/react";
import PropTypes from "prop-types";
import { getSyncStatus } from "../../models/syncStatusModel/syncStatusApi";
import { DERIVED_SYNC_STATUS } from "../../config/dossier.status.js";
import { ProgressBar } from "@airbus/components-react";



const ProgressBarStatusMap = {
  "INIT": 0,
  "SYNC" : 30,
  "READY" : 65,
  "ZIPPED" : 100,
}

const renderSyncProgress = (syncStatusData) => {
  const isSyncing = syncStatusData.status === DERIVED_SYNC_STATUS.IN_PROGRESS;
  return (
    <div>
    <span className="syncStatus">
      {isSyncing ? (
        <Autorenew className={"rotate"} />
      ) : (
        <ErrorOutline />
      )}
      <span>{syncStatusData.msg}</span> 
      
    </span>
    {isSyncing && <div ><ProgressBar className='progress-bar' striped status="success" showPercentage value={ProgressBarStatusMap[syncStatusData.progress]} /> </div>}
    </div>
  );
};

const SyncStatus = (props) => {
  const [wasSyncing, setWasSyncing] = useState(false);
  const SYNC_POLL_INTERVAL = 30000;
  const syncStatusData = useSelector(
    (store) => store.syncNotification.sync_info
  );
  const dossierKey = useSelector((store) => store.reports.selectedReport);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSyncStatus(dossierKey));
  }, [dispatch, dossierKey]);

  useEffect(() => {
    const syncInProgress =
      syncStatusData &&
      syncStatusData.status === DERIVED_SYNC_STATUS.IN_PROGRESS;
    let syncStatusTimeout;
    if (syncInProgress) {
      setWasSyncing(true);
      syncStatusTimeout = setTimeout(() => {
        dispatch(getSyncStatus(dossierKey));
      }, SYNC_POLL_INTERVAL);
    }
    return () => {
      clearTimeout(syncStatusTimeout);
    };
  }, [syncStatusData, dossierKey, dispatch]);

  const syncIncomplete = syncStatusData && syncStatusData.status !== DERIVED_SYNC_STATUS.SUCCESS;

  wasSyncing && !syncIncomplete && window.location.reload();

  return (
    <span>
      {!syncIncomplete && props.desc}
      {syncIncomplete && renderSyncProgress(syncStatusData)}
    </span>
  );
};

export default SyncStatus;

SyncStatus.propTypes = {
  desc: PropTypes.string.isRequired,
};
