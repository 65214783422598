import {
    MSG_SHOULD_SHOWON_BANNER,
    ROLES,
    STATUS
} from "../../config/dossier.status";
import { GET_ACCESS_RESTRICTION } from "./accessRestrictionActionType";

const initialState = {
    smiedEditable: {}
};
const accessRestrictionReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_ACCESS_RESTRICTION:
            {
                return reduceAccessRestrictions(state, action);
            }
        default: {
            return {
                ...state,
            };
        }
    }
}

/**
 *
 * @param {*} dossierStatus
 * @param {*} user
 * @returns
 */
const computeAccessResult = (dossierStatus, user) => {
    const accessResult = new Array(12).fill(false);
    const ACCESS_INDEX_MAP = {
        isSlidingBoxVisible : 0, isEditable : 1,
        isMPEIconVisible : 2, isDossierInChairmanReview : 3,
        isDossierChairmanReviewDone : 4, isDossierWithChairmanCommentsInReview : 5,
        canChairmanReview : 6, canReviewOrDownload : 7 , isMPEFeedbackReceived : 8 , canSubmitToReview : 9 , rollBackIconVisible : 10 , tooltip : 11, canValidatorReview: 12
    }
   // dossierStatus.mpeCommentsStatus = 'mpe review done without comments';
    accessResult[ACCESS_INDEX_MAP.tooltip] = '';

    const MPEFeedbackReceived = MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITHOUT_COMMENT === dossierStatus.mpeCommentsStatus
                                || MSG_SHOULD_SHOWON_BANNER.SUBMIT_WITH_COMMENT ===  dossierStatus.mpeCommentsStatus


    const STATUS_TO_RULE_MAP = {
        [STATUS.TECHNICAL_EVALUATION]: (dossierStatus) => {
            const comments = (dossierStatus.comments
                                 && dossierStatus.comments.length > 0 ) ||
                             (dossierStatus.mpeComments
                                 && dossierStatus.mpeComments.length > 0);
            accessResult[ACCESS_INDEX_MAP.isSlidingBoxVisible] = comments && ROLES.onlySpecialist.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isMPEIconVisible] = true;
            accessResult[ACCESS_INDEX_MAP.isDossierChairmanReviewDone] = ((dossierStatus.mpeComments
                                                                            && dossierStatus.mpeComments.length > 0)
                                                                            || MPEFeedbackReceived);
            accessResult[ACCESS_INDEX_MAP.isMPEFeedbackReceived] = MPEFeedbackReceived;
            accessResult[ACCESS_INDEX_MAP.canSubmitToReview] = ROLES.onlySpecialist.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.tooltip] = ROLES.onlySpecialist.indexOf(user.role) > -1 ? 'Submit for comments' : 'Technical Evaluation in progress';
        },
        [STATUS.REVIEW]: (_dossierStatus, user) => {
            accessResult[ACCESS_INDEX_MAP.isSlidingBoxVisible] =  ROLES.onlyValidator.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isEditable] = ROLES.onlyValidator.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isDossierWithChairmanCommentsInReview] = dossierStatus.mpeComments
                                                                                      && dossierStatus.mpeComments.length > 0;
            accessResult[ACCESS_INDEX_MAP.canReviewOrDownload] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isMPEIconVisible] = true && MPEFeedbackReceived;
            accessResult[ACCESS_INDEX_MAP.isMPEFeedbackReceived]  = MPEFeedbackReceived;
            accessResult[ACCESS_INDEX_MAP.tooltip] = 'Acceptance/Validation in progress';
            accessResult[ACCESS_INDEX_MAP.canValidatorReview] = ROLES.onlyValidator.indexOf(user.role) > -1;
        },
        [STATUS.ACCEPTED]: (dossierStatus) => {
            accessResult[ACCESS_INDEX_MAP.isSlidingBoxVisible] =  ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isEditable] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isDossierWithChairmanCommentsInReview] = dossierStatus.mpeComments
                                                                                      && dossierStatus.mpeComments.length > 0;
            accessResult[ACCESS_INDEX_MAP.canReviewOrDownload] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isMPEIconVisible] = true && MPEFeedbackReceived;
            accessResult[ACCESS_INDEX_MAP.isMPEFeedbackReceived] = MPEFeedbackReceived;
        },
        [STATUS.DELIVERED]: (_dossierStatus, user) => {
            accessResult[ACCESS_INDEX_MAP.isSlidingBoxVisible] =  ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isEditable] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isDossierWithChairmanCommentsInReview] = dossierStatus.mpeComments
                                                                                     && dossierStatus.mpeComments.length > 0;
            accessResult[ACCESS_INDEX_MAP.canReviewOrDownload] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isMPEIconVisible] = true && MPEFeedbackReceived;
            accessResult[ACCESS_INDEX_MAP.isMPEFeedbackReceived] = MPEFeedbackReceived;
        },
        [STATUS.MPE_REVIEW]: (_dossierStatus, user) => {
            accessResult[ACCESS_INDEX_MAP.isSlidingBoxVisible] =  ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isEditable] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isDossierInChairmanReview] = true;
            accessResult[ACCESS_INDEX_MAP.canChairmanReview] = ROLES.onlyChairman.indexOf(user.role) > -1;
            accessResult[ACCESS_INDEX_MAP.isMPEIconVisible] = true;
            accessResult[ACCESS_INDEX_MAP.isMPEFeedbackReceived]  = false;
            accessResult[ACCESS_INDEX_MAP.tooltip] = 'Comments in progress';
            accessResult[ACCESS_INDEX_MAP.rollBackIconVisible] = ROLES.onlySpecialist.indexOf(user.role) > -1 ? true : false;
        }
    };
    STATUS_TO_RULE_MAP[dossierStatus.status] && STATUS_TO_RULE_MAP[dossierStatus.status](dossierStatus, user);
    return  accessResult;
}

/**
 * Method will compute wheater user has acess
 * to the slider and he allowd to editable
 * @param {*} state
 * @param {*} action
 * @returns
 */
const reduceAccessRestrictions = (state, action) => {

    const user = action.payload ? action.payload.user : {};
    const dossierStatus = action.payload ? action.payload.dossierStatus : {};
    const isRejectionCommentsPresent = dossierStatus.comments && dossierStatus.comments.length > 0;
    const isChairmanCommentsPresent = dossierStatus.mpeComments && dossierStatus.mpeComments.length > 0;
    const [
        isSlidingBoxVisible, isEditable,
        isMPEIconVisible, isDossierInChairmanReview,
        isDossierChairmanReviewDone, isDossierWithChairmanCommentsInReview,
        canChairmanReview, canReviewOrDownload, isMPEFeedbackReceived, canSubmitToReview, rollBackIconVisible, tooltip, canValidatorReview
    ] = computeAccessResult(dossierStatus, user);

    const contributoriconStyle = isDossierInChairmanReview
        ? ' mpe-icon-common-style dossier-in-contributor-review-inprogress'
        : isDossierChairmanReviewDone
            ? ' mpe-icon-common-style dossier-in-techEvl-contributor-review-done'
            : (isDossierWithChairmanCommentsInReview || isMPEFeedbackReceived)
                ? ' mpe-icon-common-style dossier-in-review-contributor-review-done'
                : !isMPEFeedbackReceived && !canSubmitToReview
                ? ' contributor-review-icon-disabled'
                 : '';

    return {
        ...state,
        smiedEditable: {
            isMPEIconVisible,
            isRejectionCommentsPresent,
            isChairmanCommentsPresent,
            canChairmanReview,
            canReviewOrDownload,
            contributoriconStyle,
            isSlidingBoxVisible,
            isEditable,
            isMPEFeedbackReceived,
            isDossierInChairmanReview,
            tooltip,
            rollBackIconVisible,
            canValidatorReview
        }
    }
}

export default accessRestrictionReducer;
