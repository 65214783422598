import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PropTypes from 'prop-types';
import ReportWizard from "../ReportWizard/ReportWizard";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import SignOut from "../../components/SignOut/SignOut";
import SignIn from "../../components/SignIn/SignIn";
import PrivateRoute from "../../components/PrivateRoute";
import "./App.scss";


const App = () => {
  const renderHeader = () => {
    const isSignedIn =
      localStorage.getItem("amplify-redirected-from-hosted-ui") === "true" ||
      localStorage.getItem('amplify-signin-with-hostedUI') === 'true'
      return isSignedIn ? (
      <div className="exclude-print">
        <Header />
      </div>
    ) : (
      <Loader />
    );
  };
  return (
    <Router>
      {renderHeader()}
      <div className="app-cls">
        <Switch>
          <Route path="/signin" component={SignIn} />
          <Route path="/signout" component={SignOut} />
          <PrivateRoute path="/" component={ReportWizard}/>
        </Switch>
      </div>
    </Router>
  );
};

export default App;

App.propTypes = {
  authState: PropTypes.string
}
