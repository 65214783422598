import { prepareChartData, checkIfCircularChart } from "./chart.utils";

export const getColumns = (response) => {
  return response.columns.length
    ? response.columns
    : response.data.length
      ? Object.keys(response.data[0])
      : [];
}
/**
 *
 * @param {*} response
 * @param {*} details
 */
export const ChartData = (response, details) => {
  const { options, type } = details;
  const columns = getColumns(response);
  const isCircularChart = checkIfCircularChart(details.type);
  const label = isCircularChart ? options.labels : options.xAxis;
  let { labels, chartDataJson } = deStructureResponse(label, response);
  chartDataJson = details.errorGraph ? prepareErrorGraphData(chartDataJson) : chartDataJson
  labels = details.errorGraph ?  ["",...labels,""] : labels;
  const chartData = { type };
  if (!options.legend) {
    const legend = { position: "top" };
    options.legend = legend;
  }

  return prepareChartData(isCircularChart, chartDataJson, type, details, labels, label, chartData, columns);
};

/**
 *
 * @param {*} response
 * @param {*} label
 *
 */
export const deStructureResponse = (label, response = { data: [], columns: []}) => {
  const { data } = response;
  const columns = getColumns(response);
  return data.reduce((acc, item) => {
    columns.forEach((column) => {
      if (column === label) {
        acc.labels.push(item[column])
      } else {
        if (acc.chartDataJson[column]) {
          const arr = [...acc.chartDataJson[column], item[column]];
          acc.chartDataJson[column] = arr;
        } else {
          acc.chartDataJson[column] = [item[column]];
        }
      }
    })
    return acc;
  }, { labels: [], chartDataJson: {} });
}

/**
 * manipulate data according to chartJS error graphs expectation
 * @param {JSON} obj
 * @returns {JSON} containing modified data according to the chartJS expectation for error graph
 */
export const prepareErrorGraphData = (chartData) => {
  const primaryIntervalData = {
    ffr_100_pc : [...chartData.ffr_100_pc],
    ffr_plus_me_100_pc : [...chartData.ffr_plus_me_100_pc],
    ffr_minus_me_100_pc : [...chartData.ffr_minus_me_100_pc]
  }
  const nonPrimaryIntervalData = {
    ffr : [...chartData.ffr],
    ffr_plus_me : [...chartData.ffr_plus_me],
    ffr_minus_me : [...chartData.ffr_minus_me]
  }
  const primaryIntervalIndicesNotNull = primaryIntervalData.ffr_100_pc.map((value, index) => value !== 'null' ? index : null)
  Object.entries(nonPrimaryIntervalData).forEach(([, value]) => {
    primaryIntervalIndicesNotNull.forEach(index => {
      value[index] = null;
    });
  });
  // below lines to get y1 and y2 cordinates
  const barData = {
    ...nonPrimaryIntervalData,
    ...primaryIntervalData,
    ffr_plus_minus_me : ffrData(nonPrimaryIntervalData,'ffr_plus_me','ffr_minus_me'),
    ffr_plus_minus_me_100_pc : ffrData(primaryIntervalData,'ffr_plus_me_100_pc','ffr_minus_me_100_pc'),
  }
  Object.keys(barData).forEach(key => {barData[key] = [null, ...barData[key], null];});
  return {...barData}
}

/**
 * manipulate data according to chartJS error graphs expectation
 * @param {JSON} intervalData
 * @param {STRING} ffrPlusKey
 * @param {STRING} ffrMinusKey
 * @returns {Array of Array} ffr +/- me as y1 and y2
 */
export const ffrData = (intervalData, ffrPlus, ffrMinus)=>{
  return intervalData[ffrMinus].map((_, index) => [intervalData[ffrMinus][index], intervalData[ffrPlus][index]])
}

export default ChartData;
