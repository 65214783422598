const ColorCode = [
  '#030a64',
  '#22c4db',
  '#C71585',
  '#FF1493',
  '#e9ff7a',
  '#ff0000',
  '#ff80ed',
  '#660066',
  '#ff7f50',
  '#008080',
  '#ffd700',
  '#e6e6fa',
  '#ff7373',
  '#40e0d0',
  '#00ffff',
  '#b6fcd5',
  '#088da5',
  '#ccff00',
  '#ffc0cb',
  '#81d8d0',
  '#afeeee',
  '#ff6666',
  '#ffb6c1',
  '#008080',
  '#bada55',
  '#d4d4ff',
  '#9c8f96',
  '#67363a',
  '#16828c',
  '#168c16',
  '#788c16',
  '#f07160',
  '#f013dd',
  '#838f97',
  '#c0ff38',
  '#ffe1a9',
  '#ff387a',
  '#38fff5'
];

/**
 * 
 * @param {*} backgroundColor 
 */
export const setColorCode = backgroundColor => {
  let cCode = 0;
  for (let bgColor = 0; bgColor < backgroundColor.length; bgColor++) {
    if (!backgroundColor[bgColor]) {
      for (; cCode < ColorCode.length; cCode++) {
        if (!backgroundColor.includes(ColorCode[cCode])) {
          backgroundColor[bgColor] = ColorCode[cCode];
          cCode++;
          break;
        }
      }
    }
  }
};