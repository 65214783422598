import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import getConfig from '../../config';
const config = getConfig();

const logoutUrl = config.Auth.oauth.logoutUrl;
// const APPLICATION_NAME = 'sarge-frontend';
const SignOut = () => {
  useEffect(() => {
    // localStorage.removeItem(APPLICATION_NAME); // this item is used by applicaiton, need to remove explicitly on sign out
    Auth.signOut()
      .then(() => window.location.assign(logoutUrl))
      .catch(() => window.location.assign(logoutUrl));
  }, []);
  return null;
};

export default SignOut;