import { API_METHOD_GET, IMAGE_LIST, API_METHOD_DEL } from "../../utils/api.actions";
import getConfig from "../../config"
import { API_CALL } from "../../utils/api.middleware";
import { GET_IMAGE_SUCCESS, GET_IMAGE_FAILURE, DEL_IMAGE_SUCCESS, DEL_IMAGE_FAILURE } from "./imageActionType";

const config = getConfig();
const apiName = config.API.endpoints[0].name; // api endpoint resolved from aws for the project

export const getImageSuccess = (data, passData) => ({ type: GET_IMAGE_SUCCESS, payload: { data, passData } })
export const getImageFailure = (error) => ({ type: GET_IMAGE_FAILURE, payload: { error } })

//GET Image By Name
export const apiGetImage = (imageName, key) => {
  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: IMAGE_LIST + '/' + imageName,
      method: API_METHOD_GET,
      isAccess: false,
      params: {},
    },
    handlers: [ getImageSuccess, getImageFailure],
    passData: { key } //used to pass data from action to reducer
  }
}

//DELETE Image By Name
export const apiDeleteImage = (imageName) => {

  const delImageSuccess = (data, passData) => ({type: DEL_IMAGE_SUCCESS, payload: { data, passData } })
  const delImageFailure = (error) => ({ type: DEL_IMAGE_FAILURE, payload: { error } })

  return {
    type: API_CALL, // API_CALL to make api call
    apiOptions: {
      apiName,
      path: IMAGE_LIST + '/' + imageName,
      method: API_METHOD_DEL,
      isAccess: false,
      params: {},
    },
    handlers: [ delImageSuccess, delImageFailure ],
    componentName: 'DeleteImage'
  };
};
