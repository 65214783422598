import React from "react";
import Proptypes from  'prop-types';
import { TableRow, TableCell } from "@airbus/components-react";

import "./NoData.scss";

const NoData = ({ hasIndex, columns, msg, sources }) => {
  const customMsg = sources ? sources.reduce((acc, source) => (source.noDataMsg || acc), msg) : msg;
  return columns ? (
    <TableRow className='no-data-table-cls'>
      <TableCell colSpan={hasIndex ? columns.length + 1 : columns.length} >{customMsg}</TableCell>
    </TableRow>
  ) : (
    <div className="no-data-cls">{customMsg}</div>
  );
};

NoData.defaultProps = {
  msg: 'No relevant data for this section'
};

NoData.propTypes = {
  msg: Proptypes.string,
  hasIndex: Proptypes.bool,
  columns: Proptypes.array,
  sources: Proptypes.array
};


export default NoData;
