export const layoutsHandler = (section) => {
  const hiddenContents = section.contents.filter(
    (content) => content.hidden === "true"
  );

  const hiddenContentsByLoctn =
    hiddenContents.length &&
    hiddenContents.map((hiddenContent) => {
      return {
        hiddenContent: hiddenContent,
        matchedLoctnContents: section.contents.filter(
          (content) =>
            content.location === hiddenContent.location &&
            content.name !== hiddenContent.name &&
            content.hidden !== "true"
        ),
      };
    });

  const uniqHiddenContents =
    hiddenContentsByLoctn &&
    hiddenContentsByLoctn.filter(
      (hiddenContent) => hiddenContent.matchedLoctnContents.length === 0
    );

  return uniqHiddenContents.length
    ? refactorSectionLayout(section, uniqHiddenContents)
    : section;
};

const refactorSectionLayout = (currentSection, uniqHiddenContents) => {
  const locationIndexObj = { left: 0, middle: 1, right: 2 };
  const loctnIndexToHide = uniqHiddenContents.map(
    (content) => locationIndexObj[content.hiddenContent.location]
  );
  const updatedlayoutData = currentSection.layout.split("-");
  loctnIndexToHide.forEach((idx) => {
    updatedlayoutData[idx] = "0";
  });
  const updatedLayout = updatedlayoutData.join("-");
  currentSection["layout"] = updatedLayout;
  return currentSection;
};
