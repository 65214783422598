import React, { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import idx from "idx";
import {
  Container, Table, TableRow,
  TableCell, TableBody
} from "@airbus/components-react";
import { getData } from "../../utils/data.request";
import { updateDossierStats } from '../../models/dossierModel/dossierStatusActions';
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import NoData from "../NoData/NoData";

import "./HashTable.scss"

const HashTable = (props) => {
  const { reportName } = useParams()
  const { sources, details } = idx(props, _ => _.content);
  const dispatch = useDispatch();
  const [state, setState] = useReducer(
    (currState, newState) => ({ ...currState, ...newState }),
    { columns: [], data: [], error: null }
  );
  useEffect(() => {
    getData(sources, reportName).then((response) => {
      response && setState({ columns: response.columns, data: response.data });
      response && response.data && response.data.length && dispatch(updateDossierStats(reportName, response));
    }).catch((err) => {
      setState({ error: err })
    });
  }, [sources, reportName, dispatch]);
  const { columns, data, error } = state;
  const renderTableBody = () => {
    return data.length ? data.map((row, index) => (
      <TableRow key={index}>
        {columns.map(column => (
          <TableCell key={column}>{row[column]}</TableCell>
        ))}
      </TableRow>
    )) : (
      <NoData sources={sources} columns={columns} />
    );
  }
  return (
    <Container className="hash-table-cls">
      <span className="title">{details.title}</span>
      {!error ? <Table className='table'>
        <TableBody className="content">
          {renderTableBody()}
        </TableBody>
      </Table> : <ErrorHandler error={error} />}
    </Container>
  )
}

export default HashTable;